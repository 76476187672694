import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const CDFOverviewMeta = {
    title: "Distribuční funkce $F(x)$",
    shortTitle: "$\\small{F(x)}$",
    path: Path.cdf_overview,
    element: (sectionNumber:string) => <CDFOverview sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];



export function CDFOverview({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={CDFOverviewMeta.title} TOCSpec={TOCSpec}>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>
                <strong>Distribuční funkce</strong> {"$F(x)$"}
                </p>
                <p>
                {`$F(x) \\in \\langle 0, 1 \\rangle$`}
                </p>
                <p>
                {`$\\lim_{x \\to - \\infty}F(x) = 0, \\ \\lim_{x \\to \\infty}F(x) = 1$`}
                </p>
                <p>
                {`$F(x)$`} je neklesající.
                </p>
                <p>
                {`$F(x)$`} je <strong>schodovitá</strong>.
                </p>
            </div>

            <div style={{ flex: 1}}>
            <p>
                <strong>Distribuční funkce</strong> {"$F(x)$"}
                </p>
                <p>
                {`$F(x) \\in \\langle 0, 1 \\rangle$`}
                </p>
                <p>
                {`$\\lim_{x \\to - \\infty}F(x) = 0, \\ \\lim_{x \\to \\infty}F(x) = 1$`}
                </p>
                <p>
                {`$F(x)$`} je neklesající.
                </p>
                <p>
                {`$F(x)$`} je <strong>spojitá</strong>.
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                p(x) = \\begin{cases} -1 & \\text{ pro } x < 1 \\\\ 0.5 & \\text{ pro } x \\in \\langle 1, 2) \\\\ 1 & \\text{ pro } x \\geq 2 \\end{cases}
                $$`}
                Nemůže - funkce je v části záporná.
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                f(x) = 1 - e^{-x}
                $$`}
                Nemůže - funkce je pro {`$x < 0$`} záporná.
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                p(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\ 0.6 & \\text{ pro } x \\in \\langle 1, 2) \\\\ 1.2 & \\text{ pro } x \\geq 2 \\end{cases}
                $$`}
                Nemůže - funkce je v části větší než {`$1$`}.
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                f(x) = e^x
                $$`}
                Nemůže - funkce je v části větší než {`$1$`}. (Pro {`$x \\geq 0$`}.)
                </p>
            </div>
        </div>


        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                p(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\
                                    0.2 & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                    0.8 & \\text{ pro } x \\in \\langle 2, 3) \\\\
                                    0.7 & \\text{ pro } x \\in \\langle 3, 4) \\\\
                                    1 & \\text{ pro } x \\geq 4 \\end{cases}
                $$`}
                Nemůže - funkce je sice všude mezi {`$0$`} a {`$1$`} (včetně),
                ale v jednom místě klesá: v {`$x=3$`} je skok směrem dolů z {`$0.8$`} na {`$0.7$`}.
                Funkce tedy není neklesající.
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                f(x) = \\sin^2 (x)
                $$`}
                Nemůže - funkční hodnoty jsou sice všechny mezi {`$0$`} a {`$1$`} (včetně),
                ale funkce je v některých intervalech klesající - vykreslete si graf!
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                p(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\
                                    0.2 & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                    0.5 & \\text{ pro } x \\in \\langle 2, 3) \\\\
                                    1 & \\text{ pro } x \\geq 3 \\end{cases}
                $$`}
                Ano - hodnoty jsou mezi {`$0$`} a {`$1$`} (včetně) a funkce je neklesající
                (všechny schody jsou směrem nahoru).
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být distribuční funkce?
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                    1 - e^{-x} & \\text{ pro } x \\geq 0 \\end{cases}
                $$`}
                Ano - hodnoty jsou mezi {`$0$`} a {`$1$`} (včetně) a funkce je neklesající.
                Vykreslete si graf!
                </p>
            </div>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

