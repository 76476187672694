import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const RVOverviewMeta = {
    title: "Pravděpodobnostní funkce, hustota pravděpodobnosti",
    shortTitle: "$\\small{p(x), f(x)}$",
    path: Path.rv_overview,
    element: (sectionNumber:string) => <RVOverview sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];



export function RVOverview({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={RVOverviewMeta.title} TOCSpec={TOCSpec}>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>
                <strong>Pravděpodobnostní funkce</strong> {"$p(x_i)$"}
                </p>
                <p>
                {`$p(x_i) \\geq 0$`}
                </p>
                <p>
                {`$\\sum_{i=1}^{n} p(x_i) = 1$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                <strong>Hustota pravděpodobnosti</strong> {"$f(x)$"}
                </p>
                <p>
                {`$f(x) \\geq 0$`}
                </p>
                <p>
                {`$\\int_{-\\infty}^{\\infty} f(x) dx = 1$`}
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být pravděpodobnostní funkce?
                {`$$
                \\begin{array}{c|cccc} \\hline
                p(x_i) & 0.2 & 0.3 & 0.6 & -0.1
                \\end{array}
                $$`}
                Nemůže, jedna z hodnot je záporná.
                </p>

            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být hustota pravděpodobnosti?
                {`$$
                f(x) = \\sin x
                $$`}
                Nemůže, funkce {"$\\sin x$"} je v některých bodech záporná (pod osou {"$x$"}).
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být pravděpodobnostní funkce?
                {`$$
                \\begin{array}{c|cccc} \\hline
                p(x_i) & 0.2 & 0.3 & 0.5 & 0.1
                \\end{array}
                $$`}
                Nemůže - máme {"$\\sum p(x_i) = 1.1$"}, což není {"$1$"}.
                </p>

            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být hustota pravděpodobnosti?
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\ e^{-2x} & \\text{ pro } x \\geq 0 \\end{cases}
                $$`}
                Nemůže - máme:
                {`$$\\small
                    \\int_{-\\infty}^{\\infty} f(x) \\text{d}x = \\int_{0}^{\\infty} e^{-2x} \\text{d}x = \\left[-\\frac{e^{-2x}}{2}\\right]_0^{\\infty} = \\frac{1}{2} \\neq 1
                $$`}
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Může tohle být pravděpodobnostní funkce?
                {`$$
                \\begin{array}{c|cccc} \\hline
                p(x_i) & 0.2 & 0.3 & 0.4 & 0.1
                \\end{array}
                $$`}
                Ano, hodnoty jsou nezáporné a máme {"$\\sum p(x_i) = 1$"}.
                </p>

            </div>

            <div style={{ flex: 1}}>
                <p>
                Může tohle být hustota pravděpodobnosti?
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\ e^{-x} & \\text{ pro } x \\geq 0 \\end{cases}
                $$`}
                Ano, funkce {`$f(x)$`} je nezáporná a máme:
                {`$$\\small
                    \\int_{-\\infty}^{\\infty} f(x) \\text{d}x = \\int_{0}^{\\infty} e^{-x} \\text{d}x = \\left[-e^{-x}\\right]_0^{\\infty} = 1
                $$`}
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Určete {`$c$`} tak, aby {`$p$`} byla pravděpodobnostní funkce:
                {`$$
                \\begin{array}{c|cccc} \\hline
                p(x_i) & 0.2 & 0.3 & 0.4 & c
                \\end{array}
                $$`}
                Musí být {"$0.2 + 0.3 + 0.4 + c = 1$"},<br/>
                tedy {"$ 0.9 + c = 1$"}, {"$\\ \\ c = 0.1$"}.
                </p>

            </div>

            <div style={{ flex: 1}}>
                <p>
                Určete {`$c$`} tak, aby {`$f$`} byla hustota pravděpodobnosti:
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\ c x & \\text{ pro } x \\in \\langle 0, 1 \\rangle \\\\ 0 & \\text{ pro } x > 1 \\end{cases}
                $$`}

                Musí být:
                {`$$
                \\small
                \\int_{0}^{1} c x \\, \\text{d}x = \\left[\\frac{c x^2}{2}\\right]_0^1 = \\frac{c}{2} = 1
                $$`}
                Tedy {"$c = 2$"}.
                </p>
            </div>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

