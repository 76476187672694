import React from 'react';
import { IntegralAreaPlotFub } from '~/applets/IntegralAreaFub';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const IntegrationFubMeta = {
    title: "Integrál jako funkce horní meze",
    shortTitle: "Integrál jako f.h.m.",
    path: Path.integration_fub,
    element: (sectionNumber:string) => <IntegrationFub sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "int-fub-motivation",
    "int-fub-summary",
    "int-fub-cdf",
];

function f(x: number) {
    return 1/(1+x*x);
}

function F(x: number) {
    return Math.atan(x) + Math.PI/2;
}


export function IntegrationFub({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={IntegrationFubMeta.title} TOCSpec={TOCSpec}>

        {/* pouzij cauchyovo rozdeleni! */}

       <h2 id="int-fub-motivation">Motivace</h2>
       <p>Spočítáme určitý integrál od {"$-\\infty$"} do {"$b$"}:
       {`$$
              \\int_{-\\infty}^b f(x) \\,\\text{d}x
        $$`}
        Příklad:
        {`$$
              \\int_{-\\infty}^b \\frac{1}{1+x^2} \\,\\text{d}x = \\left[\\text{arctg}\\, x\\right]_{-\\infty}^b = \\text{arctg}\\, b - \\text{arctg}\\, (-\\infty) = \\text{arctg}\\, b + \\frac{\\pi}{2}
        $$`}
        Výsledek závisí na {"$b$"} (parametr {"$b$"} se vyskytuje ve výsledném vzorci).
        Získali jsme velikost plochy pod grafem funkce od {"$-\\infty$"} do {"$b$"}.
        Je zřejmé, že velikost plochy závisí na {"$b$"} - v tomto konkrétním případě je plocha vždy o něco větší,
        když se s horní mezí {"$b$"} (zarážkou integrace) posuneme doprava (když zvětšíme {"$b$"}).
       </p>
       <p>Můžeme tedy napsat funkci "velikost plochy {"$S$"} v závislosti na poloze zarážky {"$b$"}":
       {`$$
            S(b) = \\text{arctg}\\, b + \\frac{\\pi}{2}
       $$`}
       V obecném případě je to:
       {`$$
            S(b) = \\int_{-\\infty}^b f(x) \\,\\text{d}x
        $$`}
       </p>
       <p>Protože jsme zvyklí funkce vyjadřovat v proměnné {"$x$"}, a nikoli v proměnné {"$b$"}, píše
        se tento vzorec běžně takto:
        {`$$
            S(x) = \\int_{-\\infty}^x f(t) \\,\\text{d}t
        $$`}
        Proměnná {"$x$"} zaujala místo horní meze, a proto jsme proměnnou uvnitř integrálu
        přeznačili na {"$t$"} - aby vizuálně a nedocházelo ke knofliktu, a aby se předešlo možným výpočetním chybám.
       </p>
       <p>V našem příkladě je např.:</p>
       <table className='simple-table'>
            <thead>
                <tr>
                    <th>Integrál</th>
                    <th>Vyhodnocení</th>
                    <th>Čteme</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$\\small{S({\\textcolor{magenta}{0}}) = \\int_{-\\infty}^{\\textcolor{magenta}{0}} \\frac{1}{1+x^2} \\,\\text{d}x}$"}</td>
                    <td>{"$\\small{S({\\textcolor{magenta}{0}}) = \\text{arctg}\\, {\\textcolor{magenta}{0}} + \\frac{\\pi}{2} = 0 + \\frac{\\pi}{2} = 1.57}$"}</td>
                    <td>"Velikost plochy pod grafem funkce {"$\\frac{1}{1+x^2}$"} od {"$-\\infty$"} do {"${\\textcolor{magenta}{0}}$"} je {"$1.57$"}"</td>
                </tr>
                <tr>
                    <td>{"$\\small{S({\\textcolor{magenta}{1}}) = \\int_{-\\infty}^{\\textcolor{magenta}{1}} \\frac{1}{1+x^2} \\,\\text{d}x}$"}</td>
                    <td>{"$\\small{S({\\textcolor{magenta}{1}}) = \\text{arctg}\\, {\\textcolor{magenta}{1}} + \\frac{\\pi}{2} = \\frac{\\pi}{4} + \\frac{\\pi}{2} = 2.36}$"}</td>
                    <td>"Velikost plochy pod grafem funkce {"$\\frac{1}{1+x^2}$"} od {"$-\\infty$"} do {"${\\textcolor{magenta}{1}}$"} je {"$2.36$"}"</td>
                </tr>
                <tr>
                    <td>{"$\\small{S({\\textcolor{magenta}{x}}) = \\int_{-\\infty}^{\\textcolor{magenta}{x}} \\frac{1}{1+t^2} \\,\\text{d}t}$"}</td>
                    <td>{"$\\small{S({\\textcolor{magenta}{x}}) = \\text{arctg}\\, {\\textcolor{magenta}{x}} + \\frac{\\pi}{2}}$"}</td>
                    <td>"Obecně, velikost plochy pod grafem této funkce od {"$-\\infty$"} až po {"${\\textcolor{magenta}{x}}$"} je dána vzorcem {"$\\small{\\text{arctg}\\,{\\textcolor{magenta}{x}} + \\frac{\\pi}{2}}$"}."</td>
                </tr>
            </tbody>
       </table>

            {/* S(x) & = \\text{arctg}\\, x + \\frac{\\pi}{2} \\\\
            S(0) & = \\text{arctg}\\, 0 + \\frac{\\pi}{2} = 0 + \\frac{\\pi}{2} = 1.57 \\\\
            S(1) & = \\text{arctg}\\, 1 + \\frac{\\pi}{2} = \\frac{\\pi}{4} + \\frac{\\pi}{2} = 2.36
        \\end{align*}`} */}
        {/* Čteme: "Velikost plochy pod grafem funkce {"$\\frac{1}{1+x^2}$"} od {"$-\\infty$"} do {"$0$"} je {"$1.57$"}",
        "Velikost plochy pod grafem funkce {"$\\frac{1}{1+x^2}$"} od {"$-\\infty$"} do {"$1$"} je {"$2.36$"}".
        Obecně, velikost plochy pod grafem této od {"$-\\infty$"} až po {"$x$"} je dána vzorcem {"$\\text{arctg}\\, x + \\frac{\\pi}{2}$"}. */}

       <h2 id="int-fub-summary">Shrnutí</h2>
       <p>Integrál jako funkce horní meze se počítá jako:
        {`$$
            S(x) = \\int_{-\\infty}^x f(t) \\,\\text{d}t  \\qquad \\ \\text{popřípadě } \\ S(x) = \\int_{a}^x f(t) \\,\\text{d}t
        $$`}
        kde {"$a$"} je nějaká dolní mez vyplývající z konkrétního kontextu.
       </p>
       <p>Tento integrál vyjadřuje kumulovanou plochu pod grafem funkce {"$f$"} od dolní meze až po horní mez {"$x$"},
        jako funkci horní meze {"$x$"}.
       </p>

       <IntegralAreaPlotFub computef={f} computeF={F} xmin={-5} xmax={5} ymin={-0.2} ymax={3.5} fname={"\\frac{1}{1 + x^2}"}/>



       <h2 id="int-fub-cdf">Distribuční funkce</h2>
       <p>Distribuční funkce {"$F$"} (anglicky <i>cumulative distribution function</i>, zkráceně CDF)
       je integrál jako funkce horní meze z hustoty pravděpodobnosti {"$f$"}:
        {`$$
            F(x) = \\int_{-\\infty}^x f(t) \\,\\text{d}t
        $$`}
       </p>
       <p>Příklad:
        {`$$
                \\text{hustota: } f(x) = \\begin{cases}
                    0 & x < 0 \\\\
                    {\\color{green}{2x}} & 0 \\leq x \\leq 1 \\\\
                    0 & x > 1
                \\end{cases}
        $$`}
        Distibuční funkce na intervalu {"$\\langle 0, 1 \\rangle$"}:
        {`$$
            F(x) = \\int_{0}^x {\\color{green}{2t}} \\,\\text{d}t = \\left[t^2\\right]_0^x = (x^2 - 0^2) = x^2
        $$`}
       </p>
       <p>Kdybychom to počítali formálně detailně:
        {`\\begin{align*}
            x &\\in (-\\infty, 0): & F(x) &= \\int_{-\\infty}^x f(t) \\,\\text{d}t = \\int_{-\\infty}^0 0 \\,\\text{d}t = 0 \\\\
            x &\\in \\langle 0, 1\\rangle: & F(x) &= \\int_{-\\infty}^x f(t) \\,\\text{d}t = \\int_{-\\infty}^0 0 \\,\\text{d}t + \\int_{0}^x 2t \\,\\text{d}t = 0 + \\left[t^2\\right]_0^x = x^2 \\\\
            x &\\in (1, \\infty): & F(x) &= \\int_{-\\infty}^x f(t) \\,\\text{d}t = \\int_{-\\infty}^0 0 \\,\\text{d}t + \\int_{0}^1 2t \\,\\text{d}t + \\int_{1}^x 0 \\,\\text{d}t = 0 + 1 + 0 = 1
        \\end{align*}`}
       </p>

        <Footer/>
        </ChapterWrapper>
    );
}
