import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const cv1_abs_ilustrace = require('../assets/images/cv1_abs_ilustrace.png');
const cv1_abs_priklad = require('../assets/images/cv1_abs_priklad.png');


export const AbsValueMeta = {
    title: "Absolutní hodnota",
    shortTitle: "Abs. hodnota",
    path: Path.abs_value,
    element: (sectionNumber:string) => <AbsValue sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [

];


export function AbsValue({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={AbsValueMeta.title} TOCSpec={TOCSpec}>

        <p>
        {`$$
        |x| =
            \\begin{cases}
            x & \\quad \\text{pro } x \\geq 0 \\\\
            - x & \\quad \\text{pro } x < 0 \\\\
            \\end{cases}
        $$`}

        {`$$
        \\mathopen| f(x) \\mathclose| =
            \\begin{cases}
            f(x) & \\quad \\text{pro } x, \\text{ kde je } f(x) \\geq 0 \\\\
            - f(x) & \\quad \\text{pro } x, \\text{ kde je } f(x) < 0 \\\\
            \\end{cases}
        $$`}
        </p>


        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={cv1_abs_ilustrace} style={{width: "70%"}}/>
        </div>

        <h2>Rovnice a nerovnice s absolutní hodnotou</h2>
        <p>
        S absolutní hodnotou nelze počítat - musí se odstranit.
        Lze nahradit výrazem se znaménkem {"$+$"} anebo {"$-$"}, proto se výpočet větví.
        </p>

        <p>
        <strong>Př</strong>: {"$|2x - 1| = 5$"}

        {`$$
        \\mathopen| 2x - 1 \\mathclose| =
            \\begin{cases}
            2x - 1 & \\quad \\text{pro } \\{x: 2x - 1 \\geq 0\\}, \\text{ tj. pro } x \\geq \\frac{1}{2} \\\\
            - (2x - 1) & \\quad \\text{pro } \\{x: 2x - 1 < 0\\}, \\text{ tj. pro } x < \\frac{1}{2}
            \\end{cases}
        $$`}
        </p>
        <ul>
            <li><p>Větev A:<br/>
            Nechť {"$x \\geq \\frac{1}{2}$"} (předpoklad). <br/>
            Potom {"$|2x - 1| = 2x - 1$"} (výraz {"$|2x - 1|$"} zde lze nahradit výrazem {"$2x - 1$"}, protože jsou si tu rovny). <br/>

            {"$|2x - 1| = 5 \\iff 2x - 1 = 5 \\iff  x = 3$"}  <br/>
            Máme kandidáta na řešení, musíme ověřit že splňuje předpoklad {"$x \\geq \\frac{1}{2}$"}.
            Ano, platí, že {"$3 \\geq \\frac{1}{2}$"}.
        </p></li>
        <li><p>Větev B:<br/>
            Nechť {"$x < \\frac{1}{2}$"} (předpoklad). <br/>
            Potom {"$|2x - 1| = - (2x - 1)$"} (výraz {"$|2x - 1|$"} zde lze nahradit výrazem {"$-(2x - 1)$"}, protože jsou si tu rovny). <br/>

            {"$|2x - 1| = 5 \\iff -(2x - 1) = 5 \\iff  x = -2$"} <br/>
            Máme kandidáta na řešení, musíme ověřit že splňuje předpoklad {"$x < \\frac{1}{2}$"}.
            Ano, platí, že {"$-2 < \\frac{1}{2}$"}.
        </p></li>
        </ul>
        <p>
        Našli jsme dvě řešení: {"$x_1 = 3, x_2 = -2$"}.
        </p>

    <p>
    <strong>Př</strong>:  {"$|x^2 - 1| = 1$"}

    {`$$
    \\mathopen| x^2 - 1 \\mathclose| =
        \\begin{cases}
        x^2 - 1 & \\quad \\text{pro } \\{x: x^2 - 1 \\geq 0\\}, \\text{ tj. pro } x \\in \\textrm{kde?} \\\\
        - (x^2 - 1) & \\quad \\text{pro } \\{x: x^2 - 1 < 0\\}, \\text{ tj. pro } x \\in \\textrm{kde?}
        \\end{cases}
    $$`}
    Využijeme analýzu znaménka funkce: {"$x^2 - 1 = (x+1)(x-1)$"}.
    Kořeny {"$-1, 1$"}. Znaménko {"$+$"} pro {"$x < -1$"} nebo {"$x > 1$"}.
    Znaménko {"$-$"} pro {"$x \\in (-1, 1)$"}.

    {`$$
    \\mathopen| x^2 - 1 \\mathclose| =
        \\begin{cases}
        x^2 - 1 & \\quad \\text{pro } x \\in (-\\infty, 1 \\rangle \\cup \\langle 1, \\infty) \\\\
        - (x^2 - 1) & \\quad \\text{pro } x \\in (-1, 1)
        \\end{cases}
    $$`}
    </p>

    <ul>
        <li><p>Větev A:<br/>
	    Nechť  {"$x \\in (-\\infty, 1 \\rangle \\cup \\langle 1, \\infty)$"} (předpoklad).
        Potom {"$|x^2 - 1| =  x^2 - 1$"}. <br/>

	    {"$|x^2 - 1| = 1 \\iff x^2 - 1 = 1 \\iff  x^2 = 2 \\iff x = \\pm \\sqrt{2}$"}  <br/>
	    Máme kandidáty na řešení, musíme ověřit že splňují předpoklad {"$x \\in (-\\infty, 1 \\rangle \\cup \\langle 1, \\infty)$"}.
        Předpoklad je u obou splněn.
        </p></li>

        <li><p>Větev B:<br/>
	    Nechť {"$x \\in (-1, 1)$"} (předpoklad). Potom {"$|x^2 - 1| =  -(x^2 - 1)$"}. <br/>

	    {"$|x^2 - 1| = 1 \\iff -(x^2 - 1) = 1 \\iff  x^2 = 0 \\iff x = 0$"}  <br/>
	    Máme kandidáta na řešení, musíme ověřit že splňuje předpoklad  {"$x \\in (-1, 1)$"}.
        Předpoklad je splněn.
        </p></li>
        </ul>
        <p>
        Našli jsme tři řešení: {"$x_1 = -\\sqrt{2}, x_2 = 0, x_3 = \\sqrt{2}$"}.
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={cv1_abs_priklad} style={{width: "43%"}}/>
        </div>


        <Footer/>
        </ChapterWrapper>
    );
}

