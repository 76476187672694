import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const TaylorMeta = {
    title: "Taylorův polynom",
    shortTitle: "Taylor",
    path: Path.taylor,
    element: (sectionNumber:string) => <Taylor sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "tay-rationale",
    "tay-formula",
    "tay-demo",
    "tay-series-or-poly",
    "tay-order-or-degree",
    "tay-mustard-salad",
    "tay-poly-on-poly",
];


export function Taylor({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={TaylorMeta.title} TOCSpec={TOCSpec}>

        <h2 id="tay-rationale">Rationale</h2>

        <p>Funkce jako {"$\\sin x$"}, {"$\\cos x$"}, {"$\\ln x$"}, {"$e^x$"} jsou hezké,
        ale neumíme je vyčíslit dosazením. Představte si situaci na pustém ostrově, bez kalkulačky, bez počítače,
        a potřebujete určit hodnotu {"$\\sin(0.4)$"}. Jak to uděláte? <br/>
        U funkce typu polynom, např. {"$x^2 + 2x - 1$"}, je to jednoduché - dosadíme za {"$x$"} {"$0.4$"}, a protože umíme sčítat/odčítat a násobit (a dělit),
        tak spočítáme výsledek. <br/><br/>
        Jak ale dosadit {"$0.4$"} do {"$\\sin x$"}, aby z toho vypadlo číslo?
        </p>
        <p>
        Místo do {"$\\sin x$"} dosadíme do Taylorova polynomu pro {"$\\sin x$"} - který je polynomem (takže ho umíme vyčíslit) - a doufáme,
        že odchylka od pravé hodnoty sinu bude malá. S trochou vynalézavosti se dá chyba odhadnout a omezit, takže proces je spolehlivý.
        </p>

        <h2 id="tay-formula">Vzorec</h2>
        <p>
        N-tý člen Taylorova rozvoje funkce {"$f(x)$"} se středem v bodě {"$a$"} je dán vzorcem:
        {`$$
            \\frac{f^{(n)}(a)}{n!}(x-a)^n
        $$`}
        </p>
        <p>
        {`$$\\begin{array}{|c|c|c|c|c|}
            \\hline
            n & f^{(n)}(a) & n! & (x - a)^n & \\text{člen} \\\\[7pt] \\hline
            0 & f(a) & 1 & 1 & f(a) \\\\[7pt]
            1 & f'(a) & 1 & (x - a) & f'(a)(x - a) \\\\[7pt]
            2 & f''(a) & 2 & (x - a)^2 & \\frac{f''(a)}{2}(x - a)^2 \\\\[7pt]
            3 & f'''(a) & 6 & (x - a)^3 & \\frac{f'''(a)}{6}(x - a)^3 \\\\[7pt]
            4 & f^{(4)}(a) & 24 & (x - a)^4 & \\frac{f^{(4)}(a)}{24}(x - a)^4 \\\\[7pt]
            5 & f^{(5)}(a) & 120 & (x - a)^5 & \\frac{f^{(5)}(a)}{120}(x - a)^5 \\\\[7pt]
            \\cdots & \\cdots & \\cdots & \\cdots & \\cdots \\\\
            \\hline
        \\end{array}$$`}
        </p>

        <p>Když to napíšeme pospolu:
        {`$$\\begin{align*}
            T(x) &= f(a) + f'(a)(x - a) + \\frac{f''(a)}{2}(x - a)^2 + \\frac{f'''(a)}{6}(x - a)^3 + \\frac{f^{(4)}(a)}{24}(x - a)^4 + \\cdots \\\\
        \\end{align*}$$`}
        </p>

        <p>Když skončíme brzy - tečna:
        {`$$
            T(x) = f(a) + f'(a)(x - a) \\\\
        $$`}
        ... toto je vzorec pro tečnu ke grafu funkce {"$f$"} v bodě {"$(a, f(a))$"}.
        </p>

        <h2 id="tay-demo">Ukázka</h2>
        <p>
        Funkce {"$f(x) = \\sin x$"} v bodě {"$a = 0$"}:
        {`$$\\begin{array}{|c|c|c|c|c|}
            \\hline
            n & \\sin^{(n)}(x) & \\sin^{(n)}(0) & n! & (x - 0)^n & \\text{člen} \\\\ \\hline
            0 & \\sin x & 0 & 1 & 1 & 0 \\\\
            1 & \\cos x & 1 & 1 & x & x \\\\
            2 & -\\sin x & 0 & 2 & x^2 & 0 \\\\
            3 & -\\cos x & -1 & 6 & x^3 & -\\frac{x^3}{6} \\\\
            4 & \\sin x & 0 & 24 & x^4 & 0 \\\\
            5 & \\cos x & 1 & 120 & x^5 & \\frac{x^5}{120} \\\\
            \\cdots & \\cdots & \\cdots & \\cdots & \\cdots & \\cdots \\\\
            \\hline
        \\end{array}$$`}
        Výsledek:
        {`$$
            T(x) = x - \\frac{x^3}{6} + \\frac{x^5}{120} - \\cdots \\\\
        $$`}
        </p>

        <h2 id="tay-series-or-poly">Řada versus polynom</h2>
        <p>Taylorova <strong>řada</strong> (také nazývaná Taylorův rozvoj) je nekonečná řada:
        {`$$
            T(x) = f(a) + f'(a)(x - a) + \\frac{f''(a)}{2}(x - a)^2 + \\frac{f'''(a)}{6}(x - a)^3 + \\frac{f^{(4)}(a)}{24}(x - a)^4 + \\cdots \\\\
        $$`}
        </p>
        <p>Taylorův <strong>polynom</strong> nastane tehdy, když nepokračujeme do nekonečna, ale po určitém kroku zastavíme:
        {`$$
            T_{\\color{red}{3}}(x) = f(a) + f'(a)(x - a) + \\frac{f''(a)}{2}(x - a)^2 + \\frac{f'''(a)}{6}(x - a)^3.
        $$`}
        Dejme tomu, že poslední derivace, kterou spočítáme, je <span style={{color: 'red'}}>třetí</span>. Pak máme Taylorův polynom <span style={{color: 'red'}}>třetího řádu</span>.
        Řád se volitelně indikuje v dolním indexu za písmenem {"$T$"}.
        </p>

        <h2 id="tay-order-or-degree">Řád versus stupeň</h2>

        <p>Řád a stupeň není nutně stejná věc - za zvláštních okolností může být stupeň menší:
        </p>
        <p>Dejme tomu, že u příkladu se {"$\\sin x$"} výše jsme se rozhodli skončit po určení čtvrté derivace - zapíšeme Taylorův polynom <strong>čtvrtého řádu</strong>:
        {`$$
            T_4(x) = x - \\frac{x^3}{6}
        $$`}
        ... a je to polynom <strong>třetího stupně</strong>. Protože poslední koeficient u {"$x^4$"} je nula.
        </p>
        <p>Často řád se stupněm splývá; např. v rozvoji {"$e^x$"}:
        {`$$
            T_4(x) = 1 + x + \\frac{x^2}{2} + \\frac{x^3}{6} + \\frac{x^4}{24}
        $$`}
        </p>

        <h2 id="tay-mustard-salad">"S hořčicí, se salátem"</h2>

        <p>Ve skriptech bývá zvykem psát "obložený" symbol {"$T$"}:
        {`$$
            T^{f,a}_n(x) = \\cdots
        $$`}
        kde se indikuje:
        </p>

        <ul>
            <li> {"$f$"} - funkce, které se Taylorův polynom týká,</li>
            <li> {"$a$"} - střed,</li>
            <li> {"$n$"} - řád polynomu,</li>
            <li> {"$x$"} - proměnná</li>
        </ul>

        <p>
        Zápis potom vypadá třeba takto:
        {`$$
            T^{\\sin x,0}_4(x) = x - \\frac{x^3}{6}
        $$`}
        </p>

        <p>
        Pokud provádím výpočet, kde se vyskytuje více Taylorových polynomů současně, pak je praktické je takto anotovat, abych se vyznal v tom, který je který.
        Pokud počítám s jedním polynomem, pak funkce, střed a řád jsou dány ze zadání nebo z kontextu, takže jimi symbol {"$T$"} obkládat nemusím.
        </p>

        <h2 id="tay-poly-on-poly">Polynom polynomu?</h2>

        <p>Dejme tomu, že chceme Taylorovým polynomem aproximovat nějaký polynom. Co bychom čekali že vyjde? Zkusme pro zábavu rozvoj obecného polynomu stupně 3:
        {`$$
            f(x) = ax^3 + bx^2 + cx + d \\\\
        $$`}
        Taylorův polynom se středem v {"$x_0 = 0$"}:
        {`$$\\begin{array}{|c|l|c|c|c|}
            \\hline
            n & f^{(n)}(x) & f^{(n)}(0) & n! & (x - 0)^n & \\text{člen} \\\\ \\hline
            0 & ax^3 + bx^2 + cx + d & d & 1 & 1 & d \\\\
            1 & 3ax^2 + 2bx + c & c & 1 & x & cx \\\\
            2 & 6ax + 2b & 2b & 2 & x^2 & bx^2 \\\\
            3 & 6a & 6a & 6 & x^3 & ax^3 \\\\
            \\hline
        \\end{array}$$`}
        Výsledek:
        {`$$
            T(x) = d + cx + bx^2 + ax^3 \\\\
        $$`}
        Taylorův polynom se středem v {"$x_0 = 1$"}:
        {`$$\\begin{array}{|c|l|c|c|r|}
            \\hline
            n & f^{(n)}(x) & f^{(n)}(1) & n! & (x - 1)^n & \\text{člen} \\\\ \\hline
            0 & ax^3 + bx^2 + cx + d & (a+b+c+d) & 1 & 1 & a+b+c+d \\\\
            1 & 3ax^2 + 2bx + c & (3a+2b+c) & 1 & x-1 & (3a+2b+c)(x-1) \\\\
            2 & 6ax + 2b & (6a+2b) & 2 & (x-1)^2 & (3a+b)(x-1)^2 \\\\
            3 & 6a & 6a & 6 & (x-1)^3 & a(x-1)^3 \\\\
            \\hline
        \\end{array}$$`}
        Výsledek:
        {`$$
            T(x) = (a+b+c+d) + (3a+2b+c)(x-1) + (3a+b)(x-1)^2 + a(x-1)^3 \\\\
        $$`}
        Zkouška - 1) roznásobení:
        {`$$
            T(x) = (a+b+c+d) + (3a+2b+c)(x-1) + (3a+b)(x^2 -2x + 1) + a(x^3 - 3x^2 + 3x - 1), \\\\
        $$`}
        2) kontrola koeficientů u mocnin x:
        {`$$\\begin{align*}
            x^0: &\\ (a+b+c+d) - (3a+2b+c) + (3a+b) - a = d \\\\
            x^1: &\\ (3a+2b+c) - 2(3a+b) + 3a = c \\\\
            x^2: &\\ (3a+b) - 3a = b \\\\
            x^3: &\\ a
        \\end{align*}$$`}
        </p>
        Funguje.
        <Footer/>
        </ChapterWrapper>
    );
}

