import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const Derivatives1Meta = {
    title: "Derivace",
    shortTitle: "Derivace",
    path: Path.derivatives_1,
    element: (sectionNumber:string) => <Derivatives1 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "d1-formulas",
    "d1-more-formulas",
    "d1-functions-combined",
    "d1-composite-function",
    "d1-practical-aspects",
    "d1-for-fun",
    "d1-xx",
    "d1-inverse-function",
];

// {`$$\\begin{align*}
//     c' &= 0 &  \\sin' x &= \\cos x \\\\
//     x' &= 1 &  \\cos' x &= -\\sin x \\\\
//     (x^{n})' &= nx^{n-1} &  \\text{tg}' x &= \\frac{1}{\\cos^2 x} \\\\
//     (e^{x})' &= e^x &  \\text{cotg}' x &= -\\frac{1}{\\sin^2 x} \\\\
//     (a^{x})' &= a^x \\ln a &  \\arcsin' x &= \\frac{1}{\\sqrt{1-x^2}} \\\\
//     \\ln' x &= \\frac{1}{x} &  \\arccos' x &= -\\frac{1}{\\sqrt{1-x^2}} \\\\
//     \\log_a' x &= \\frac{1}{x \\ln a} &  \\text{arctg}' x &= \\frac{1}{1+x^2} \\\\
// \\end{align*}$$`}


export function Derivatives1({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={Derivatives1Meta.title} TOCSpec={TOCSpec}>

        <h2 id="d1-notation">Značení</h2>
        <p><strong>A)</strong> Následující značení je ekvivalentní:
        {`$$
            \\sin' x = (\\sin x)'  \\quad \\cos' x = (\\cos x)'  \\quad \\text{tg}' x = (\\text{tg } x)'  \\quad \\text{cotg }' x = (\\text{cotg} x)'  \\quad \\ln' x = (\\ln x)' \\ \\ \\text{ atd.}
        $$`}
        </p>
        <p><i>Pozorování: Stejný princip vlastně používáme i u mocnin:</i>
        {`$$
            \\sin^2 x = (\\sin x)^2  \\quad \\cos^3 x = (\\cos x)^3  \\quad \\text{tg}^4 x = (\\text{tg } x)^4  \\ \\ \\text{ atd.}
        $$`}
        </p>
        <p><strong>B)</strong> Pokud je funkce složená (tj. je v ní něco jiného než čistě {"$x$"}), dáváme přednost závorce kolem funkce:
        {`$$
            (\\sin x^2)' \\quad  \\left[\\cos(x+1)\\right]' \\quad \\left[\\text{tg}(2x)\\right]' \\quad  \\left[\\ln(3x+1)\\right]' \\quad \\text{ atd.}
        $$`}
        Zde by totiž mohlo dojít k nedorozumění, protože potřebujeme rozlišovat dvě situace:
        {`$$\\begin{align*}
            (\\sin x^2)' &= \\left|{\\small{\\text{derivujeme složenou funkci}}}\\right| =(\\cos x^2) \\cdot 2x \\\\
            \\sin'(x^2) &= \\left|{\\small{\\text{derivujeme sinus a do výsledku, tj. do kosinu, pak vkládáme } x^2}} \\right| = \\cos(x^2)
        \\end{align*}$$`}
        </p>
        <p><strong>C)</strong> Pokud se setká derivace a mocnina, nezbývá než použít závorku:
        {`$$\\begin{align*}
            (\\sin' x)^2 &= \\left|{\\small{\\text{napřed derivujeme, pak umocníme}}}\\right| = (\\cos x)^2 = \\cos^2 x \\\\
            (\\sin^2 x)' &= \\left|{\\small{\\text{drivujeme složenou funkci}}}\\right| = 2 (\\sin x) \\cos x
        \\end{align*}$$`}
        </p>

        <h2 id="d1-formulas">Vzorce pro výpočet derivací</h2>
        <p>
        Vzorce ve druhém sloupci čtěte tak, že {"$f$"} značí {"$f(x)$"}, tj. vnitřní (vnořenou) funkci.
        {`$$\\small\\begin{align*}
            \\text{vanilla} && \\text{složená fce} && \\text{příklad} \\\\
            c' &= 0 &  &  & 3' &= 0 \\\\
            x' &= 1 &  &  & x' &= 1 \\\\
            (x^n)' &= n x^{n-1}                         & (f^n)' &= n f^{n-1} \\cdot f'                                         & \\left(\\sin^5 x             \\right)' &= 5 (\\sin^4 x) \\cdot \\cos x\\\\
            (e^x)' &= e^x                               & (e^{f})' &= e^{f} \\cdot f'                                           & \\left(e^{x^2}               \\right)' &= e^{x^2} \\cdot 2x \\\\
            (a^x)' &= a^x \\ln a                        & (a^{f})' &= a^{f} \\ln a \\cdot f'                                    & \\left(2^{\\cos x}           \\right)' &= 2^{\\cos x} \\ln 2 \\cdot (-\\sin x) \\\\
            \\ln' x &= \\frac{1}{x}                     & \\left(\\ln f \\right)' &= \\frac{1}{f} \\cdot f'                     & \\left[ \\ln (3x+1)          \\right]' &= \\frac{1}{3x+1} \\cdot 3 \\\\
            \\log_a' x &= \\frac{1}{x \\ln a}           & \\left(\\log_a f \\right)' &= \\frac{1}{f \\ln a} \\cdot f'           & \\left[ \\log_2 (\\sin x)    \\right]' &= \\frac{1}{(\\sin x) \\ln 2} \\cdot \\cos x \\\\
            \\sin' x &= \\cos x                         & \\left(\\sin f \\right)' &= (\\cos f) \\cdot f'                       & \\left( \\sin x^3            \\right)' &= (\\cos x^3) \\cdot 3x^2 \\\\
            \\cos' x &= -\\sin x                        & \\left(\\cos f \\right)' &= -(\\sin f) \\cdot f'                      & \\left[ \\cos (-x)           \\right]' &= -(\\sin(-x)) \\cdot (-1) \\\\
            \\text{tg}' x &= \\frac{1}{\\cos^2 x}       & \\left(\\text{tg} f \\right)' &= \\frac{1}{\\cos^2 f} \\cdot f'       & \\left( \\text{tg } x^2       \\right)' &= \\frac{1}{\\cos^2 x^2} \\cdot 2x \\\\
            \\text{cotg}' x &= -\\frac{1}{\\sin^2 x}    & \\left(\\text{cotg} f \\right)' &= -\\frac{1}{\\sin^2 f} \\cdot f'    & \\left[ \\text{cotg } (e^{2x})\\right]' &= -\\frac{1}{\\sin^2 e^{2x}} \\cdot e^{2x} \\cdot 2 \\\\
            \\text{arctg}' x &= \\frac{1}{1+x^2}        & \\left(\\text{arctg} f \\right)' &= \\frac{1}{1+f^2} \\cdot f'        & \\left( \\text{arctg } x^2   \\right)' &= \\frac{1}{1+x^4} \\cdot 2x \\\\
            \\arcsin' x &= \\frac{1}{\\sqrt{1-x^2}}     & \\left(\\arcsin f \\right)' &= \\frac{1}{\\sqrt{1-f^2}} \\cdot f'     & \\left( \\arcsin x^3          \\right)' &= \\frac{1}{\\sqrt{1-x^6}} \\cdot 3x^2 \\\\
            \\arccos' x &= -\\frac{1}{\\sqrt{1-x^2}}    & \\left(\\arccos f \\right)' &= -\\frac{1}{\\sqrt{1-f^2}} \\cdot f'    & \\left[ \\arccos (\\ln x)    \\right]' &= -\\frac{1}{\\sqrt{1-(\\ln x)^2}} \\cdot \\frac{1}{x} \\\\
        \\end{align*}$$`}
        </p>

        <h2 id="d1-more-formulas">Další užitečné vzorce, které se hodí si pamatovat 'napřímo'</h2>
        <p>
        {`$$\\begin{align*}
            \\sqrt{x}' &= \\frac{1}{2\\sqrt{x}} &  \\Big(\\frac{1}{x}\\Big)' &= -\\frac{1}{x^2} \\\\
        \\end{align*}$$`}
        </p>
        <p>
        {`$$\\small\\begin{align*}
            \\text{vanilla} && \\text{složená fce} && \\text{příklad} \\\\
            (\\sqrt{x})' &= \\frac{1}{2\\sqrt{x}} & (\\sqrt{f})' &= \\frac{1}{2\\sqrt{f}} \\cdot f' & (\\sqrt{\\sin x})' &= \\frac{1}{2\\sqrt{\\sin x}} \\cdot \\cos x \\\\
            \\left(\\frac{1}{x}\\right)' &= -\\frac{1}{x^2} & \\left(\\frac{1}{f}\\right)' &= -\\frac{1}{f^2} \\cdot f' & \\left(\\frac{1}{\\ln x}\\right)' &= -\\frac{1}{(\\ln x)^2} \\cdot \\frac{1}{x} \\\\
        \\end{align*}$$`}
        </p>

        <h2 id="d1-functions-combined">Kombinace funkcí</h2>
        <p>
        {`$$\\begin{align*}
            \\text{vztah} && \\text{příklad} \\\\
            (c \\cdot f)' &= c \\cdot f' & (3 x^2)' &= 3 \\cdot 2x = 6x \\\\
            (f \\pm g)' &= f' \\pm g' & (x^2 + 3x)' &= 2x + 3 \\\\
            (f \\cdot g)' &= f'g + fg' & (x^2 \\sin x)' &= 2x \\sin x + x^2 \\cos x \\\\
            \\left(\\frac{f}{g}\\right)' &= \\frac{f'g - fg'}{g^2} & \\left(\\frac{x^2}{3x+1}\\right)' &= \\frac{2x(3x+1) - x^2 \\cdot 3}{(3x+1)^2} \\\\
        \\end{align*}$$`}
        </p>

        <h2 id="d1-composite-function">Složená funkce</h2>
        <p>
        {`$$
            \\Big[g\\big(f(x)\\big)\\Big]' = g'(f(x)) \\cdot f'(x)  \\quad \\text{(násobíme derivací vnitřní složky)}
        $$`}
        Vnitřní složka je {"$f(x)$"}; derivace vnitřní složky je {"$f'(x)$"}.
        </p>

        <h2 id="d1-product-three">Součin tří funkcí</h2>
        <p>
        {`$$
            (fgh)' = f'gh + f(gh)' = f'gh + f(g'h + gh') = {\\color{RoyalBlue}f'}gh + f{\\color{RoyalBlue}g'}h + fg{\\color{RoyalBlue}h'} \\qquad \\text{isn't that nice :)}
        $$`}
        Asi uhádnete, jak to dopadne se čtyřmi, pěti, šesti funkcemi... :)
        </p>

        <h2 id="d1-practical-aspects">Praktické aspekty výpočtů</h2>

        <p>Podíl lze derivovat jako součin (!), a někdy je to výhodnější:

        {`$$\\begin{align*}
            \\left(\\frac{e^x}{x}\\right)' &= \\frac{e^x \\cdot x - e^x \\cdot 1}{x^2} = \\frac{e^x(x - 1)}{x^2} = \\frac{x - 1}{x^2}e^x \\\\
            \\left(\\frac{e^x}{x}\\right)' &= \\left(e^x \\cdot x^{-1}\\right)' = e^x \\cdot x^{-1} + e^x \\cdot (-x^{-2}) = e^x \\left(\\frac{1}{x} - \\frac{1}{x^2}\\right)
        \\end{align*}$$`}

        {/* {`$$\\begin{align*}
            \\left(\\frac{x}{e^x}\\right)' &= \\frac{1 \\cdot e^x - x \\cdot e^x}{(e^x)^2} = \\frac{e^x - x \\cdot e^x}{e^{2x}} = \\frac{e^x(1 - x)}{e^{2x}} = \\frac{1 - x}{e^x} \\\\
            \\left(\\frac{x}{e^x}\\right)' &= \\left(xe^{-x}\\right)' = x'e^{-x} + x(e^{-x})' = e^{-x} - x(e^{-x}) = e^{-x}(1 - x)
        \\end{align*}$$`} */}

        {/* {`$$\\begin{align*}
            \\left(\\frac{\\ln x}{\\sqrt{x}}\\right)' &= \\frac{\\frac{1}{x} \\cdot \\sqrt{x} - \\ln x \\cdot \\frac{1}{2\\sqrt{x}}}{x} = \\frac{1 - \\ln x}{x \\sqrt{x}} \\\\
            \\left(\\frac{\\ln x}{\\sqrt{x}}\\right)' &= \\left(\\ln x \\cdot x^{-1/2}\\right)' = (\\ln x)' \\cdot x^{-1/2} + \\ln x \\cdot (x^{-1/2})' = \\frac{1}{x} \\cdot x^{-1/2} - \\ln x \\cdot \\frac{1}{2x^{3/2}} = \\frac{1 - \\ln x}{x \\sqrt{x}}
        \\end{align*}$$`} */}
        </p>

        <p>Podíl co nejvíc 'podělíme', abychom si šetřili práci:

        {`$$\\begin{align*}
            \\left(\\frac{x+1}{x}\\right)' &= \\frac{1 \\cdot x - (x+1) \\cdot 1}{x^2} = \\frac{x - x - 1}{x^2} = -\\frac{1}{x^2} \\\\
            \\color{green}{\\left(\\frac{x+1}{x}\\right)'} &= \\color{green}{\\left(1 + \\frac{1}{x}\\right)' = 0 - x^{-2} = -\\frac{1}{x^2} \\quad \\text{(efektivnější)}}
        \\end{align*}$$`}
        </p>

        <p>Nesmíme zapomenout na derivaci vnitřní složky:
        {`$$
            (e^{-x})' = e^{-x} \\cdot (-x)' = e^{-x} \\cdot (-1) = -e^{-x} \\\\
        $$`}
        </p>

        <p>Pokud se 'zapomeneme', a za vnitřní složku vezmeme {"$x$"}, nic se nestane:
        {`$$
            (e^{x})' = e^{x} \\cdot x' = e^{x} \\cdot 1 = e^{x} \\\\
        $$`}
        </p>

        <p>Funkce třikrát složená:
        {`$$
            \\left[\\text{tg}\\left(\\ln (2x+1)\\right)\\right]' = \\frac{1}{\\cos^2\\left(\\ln (2x+1)\\right)} \\cdot \\left[\\ln (2x+1)\\right]' = \\frac{1}{\\cos^2(\\ln (2x+1))} \\cdot \\frac{1}{2x+1} \\cdot 2 \\\\
        $$`}
        </p>

        <p>Derivace s logaritmem - šetříme si práci: <br/> <br/>
        a) součin v logaritmu:
        {`$$\\begin{align*}
            \\big[\\ln(x \\sin x)\\big]' &= \\frac{1}{x\\sin x} \\cdot (x\\sin x)' = \\frac{1}{x\\sin x} \\cdot (x'\\sin x + x\\sin' x) = \\frac{1}{x\\sin x} \\cdot (\\sin x + x\\cos x) \\\\
            \\color{green}{\\big[\\ln(x \\sin x)\\big]'} &= \\color{green}{\\left[\\ln(x) + \\ln(\\sin x)\\right]' = \\frac{1}{x} + \\frac{1}{\\sin x} \\cdot \\cos x \\qquad \\text{(efektivnější)}}
        \\end{align*}$$`}
        </p>
        <p>
        b) exponent v logaritmu:
        {`$$\\begin{align*}
            \\big(\\ln x^3 \\big)' &= \\frac{1}{x^3} \\cdot (x^3)' = \\frac{1}{x^3} \\cdot 3x^2 = \\frac{3}{x} \\\\
            \\color{green}{\\big(\\ln x^3 \\big)'} &= \\color{green}{3 \\cdot \\ln'x = 3 \\cdot \\frac{1}{x} = \\frac{3}{x} \\qquad \\text{(efektivnější)}}
        \\end{align*}$$`}
        </p>
        <p>
        c) podíl v logaritmu:
        {`$$\\begin{align*}
            \\small{\\left[\\ln\\left(\\frac{x+1}{x^2}\\right)\\right]'} &\\small{= \\frac{1}{\\frac{x+1}{x^2}} \\cdot \\left(\\frac{x+1}{x^2}\\right)' = \\frac{x^2}{x+1} \\cdot \\frac{x^2 - (x+1) \\cdot 2x}{x^4} = \\frac{1}{x+1} \\cdot \\frac{-x^2 - 2x}{x^2} = -\\frac{x + 2}{x(x+1)}} \\\\
            \\color{green}{\\small{\\left[\\ln\\left(\\frac{x+1}{x^2}\\right)\\right]'}} &= \\color{green}{\\small{\\left[\\ln(x+1)\\right]' - \\left(\\ln x^2 \\right)' = \\frac{1}{x+1} - 2 \\cdot \\frac{1}{x} = \\frac{x - 2(x+1)}{x(x+1)} = -\\frac{x + 2}{x(x+1)}} \\qquad \\text{(efektivnější)}}
        \\end{align*}$$`}
        </p>

        <h2 id="d1-for-fun">Derivování pro radost :)</h2>
        <p>
        {`$$\\begin{align*}
            (x^6)' &= 6x^5, \\\\
            (x^6)' &= \\left(x \\cdot x^5 \\right)' = 1 \\cdot x^5 + x \\cdot 5x^4 = x^5 + 5x^5 = 6x^5, \\\\
            (x^6)' &= \\left(x^2 \\cdot x^4 \\right)' = 2x \\cdot x^4 + x^2 \\cdot 4x^3 = 2x^5 + 4x^5 = 6x^5, \\\\
            (x^6)' &= \\left(x^3 \\cdot x^3 \\right)' = 3x^2 \\cdot x^3 + x^3 \\cdot 3x^2 = 3x^5 + 3x^5 = 6x^5, \\\\
            (x^6)' &= \\left(x^7 \\cdot x^{-1} \\right)' = 7x^6 \\cdot x^{-1} + x^7 \\cdot (-1) \\cdot x^{-2} = 7x^5 - x^5 = 6x^5, \\\\
                   &  \\\\
            (x^6)' &= \\left[(x^2)^3 \\right]' = 3(x^2)^2 \\cdot 2x = 3x^4 \\cdot 2x = 6x^5, \\\\
            (x^6)' &= \\left[(x^3)^2 \\right]' = 2(x^3) \\cdot 3x^2 = 2x^3 \\cdot 3x^2 = 6x^5.
        \\end{align*}$$`}
        </p>

        <h2 id="d1-xx">Derivace {"$x^x$"}</h2>
        <p>Toto není ani dle vozrce {"$x^n$"}, ani dle vzorce {"$a^x$"} (!). Převedeme takto:
        {`$$\\begin{align*}
            y &= x^x = e^{\\ln x^x} = e^{x \\ln x}, \\\\
            y' &= (e^{x \\ln x})' = e^{x \\ln x} \\cdot (x \\ln x)' = x^x (\\ln x + 1).
        \\end{align*}$$`}
        Stejně řešíme případy typu {"$f(x)^{g(x)}$"}, např. {"$(x^2+1)^{\\sin x}$"}.
        </p>

        <h2 id="d1-inverse-function">Derivace inverzní funkce</h2>
        <p>
        {`$$
            (f^{-1})'(x) = \\frac{1}{f'(f^{-1}(x))}.
        $$`}
        Například:
        {`$$
            (\\arcsin x)' = \\frac{1}{\\sin'(\\arcsin x)} = \\frac{1}{\\cos(\\arcsin x)} = \\frac{1}{\\sqrt{1 - \\sin^2 (\\arcsin x)}} = \\frac{1}{\\sqrt{1 - x^2}}.
        $$`}
        Použili jsme převod {"$\\cos t = \\sqrt{1 - \\sin^2 t}$"}, platný na intervalu {"$\\langle -\\frac{\\pi}{2}, \\frac{\\pi}{2}\\rangle$"},
        a převod {"$\\sin(\\arcsin x) = x$"}.
        </p>
        <p>Další příklad:
        {`$$
            (\\ln x)' = \\frac{1}{e^{\\ln{x}}} = \\frac{1}{x}.
        $$`}

        {/* {`$$\\begin{align*}
            (\\text{arctg} x)' &= \\frac{1}{\\text{tg}'(\\text{arctg} x)} = \\frac{1}{\\frac{1}{\\cos^2(\\text{arctg} x)}} = \\cos^2(\\text{arctg} x) = \\frac{1}{1 + \\text{tg}^2(\\text{arctg} x)} = \\frac{1}{1 + x^2}
        \\end{align*}$$`} */}

        </p>
        <Footer/>
        </ChapterWrapper>
    );
}

