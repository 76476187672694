

export enum Path {
    root = "/",
    //
    section_basics = "section-basics",
    section_derivatives = "section-derivatives",
    section_functions = "section-functions",
    section_integration = "section-integration",
    section_probability = "section-probability",
    //
    angles = "angles",
    sincostg_values = "sincostg-values",

    basics_equations = "basics-equations",
    basics_fractions = "basics-fractions",
    basics_sqrt = "basics-sqrt",
    basics_piecewise = "basics-piecewise",
    basics_power_funcs = "basics-power-funcs",
    abs_value = "abs-value",

    domains = "domains",
    sign_of_expr = "sign-of-expr",
    inverse_function = "inverse-function",

    derivatives_1 = "derivatives-1",
    function_graph_transformations = "function-graph-transformations",
    function_graph_transformations2 = "function-graph-transformations2",
    function_line = "function-line",
    function_notation = "function_notation",
    function_parity = "function-parity",
    function_quadratic = "function-quadratic",
    functions_notes = "functions-notes",
    goniometric = "goniometric",
    limits = "limits",
    substitution = "substitution",

    extremes = "extremes",
    asymptotes = "asymptotes",
    function_shape = "function-shape",

    function_zoo = "function-zoo",

    tangent_line = "tangent-line",
    taylor = "taylor",

    integration1 = "integration1",
    integration2 = "integration2",
    integration3 = "integration3",
    integration4 = "integration4",
    integration5 = "integration5",
    integration_definite = "integration-definite",
    integration_fub = "integration-fub",

    polynomial_division = "polynomial-division",
    polynomial_factorization = "polynomial-factorization",
    partial_fractions = "partial-fractions",

    rv_overview = "rv-overview",
    cdf_overview = "cdf-overview",
    transform_fF = "transform-fF",
    expectation = "expectation",
    variance = "variance",
    pcomuptation = "pcomputation",
    pcomuptation_half = "pcomuptation-half",
}