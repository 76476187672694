import React, { useEffect, useRef, useState } from 'react';

import Plot from 'react-plotly.js';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
// import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { MathJaxWrapper } from '~/MathJaxWrapper';
import { MathJax } from 'better-react-mathjax';


export type DistributionProps = {
    computeF: (x: number) => number;
    computef: (x: number) => number;
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
    fname: string;
};


export const areaColor = 'rgba(255,165,0,0.8)'; // #FFB84D
export const fColor = 'rgba(59,117,175,1.0)';


export function IntegralAreaPlot ({ computeF, computef, xmin, xmax, ymin, ymax, fname }: DistributionProps) {
    const [a, seta] = useState(xmin * 0.6 + xmax * 0.4);
    const [b, setb] = useState(xmin * 0.4 + xmax * 0.6);

    const n = React.useMemo(() => 100, []);

    const xValues = React.useMemo(
        () => Array.from({ length: n+1 }, (_, i) => xmin + i * (xmax - xmin) / n)
    , [xmin, xmax, n]);

    const fValues = React.useMemo(() => xValues.map(computef), [xValues, computef]);
    const FValues = React.useMemo(() => xValues.map(computeF), [xValues, computeF]);

    const handleSliderChange = React.useCallback((value: number | number[]) => {
        if (typeof value == 'number') {
            return; // multi slider?
        }
        // console.log(value);
        seta(value[0]);
        setb(value[1]);
    }, []);


    return (
        <div>
            <Plot
                data={[
                    {   // the fill of the area between f and x-axis
                        x: xValues.filter(x => x >= a && x <= b),
                        y: fValues.slice(xValues.findIndex(x => x >= a), xValues.findIndex(x => x > b)),
                        fill: 'tozeroy',
                        type: 'scatter',
                        mode: 'none',
                        fillcolor: areaColor,
                        name: 'Plocha',
                    },
                    {   // the graph of f
                        x: xValues,
                        y: fValues,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'f',
                        line: { color: fColor },
                    },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    title: "", // "$\\int_a^b f(x)\\,\\text{d}x$",
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax] },
                    yaxis: { range: [ymin, ymax] },
                    margin: { r: 150, t: 40, b:20 },
                }}
            />
            <div style={{ paddingLeft: 80, paddingRight: 140 }}>
                {/* <label htmlFor="distribf-x-slider">x</label> */}
                <Slider
                    range
                    min={xmin}
                    max={xmax}
                    step={(xmax - xmin) / n}
                    defaultValue={[a, b]}
                    onChange={handleSliderChange}
                    styles={ { handle: { width: 12, height: 12, borderWidth: 6} } }
                />
            </div>
            <div>
                <MathJaxWrapper>
                <MathJax dynamic={true}>
                {`\\begin{align*}
                    \\int_{${a.toFixed(2)}}^{${b.toFixed(2)}} ${fname} \\, \\mathrm{d}x & = -\\cos(${b.toFixed(2)}) + \\cos(${a.toFixed(2)}) = {\\colorbox{#FFB84D}{${(computeF(b) - computeF(a)).toFixed(2)}}}
                \\end{align*}`}
                </MathJax>
                </MathJaxWrapper>
            </div>
        </div>
    );
}
