import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const DI_table = require('../assets/images/DI_table.png');
const DI_table_restarted = require('../assets/images/DI_table_restarted.png');


export const Integration2Meta = {
    title: "Integrál 2: Per Partes",
    shortTitle: "Integrál 2 (PP)",
    path: Path.integration2,
    element: (sectionNumber:string) => <Integration2 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "int-pp-principle",
    "int-pp-merry",
    "int-pp-timesone",
    "int-di-method",
    "int-pp-stopping",
    "int-pp-transform",
];


export function Integration2({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={Integration2Meta.title} TOCSpec={TOCSpec}>

        <h2 id="int-pp-principle">Princip</h2>
        <p>
        {`$$
            \\int uv' \\,\\text{d}x = uv - \\int u'v \\,\\text{d}x
        $$`}
        Funkci, kterou chceme integrovat, si zapíšeme (rozložíme) jako součin dvou funkcí {"$u$"} a {"$v'$"}.
        Snažíme se trefit rozklad, kde {"$u$"} se derivací zjednodušší, a {"$v'$"} lze snadno integrovat.
        Ne vždy to musí jít - neexistuje obecný návod, jak rozklad najít.
        {/* (jedna mnemotechnická pomůcka je 'LIATE', ale nefunguje univerzálně). */}
        </p>
        <p>
        Per partes lze aplikovat <strong>několikrát za sebou</strong>, typicky např. u integrálů funkcí:
        {`\\begin{align}
            & x^n e^x       && x^n \\sin x     &&  x^n \\cos x \\\\
            % & x^n e^{ax}  &&  x^n \\sin(ax)  &&  x^n \\cos(ax) \\\\
        \\end{align}`}
        {/* {`\\begin{align}
            \\int x^n e^x \\,\\text{d}x  \\qquad \\int x^n \\sin x \\,\\text{d}x  \\qquad \\int x^n \\cos x \\,\\text{d}x
        \\end{align}`} */}
        Příklad:
        {`$$
            \\small{
            \\int {\\color{Orange}{x}} {\\color{RoyalBlue}{e^{3x}}} \\,\\text{d}x = \\left| \\begin{array}{cc}
                        u = {\\color{Orange}{x}} & v' = {\\color{RoyalBlue}{e^{3x}}} \\\\
                        u' = {\\color{Red}{1}} & v = {\\color{Purple}{\\frac{1}{3}e^{3x}}}
                        \\end{array}
                        \\right| = {\\color{Orange}{x}} \\cdot {\\color{Purple}{\\frac{1}{3}e^{3x}}} - \\int {\\color{Red}{1}} \\cdot {\\color{Purple}{\\frac{1}{3}e^{3x}}} \\,\\text{d}x = \\frac{1}{3}x e^{3x} - \\frac{1}{9} e^{3x} + C
        }
        $$`}

        {/* {`\\begin{align}
            \\small{
            \\int x^2 e^{3x} \\,\\text{d}x = \\left| \\begin{array}{cc}
                        u = x^2 & v' = e^{3x} \\\\
                        u' = 2x & v = \\frac{1}{3}e^{3x}
                        \\end{array}
                        \\right| = x^2 \\cdot \\frac{1}{3}e^{3x} - \\int 2x \\cdot \\frac{1}{3}e^{3x} \\,\\text{d}x = } \\\\
            \\small{
            = \\frac{1}{3}x^2 e^{3x} - \\frac{2}{3} \\int x e^{3x} \\,\\text{d}x = \\frac{1}{3}x^2 e^{3x} - \\frac{2}{3} \\left( \\left| \\begin{array}{cc}
                        u = x & v' = e^{3x} \\\\
                        u' = 1 & v = \\frac{1}{3}e^{3x}
                        \\end{array}
                        \\right| \\right) = } \\\\
            \\small{
            = \\frac{1}{3}x^2 e^{3x} - \\frac{2}{3} \\left( x \\cdot \\frac{1}{3}e^{3x} - \\int 1 \\cdot \\frac{1}{3}e^{3x} \\,\\text{d}x \\right) }\\\\

        \\end{align}`} */}
        </p>

        <h2 id="int-pp-merry">Merry-go-round</h2>
        <p>Když nám po opakovaném použití per partes vznikne stejný integrál (potenciálně až na konstantu), řešíme to pomocí "kolotoče":
        {`\\begin{align}
            \\int f(x) \\text{d}x & = \\text{několik členů } + c \\int f(x) \\text{d}x \\\\
                 I & = \\text{několik členů } + c I  \\\\
            I - cI & = \\text{několik členů} \\\\
            I(1-c) & = \\text{několik členů} \\\\
                 I & = \\frac{\\text{několik členů}}{1-c}
        \\end{align}`}
        Takto lze řešit např. integrály funkcí:
        {`$$
            e^x \\sin x  \\qquad e^x \\cos x  \\qquad \\frac{\\ln x}{x}
        $$`}
        {/* {`\\begin{align}
            \\int e^x \\sin x  \\,\\text{d}x  \\qquad \\int e^x \\cos x  \\,\\text{d}x  \\qquad \\int \\frac{\\ln x}{x} \\,\\text{d}x
        \\end{align}`} */}
        </p>

        <h2 id="int-pp-timesone">Rozklad {"$f(x) \\cdot 1$"}</h2>
        <p>V některých případech provedeme rozklad takto:
        {`$$
            f(x) = f(x) \\cdot 1 \\qquad \\left| \\begin{array}{cc}
                        u = f(x) & v' = 1 \\\\
                        u' = f'(x) & v = x
                        \\end{array}
                        \\right|
        $$`}
        Takto lze řešit např. integrály funkcí:
        {`$$
            \\text{arctg}\\,x   \\qquad  \\arcsin x \\qquad  \\ln x  \\qquad \\ln^n x
        $$`}
        {/* {`\\begin{align}
            \\int \\ln x \\,\\text{d}x  \\qquad \\int \\text{arctg}\\,x \\,\\text{d}x  \\qquad \\int \\arcsin x \\,\\text{d}x
        \\end{align}`} */}
        </p>
        Příklad:
        {`$$
            \\small{
            \\int {\\color{Orange}{\\ln x}} \\cdot {\\color{RoyalBlue}{1}} \\,\\text{d}x = \\left| \\begin{array}{cc}
                        u = {\\color{Orange}{\\ln x}} & v' = {\\color{RoyalBlue}{1}} \\\\
                        u' = {\\color{Red}{\\frac{1}{x}}} & v = {\\color{Purple}{x}}
                        \\end{array}
                        \\right| = {\\color{Purple}{x}} \\cdot {\\color{Orange}{\\ln x}} - \\int  {\\color{Red}{\\frac{1}{x}}}\\cdot {\\color{Purple}{x}} \\,\\text{d}x = x \\ln x - x + C
        }
        $$`}

        <h2 id="int-di-method">* DI metoda</h2>
        <p><i>* Toto je hezká věc pro pokročilejší.</i></p>
        <p>
        <span style={{color: "red"}}>Pozor: DI metoda může být rychlejší, ale zároveň skrývá úskalí v ukončení, popř. ve složitějších případech.
        Proto ji lze doporučit používat jen jako způsob zrychlení výpočtu u integrálů, u kterých je Vám už dopředu jasné, jakým způsobem se budou řešit (už je máte typově 'napočítané').
        </span>
        </p>

        <p>DI metoda není jiný způsob výpočtu, je to jen jiný <strong>způsob zápisu</strong> výpočtu.
        Tento zápis je velmi efektivní, protože minimalizuje počet opisování už spočítaných členů.
        Je vhodný pro případy opakovaného použití per partes - hlavně když už <strong>dopředu rozpoznáme</strong>, že integrál se bude řešit opakovaným použitím per partes.
        </p>
        {/* <p>DI znamená Derivace - Integrace.</p> */}
        <p>Princip:
        Stejně tak, jako se n-tá deviace funkce {"$v$"} značí  {"$v^{(n)}$"}, označme jako {"$v^{(-1)}$"} první integrál funkce {"$v$"}, jako {"$v^{(-2)}$"} druhý integrál, atd.
        {`\\begin{align}
            \\int uv' \\,\\text{d}x & = uv - \\int u'v \\,\\text{d}x \\\\
                                    & = uv - \\left(u'v^{(-1)} - \\int u''v^{(-1)} \\,\\text{d}x \\right) \\\\
                                    & = uv - \\left(u'v^{(-1)} - \\left( u''v^{(-2)} - \\int u''' v^{(-2)} \\,\\text{d}x \\right) \\right) \\\\
                                    & = uv - \\left(u'v^{(-1)} - \\left( u''v^{(-2)} - \\left( u''' v^{(-3)} - \\int u^{(4)} v^{(-3)} \\,\\text{d}x \\right) \\right) \\right) \\\\
                                    & = \\ldots
        \\end{align}`}
        Vidíme:
        {`$$
            \\int {\\color{Orange}{u}}{\\color{Aquamarine}{v'}} \\,\\text{d}x = {\\color{Orange}{u}}{\\color{SkyBlue}{v}} - {\\color{RedOrange}{u'}}{\\color{CornflowerBlue}{v^{(-1)}}} +  {\\color{Red}{u''}}{\\color{RoyalBlue}{v^{(-2)}}} - {\\color{Magenta}{u'''}}{\\color{RoyalPurple}{v^{(-3)}}} + \\ldots
        $$`}
        </p>
        DI tabulka:
        {/* {`\\begin{array}{cll} \\\\
        + & {\\color{Orange}{u}}      & {\\color{Aquamarine}{v'}} \\\\
        - & {\\color{RedOrange}{u'}}  & {\\color{SkyBlue}{v}} \\\\
        + & {\\color{Red}{u''}}       & {\\color{CornflowerBlue}{v^{(-1)}}} \\\\
        - & {\\color{Magenta}{u'''}}  & {\\color{RoyalBlue}{v^{(-2)}}} \\\\
            &  & {\\color{RoyalPurple}{v^{(-3)}}} \\\\
                &         &
        \\end{array}`} */}
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={DI_table} style={{width: "18%"}}/>
        </div>
        <ul>
            <li><p>V prvním sloupci píšeme na střídačku {"$+$"}, {"$-$"}; začínáme s {"$+$"}.</p></li>
            <li><p>Ve druhém sloupci postupně derivujeme (D) funkci {"$u$"}.</p></li>
            <li><p>Ve třetím sloupci postupně integrujeme (I) funkci {"$v'$"}.</p></li>
            <li><p>Výsledek píšeme podle lomených čar.</p></li>
        </ul>
        <p>Příklad:
        {`$$
            \\int x^4 e^{-x} \\,\\text{d}x = ?
        $$`}
        DI tabluka:
        {`\\begin{array}{c|r|r}
              & \\text{D} & \\text{I} \\\\ \\hline
            + & x^4 & e^{-x} \\\\
            - & 4x^3 & -e^{-x} \\\\
            + & 12x^2 & e^{-x} \\\\
            - & 24x & -e^{-x} \\\\
            + & 24 & e^{-x} \\\\
            - & 0 & -e^{-x} \\\\
        \\end{array}`}
        Výsledek:
        {`$$
            \\int x^4 e^{-x} \\,\\text{d}x = -x^4 e^{-x} - 4x^3 e^{-x} - 12x^2 e^{-x} - 24x e^{-x} - 24 e^{-x} + C
        $$`}
        Tabulka šetří opisování a zabraňuje chybám ve znaménku.
        </p>

        <h2 id="int-pp-stopping">* DI metoda - zastavení</h2>

        <p>
        U DI metody je potenciálně náročnější rozpoznat, kdy a jak zastavit.
        </p>

        <p>
        0. Příklad nahoře ukazuje zastavení v okamžiku, kdy se proderivujeme k nule. <i>(Toto není vždy zaručeno - zkuste např. derivovat {"$x^{\\frac{5}{2}}$"}...)</i>
        </p>

        <p>1. Každý řádek DI tabulky představuje integrál - který se můžeme rozhodnout řešit jinak než per partes.
        Příklad:
        {`$$
            \\int \\text{arctg}\\,x \\,\\text{d}x= ?
        $$`}
        {`\\begin{array}{c|c|r}
              & \\text{D} & \\text{I} \\\\ \\hline
            {\\color{RoyalBlue}{+}} & {\\color{RoyalBlue}{\\text{arctg}\\,x}}  & 1 \\\\
            {\\color{magenta}{-}} & {\\color{magenta}{\\frac{1}{1+x^2}}}   & {\\color{RoyalBlue}{x}}
        \\end{array}`}
        Spodní řádek už nechceme řešit per partes, ale substitucí:
        {`\\begin{align}
            \\int {\\color{RoyalBlue}{\\text{arctg}\\,x}} \\cdot 1 \\,\\text{d}x & = {\\color{RoyalBlue}{x\\cdot\\text{arctg}\\,x}} \\ {\\color{magenta}{-}} \\int {\\color{magenta}{\\frac{1}{1+x^2}}} \\cdot {\\color{RoyalBlue}{x}} \\,\\text{d}x \\\\
                                    & = x\\cdot\\text{arctg}\\,x - \\frac{1}{2}\\ln(1+x^2) + C
        \\end{align}`}
        </p>

        <p>2. Merry-go-round: na řádku dostaneme stejný integrál, jako ten, ze kterého jsme vyšli (potenciálně až na multiplikativní konstantu). Příklad:
        {`$$
            \\int \\sin x \\cdot e^x \\,\\text{d}x = ?
        $$`}
        {`\\begin{array}{c|c|r}
                & \\text{D} & \\text{I} \\\\ \\hline
              + & \\sin x & e^x \\\\
              - & \\cos x & e^x \\\\
              + & -\\sin x & e^x
        \\end{array}`}
        {`\\begin{align}
            \\int \\sin x \\cdot e^x \\,\\text{d}x & =  \\sin x \\cdot e^x + \\cos x \\cdot e^x - \\int \\sin x \\cdot e^x \\,\\text{d}x \\\\
                                  I  & = e^x (\\sin x + \\cos x) - I \\\\
                                    2I & = e^x (\\sin x + \\cos x) \\\\
                                    I  & = \\frac{e^x}{2} (\\sin x + \\cos x) + C
        \\end{align}`}
        </p>

        <h2 id="int-pp-transform">* DI metoda - transformace</h2>
        <p>Někdy tabulka potřebuje další úpravy, které způsobují částečnou ztrátu výhod DI metody:
        {`$$
            \\int \\ln^2 x \\,\\text{d}x = ?
        $$`}
        {/* {`\\begin{array}{c|c|r}
                & \\text{D} & \\text{I} \\\\ \\hline
              + & \\ln^2 x & 1 \\\\
              - & 2\\ln x \\cdot \\frac{1}{x} & x \\\\
        \\end{array}`}
        {`\\begin{array}{c|c|r}
                & \\text{D} & \\text{I} \\\\ \\hline
              - & 2\\ln x & 1 \\\\
              + & \\frac{2}{x} & x \\\\
        \\end{array}`}
        {`\\begin{array}{c|c|r}
              & \\text{D} & \\text{I} \\\\ \\hline
            + & 2 & 1 \\\\
            - & 0 & x \\\\
        \\end{array}`} */}
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={DI_table_restarted} style={{width: "60%"}}/>
        </div>
        <p>
        V posledním řádku první a druhé tabulky jsem chtěli pokrátit {"$\\frac{1}{x}$"} a {"$x$"} - a to vedlo k restartu procesu v další tabulce. Výsledek:
        {`$$
            \\int \\ln^2 x \\,\\text{d}x = x\\ln^2 x - 2x\\ln x + 2x + C
        $$`}
        V tomto případě už se skoro vyplatí počítat klasické per partes.
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}
