import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const polydiv_ex1 = require('../assets/images/polydiv_ex1.png');
const polydiv_ex2 = require('../assets/images/polydiv_ex2.png');
const polydiv_ex1_steps = require('../assets/images/polydiv_ex1_steps.png');

export const PolynomialDivisionMeta = {
    title: "Dělení polynomů",
    shortTitle: "Dělení polynomů",
    path: Path.polynomial_division,
    element: (sectionNumber:string) => <PolynomialDivision sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "polydiv-algorithm",
    "polydiv-observations",
    "polydiv-simple",
];


export function PolynomialDivision({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={PolynomialDivisionMeta.title} TOCSpec={TOCSpec}>

        <h2 id="polydiv-algorithm">Postup</h2>

        <p>
        Dělení čísel se zbytkem a dělení polynomů se zbytkem pracuje na stejném principu.
        Dělení polynomů potřebujeme, abychom mohli integrovat.
        </p>

        <p>Místo formálního popisu algoritmu dělení si ukážeme příklad, z kterého snad bude postup jasný:
        {`$$\\begin{align*}
            \\frac{2x^3 - x^2 + 5x - 4}{x - 3} & = \\ ? \\\\
        \\end{align*}$$`}
        Po vydělení získáme:
        {`$$\\begin{align*}
            \\frac{2x^3 - x^2 + 5x - 4}{x - 3} & = 2x^2 + 5x + 20 + \\frac{56}{x - 3}
        \\end{align*}$$`}
        Postup:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={polydiv_ex1} style={{width: "85%"}}/>
        </div>
        <p>
        Krok za krokem:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={polydiv_ex1_steps} style={{width: "85%"}}/>
        </div>

        <p>Další příklad:
        {`$$\\begin{align*}
            \\frac{2x^3 - x^2 + 5x - 4}{x^2 + x - 1} & = 2x - 3 + \\frac{10x - 7}{x^2 + x - 1}
        \\end{align*}$$`}
        Postup:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={polydiv_ex2} style={{width: "85%"}}/>
        </div>

        <h2 id="polydiv-observations">Pozorování</h2>

        <p>
        Dělíme polynom stupně {"$n$"} polynomem stupně {"$m$"}, který je stejně velký nebo menší: {"$m = n$"}, {"$m = n-1$"}, {"$m = n-2$"} ...
        Např. polynom stupně 4 můžeme dělit polynomem stupně 4, 3, 2, 1, 0.
        </p>

        <p>
        Podíl je polynom stupně {"$n - m$"}. Např. pokud dělíme polynom stupně 5 polynomem stupně 3, pak podíl bude polynom stupně 2.
        </p>

        <p>
        Zbytek vychází jako polynom stupně menšího než {"$m$"} (tj. menšího než stupně dělitele).
        Např. dělíme-li polynom stupně 5 polynomem stupně 2, pak zbytek bude polynom stupně 1 nebo menší.
        </p>

        <p>Př.
        {`$$\\begin{align*}
            \\frac{(\\text{stupeň } 7)}{(\\text{stupeň } 5)} & = (\\text{stupeň } 2) + \\frac{(\\text{stupeň } 4 \\text{ nebo menší})}{(\\text{stupeň } 5)} \\\\
             & \\\\
            \\frac{(\\text{stupeň } 4)}{(\\text{stupeň } 3)} & = (\\text{stupeň } 1) + \\frac{(\\text{stupeň } 2 \\text{ nebo menší})}{(\\text{stupeň } 3)} \\\\
            & \\\\
            \\frac{(\\text{stupeň } 2)}{(\\text{stupeň } 2)} & = (\\text{stupeň } 0) + \\frac{(\\text{stupeň } 1 \\text{ nebo menší})}{(\\text{stupeň } 2)} \\\\
            & \\\\
            \\frac{(\\text{stupeň } 1)}{(\\text{stupeň } 1)} & = (\\text{stupeň } 0) + \\frac{(\\text{stupeň } 0 )}{(\\text{stupeň } 1)}
        \\end{align*}$$`}
        Polynom stupně 0 je samozřejmě konstanta.
        </p>

        <h2 id="polydiv-simple">Jednoduché případy</h2>

        <p>
        Pokud je situace jednoduchá, můžeme podělení udělat 'od oka' (ale stejně korektně):
        {`$$\\begin{align*}
            \\frac{x}{x + 1} & = \\frac{x \\ {\\textcolor{RoyalBlue}{ + \\ 1 - 1}}}{x + 1} = \\frac{x + 1}{x + 1} - \\frac{1}{x + 1} = 1 - \\frac{1}{x + 1} \\\\
            & \\\\
            \\frac{2x - 1}{x + 1} & = \\frac{2x \\ {\\textcolor{RoyalBlue}{ + \\ 2 - 3}}}{x + 1} = \\frac{2x + 2}{x + 1} - \\frac{3}{x + 1} = 2 - \\frac{3}{x + 1} \\\\
            & \\\\
            \\frac{x^2}{2x^2 - 1} & = \\frac{x^2 \\ {\\textcolor{RoyalBlue}{ - \\ \\frac{1}{2} + \\frac{1}{2}}}}{2x^2 - 1} = \\frac{x^2 - \\frac{1}{2}}{2x^2 - 1} + \\frac{\\frac{1}{2}}{2x^2 - 1} = \\frac{1}{2}\\cdot\\frac{1}{2x^2 - 1} + \\frac{1}{4x^2 - 2}
        \\end{align*}$$`}

        </p>

        <Footer/>
        </ChapterWrapper>
    );
}
