import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import { Path } from '~/paths';

export const SectionIntegrationMeta = {
    title: "Integrály",
    shortTitle: "Integrály",
    path: Path.section_integration,
    element: (sectionNumber:string) => <SectionIntegration sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionIntegration({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionIntegrationMeta.title}>
        <p>
        Zatímco derivace je proces mechanický, který se vždy z principu podaří,
        tak integrace je proces kreativní; a jsou i funkce, které z principu (nikoli z důvodu naší neschopnosti)
        nejde zintegrovat. To proto, že ač primitivní funkce existuje, nemáme pro ni vzorec
        (máme jen spočetně nekonečné množství vzorců, ale všech funkcí je nespočetně mnoho).
        </p>
        <p>Situaci klasicky ilustruje <ExternalLink href="https://xkcd.com/2117/">komiks XKCD</ExternalLink>.
        </p>
        <p>Po výpočtu integrálu vždy děláme <strong>zkoušku zderivováním</strong> výsledku - musí
        vyjít zpátky funkce, kterou jsme integrovali.</p>
        </ChapterWrapper>
    );
}

