import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const VarianceMeta = {
    shortTitle: "$\\small{\\text{D}(X)}$",
    title: "Rozptyl $D(X)$",
    path: Path.variance,
    element: (sectionNumber:string) => <Variance sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];


export function Variance({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={VarianceMeta.title} TOCSpec={TOCSpec}>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>
                {`$$\\begin{align*}
                    \\text{D}(X) & = \\sum_{i=1}^n p(x_i) \\cdot \\left[x_i - \\text{E}(X)\\right]^2 \\\\
                    \\text{D}(X) & = \\sum_{i=1}^n p(x_i) \\cdot x_i^2 \\ - \\ [\\text{E}(X)]^2
                \\end{align*}$$`}
                <i>(dvě úpravy téhož vzorce, dávají stejný výsledek)</i>
                </p>
                <p>
                {"$E(X)$"} si musíme zvlášť předpočítat - viz předchozí kapitola.
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                {`$$\\begin{align*}
                    \\text{D}(X) & = \\int_{-\\infty}^{\\infty} f(x) \\cdot \\left[x - \\text{E}(X)\\right]^2 \\, \\text{d}x \\\\
                    \\text{D}(X) & = \\int_{-\\infty}^{\\infty} f(x) \\cdot x^2 \\, \\text{d}x \\ - \\ [\\text{E}(X)]^2
                \\end{align*}$$`}
                <i>(dvě úpravy téhož vzorce, dávají stejný výsledek)</i>
                </p>
                <p>
                {"$E(X)$"} si musíme zvlášť předpočítat - viz předchozí kapitola.
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Určete {"$\\text{D}(X)$"}:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 & 2 & 3 & 4 \\\\ \\hline
                p(x_i) & 0.5 & 0.3 & 0.2 & 0.1
                \\end{array}
                $$`}
                Předpočítáme střední hodnotu:
                {`$$\\begin{align*}\\small
                \\text{E}(X) & = 0.5 \\cdot 1 + 0.3 \\cdot 2 + 0.2 \\cdot 3 + 0.1 \\cdot 4 \\\\
                             & = 2.1
                \\end{align*}$$`}
                Počítáme:
                {`$$\\begin{align*}\\small
                & \\sum_{i=1}^n p(x_i) \\cdot x_i^2 = \\\\
                & = 0.5 \\cdot 1^2 +  0.3 \\cdot 2^2 + 0.2 \\cdot 3^2 + 0.1 \\cdot 4^2 = \\\\
                & = 0.5 + 1.2 + 1.8 + 1.6 = \\\\
                & = 5.1
                \\end{align*}$$`}
                Celkem:
                {`$$\\begin{align*}\\small
                \\text{D}(X) & = 5.1 - 2.1^2 = 5.1 - 4.41 = 0.69
                \\end{align*}$$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Určete {"$\\text{D}(X)$"}:
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                      x & \\text{ pro } x \\in \\langle 0, 1)  \\\\
                                      (2-x) & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                        0 & \\text{ pro } x \\geq 2 \\end{cases}
                $$`}
                Předpočítáme střední hodnotu:
                {`$$\\begin{align*}\\small
                \\text{E}(X) & = \\int_{-\\infty}^{\\infty} f(x) \\cdot x \\, \\text{d}x = \\\\
                             & = \\int_{0}^{1} x \\cdot x \\, \\text{d}x + \\int_{1}^{2} (2-x) \\cdot x \\, \\text{d}x = \\\\
                             & = \\ldots = 1
                \\end{align*}$$`}
                Počítáme:
                {`$$\\begin{align*}\\small
                &\\int_{-\\infty}^{\\infty} f(x) \\cdot x^2 \\, \\text{d}x = \\\\
                & = \\int_{0}^{1} x \\cdot x^2 \\, \\text{d}x + \\int_{1}^{2} (2-x)  x^2 \\, \\text{d}x = \\\\
                & = \\left[\\frac{x^4}{4}\\right]_0^1 + \\left[\\frac{2x^3}{3} - \\frac{x^4}{4}\\right]_1^2 = \\\\
                & = \\left[\\frac{1}{4} - 0\\right] + \\left[ \\frac{16}{3} - \\frac{16}{4} - \\left(\\frac{2}{3} - \\frac{1}{4}\\right)\\right] = \\\\
                & = \\frac{7}{6}
                \\end{align*}$$`}
                Celkem:
                {`$$\\begin{align*}\\small
                \\text{D}(X) &= \\frac{7}{6} - 1^2 = \\frac{1}{6}
                \\end{align*}$$`}
                </p>

                <p><i>Pozn.: Stačí integrovat přes intervaly, kde je hustota {"$f(x)$"} nenulová.</i></p>
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>Rozptyl musí vyjít vždy nezáporně:
                {`$$
                \\text{D}(X) \\geq 0
                $$`}
                Rozptyl by vyšel {"$0$"} jen tehdy, kdybychom měli diskrétní náhodnou veličinu
                s jedinou hodnotou:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 \\\\ \\hline
                p(x_i) & 1.0
                \\end{array}
                $$`}
                ... a na té by vlastně nebylo nic nádhodného (!). (Nebyl by tu žádný rozptyl možných výsledků.)
                </p>
                <p>V praxi (a v příkladech) tedy očekáváme, že rozptyl vyjde vyloženě kladně:
                {`$$
                \\text{D}(X) > 0
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
            <p>Rozptyl musí vyjít vždy kladně:
                {`$$
                \\text{D}(X) > 0
                $$`}
            </p>
            </div>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

