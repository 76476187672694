import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';
import { ExternalLink } from '~/ExternalLink';

const asympt_1 = require('../assets/images/asympt_1.png');
const asympt_2 = require('../assets/images/asympt_2.png');
const asympt_3 = require('../assets/images/asympt_3.png');
const asympt_m = require('../assets/images/asympt_m.png');



export const AsymptotesMeta = {
    title: "Asymptoty",
    shortTitle: "Asymptoty",
    path: Path.asymptotes,
    element: (sectionNumber:string) => <Asymptotes sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "asym-summary",
    "asym-vert",
    "asym-inf",
    "asym-demos",
];



export function Asymptotes({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={AsymptotesMeta.title} TOCSpec={TOCSpec}>

        <p>
        Ref: Asymptoty na <ExternalLink href={"http://www.realisticky.cz/ucebnice/01%20Matematika%20S%C5%A0/10%20Diferenci%C3%A1ln%C3%AD%20a%20integr%C3%A1ln%C3%AD%20po%C4%8Det/01%20Spojitost,%20limita/13%20Asymptoty%20grafu%20funkce.pdf"}>realisticky</ExternalLink>.
        Příklady na <ExternalLink href={"https://www.priklady.com/cs/index.php/vysetrovani-prubehu-funkce-vlastnosti-funkci/asymptoty-grafu-funkce"}>priklady.com</ExternalLink>.
        Asymptoty na <ExternalLink href={"https://maths.cz/cs/article/view/213-asymptota-funkce.html"}>maths.cz</ExternalLink>.
        </p>

        <h2 id="asym-summary">Shrnutí</h2>
        <p>Asymptoty jsou přímky, ke kterým se 'přimyká' graf funkce. Rozlišujeme dva typy:
        </p>
        <ul>
            <li><p>
            Asymptota bez směrnice = vertikála. Rovnice {"$x = c$"}. V bodech nespojitosti 2. druhu (kde limita z aspoň jedné strany je {"$\\pm\\infty$"}). Není to funkce!
            </p></li>
            <li><p>
            Asymptota se směrnicí = přímka s rovnicí {"$y = ax + b$"}. Počítáme asymptotu do {"$+\\infty$"} a asymptotu do {"$-\\infty$"}. Ani jedna z obou asymptot
            nemusí existovat; pokud exitují obě, mohou být různé nebo stejné.
            </p></li>
        </ul>

        <p>
        <strong>Smysl</strong> asymptoty se směrnicí do {"$+\\infty$"} je ten, že funkce se pro velká {"$x$"} chová cca jako přímka s rovnicí {"$y = ax + b$"}.
        To mj. znamená, že pokud je vzorec funkce komplikovaný na vyčíslení (např. {"$f(x) = \\frac{x^3 + \\sin^2 x}{x^2 + x + 1}$"}), můžeme pro velké {"$x$"} funkční hodnotu
        místo toho vyčíslit asymptotou (zde {"$f(x) \\approx x$"}), přičemž se dopustíme relativně jen malé chyby. (Analogicky pro asymptotu do {"$-\\infty$"}.)
        </p>

        <h2 id="asym-vert">Asymptoty bez směrnice</h2>
        <p>Najdeme body nespojitosti (včetně okrajů intervalů, ze kterých sestává definiční obor, kromě {"$\\pm\\infty$"}), kde limita z aspoň jedné strany je {"$\\pm\\infty$"}.
        Vertikální přímky v těchto bodech jsou asymptoty bez směrnice.
        </p>
        <p>Proč se jim říká bez směrnice? Protože nemohou mít obecnou rovnici přímky ve tvaru {"$y = ax + b$"} (tvar se směrnicí).
        Místo toho mají rovnici ve tvaru {"$x = c$"} (verikála v bodě {"$c$"} je množina všech bodů, jejichž x-ová souřadnice je rovna {"$c$"}, a y-ová souřadnice je libovolná).
        </p>

        <h2 id="asym-inf">Asymptoty se směrnicí</h2>
        <p>Jsou potenciálně dvě: do {"$+\\infty$"} a do {"$-\\infty$"}. Přitom mohou vyjít stejně - společná asymptota do {"$\\pm \\infty$"}.
        {`\\begin{align*}
        \\text{asymptota do } {\\color{BrickRed}{+\\infty}}: && y &= ax + b,  & a &= \\lim_{x \\to {\\color{BrickRed}{\\infty}}} \\frac{f(x)}{x},  & b & = \\lim_{x \\to {\\color{BrickRed}{\\infty}}} f(x) - ax \\\\
        \\text{asymptota do } {\\color{RoyalBlue}{-\\infty}}: && y &= ax + b, & a &= \\lim_{x \\to {\\color{RoyalBlue}{-\\infty}}} \\frac{f(x)}{x}, & b & = \\lim_{x \\to {\\color{RoyalBlue}{-\\infty}}} f(x) - ax
        \\end{align*}`}
        Postup výpočtu (stejný pro obě asymptoty):
        </p>
        <ol>
            <li><p>
            Spočítáme limitu {"$a = \\ldots$"} Pokud vyjde {"$\\pm\\infty$"} nebo neexistuje, asymptota neexistuje. Nepočítáme {"$b$"}, končíme.
            </p></li>
            <li><p>
            Spočítáme limitu {"$b = \\ldots$"} <i>(Zde už se nemůže stát, že by vyšla {"$\\pm\\infty$"} nebo neexistovala.)</i>
            </p></li>
            <li><p>
            Napíšeme rovnici asymptoty: {"$y = ax + b$"}, kde za {"$a$"} dosadíme číselný výsledek z bodu 1. a za {"$b$"} číselný výsledek z bodu 2.
            </p></li>
        </ol>

        <h2 id="asym-demos">Ukázky</h2>

        <p>
        {"$f(x) = x + e^{-x}$"}: Asymptota do {"$+\\infty$"} existuje, asymptota do {"$-\\infty$"} neexistuje.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={asympt_1} style={{width: "60%"}}/>
        </div>

        <p>
        {"$f(x) = \\frac{x^2 + 3x + 1}{2x}$"}: Asymptota do {"$+\\infty$"} i do {"$-\\infty$"} existuje, a je to stejná přímka.
        Kromě toho osa y ({"$x = 0$"}) je asymptotou bez směrnice.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={asympt_2} style={{width: "60%"}}/>
        </div>

        <p>
        {"$f(x) = x - \\text{arctg}(x)$"}: Asymptota do {"$+\\infty$"} i do {"$-\\infty$"} existuje, a jsou to dvě různé přímky.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={asympt_3} style={{width: "60%"}}/>
        </div>

        <p>
        {"$f(x) = \\frac{x}{x^2 - 4} + 1$"}: Asymptota do {"$+\\infty$"} i do {"$-\\infty$"} existuje, a je to vodorovná přímka {"$y = 1$"};
        kromě toho máme dvě asymptoty bez směrnice na pozicích {"$x = 2$"} a {"$x = -2$"}.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={asympt_m} style={{width: "60%"}}/>
        </div>

        {/* tahak example - in tex or Inkscape? */}

        <Footer/>
        </ChapterWrapper>
    );
}

