import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const SectionFunctionsMeta = {
    title: "Funkce",
    shortTitle: "Funkce",
    path: Path.section_functions,
    element: (sectionNumber:string) => <SectionFunctions sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionFunctions({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionFunctionsMeta.title}>
        <p>
        Tato kapitola se věnuje reálným funkcím jedné reálné proměnné.
        </p>

        <p>
        Hezký zdroj na zopakování a procvičení funkcí je <ExternalLink href="https://is.muni.cz/do/rect/el/estud/prif/ps23/metodika_matematiky/web/pages/04_01_funkce.html">zde</ExternalLink>.
        Obsahuje příklady na definiční obor a vlastnosti funkcí, včetně řešení.
        </p>

        </ChapterWrapper>
    );
}

