import * as d3 from "d3";

export const CLS_MAIN = "main";
export const CLS_AXES = "axes";
export const CLS_LINES = "lines";
export const CLS_POINTS = "points";

export function addGroupForLines(contentGroup: d3.Selection<SVGGElement, unknown, null, any>) {
    contentGroup.append("g").attr("class", CLS_LINES);
}

export function addGroupForPoints(contentGroup: d3.Selection<SVGGElement, unknown, null, any>) {
    contentGroup.append("g").attr("class", CLS_POINTS);
}

export function addGroupForAxes(contentGroup: d3.Selection<SVGGElement, unknown, null, any>) {
    contentGroup.append("g").attr("class", CLS_AXES);
    console.log("Add group for axes", contentGroup);
}

export function selectGroupLines(): d3.Selection<SVGGElement, unknown, HTMLElement, any> {
    return d3.select(`g.${CLS_LINES}`);
}

export function selectGroupMain(): d3.Selection<SVGGElement, unknown, HTMLElement, any> {
    return d3.select(`g.${CLS_MAIN}`);
}

export function selectGroupAxes(): d3.Selection<SVGGElement, unknown, HTMLElement, any> {
    return d3.select(`g.${CLS_AXES}`);
}

export function selectPoints(points: number[][]) {
    return d3.select(`g.${CLS_POINTS}`).selectAll("circle").data(points);
}

export interface PlotProps {
    width: number;
    height: number;
    margin: number;
    xScale: d3.ScaleLinear<number, number>;
    yScale: d3.ScaleLinear<number, number>;
    currentXScale: d3.ScaleLinear<number, number>;
    currentYScale: d3.ScaleLinear<number, number>;
}

export function defaultPlotProps(): PlotProps {
    // Set up the axes and scales
    const width = 500;
    const height = 500;
    const margin = 40;

    const xScale = d3
        .scaleLinear()
        .domain([-10, 10])
        .range([margin, width - margin]);
    //.range([0, width]);
    const yScale = d3
        .scaleLinear()
        .domain([-10, 10])
        .range([height - margin, margin]);
    //.range([height, 0]);

    return {
        width: width,
        height: height,
        margin: margin,
        xScale: xScale,
        yScale: yScale,
        currentXScale: xScale,
        currentYScale: yScale,
    };
}

// Reset function to reset zoom without needing access to `zoom`
export function resetZoomWithoutAccess(svgElement: SVGSVGElement | null) {
    if (!svgElement) return;

    const svg = d3.select(svgElement);

    // Access the current zoom transform from the SVG
    // const currentTransform = d3.zoomTransform(svg.node() as any);

    // Reset the zoom transform by setting it to identity
    svg.transition()
        .duration(1)
        .call(
            d3.zoom().transform as any, // Create a new zoom behavior here
            d3.zoomIdentity,
        );
}



export function repositionPointsOnZoom(plotProps: PlotProps) {
    const selector = `g.${CLS_POINTS}`;

    const [xScale, yScale] = [plotProps.currentXScale, plotProps.currentYScale];

    // Select existing circles and update their positions using the new scales
    d3.select(selector)
        .selectAll("circle")
        .attr("cx", (p: any) => xScale(p[0]))
        .attr("cy", (p: any) => yScale(p[1]));

    // Optionally, if labels exist, reposition them as well
    d3.select(selector)
        .selectAll("text")
        .attr("x", (p: any) => xScale(p[0]) + 5)
        .attr("y", (p: any) => yScale(p[1]) - 5);
}

//
// Plot the axes
//
export function plotAxes(plotProps: PlotProps) {
    const g = selectGroupAxes();
    //g.selectAll("*").remove();
    //const { xScale, yScale } = plotProps;
    const [xScale, yScale] = [plotProps.currentXScale, plotProps.currentYScale];

    let gX = g.select("g.x-axis") as any as d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
    if (gX.empty()) {
        gX = g.append("g").attr("class", "x-axis");
    }
    gX.attr("transform", `translate(0, ${yScale(0)})`);
    gX.call(d3.axisBottom(xScale));

    let gY = g.selectAll("g.y-axis") as any as d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
    if (gY.empty()) {
        gY = g.append("g").attr("class", "y-axis");
    }
    gY.attr("transform", `translate(${xScale(0)}, 0)`);
    gY.call(d3.axisLeft(yScale));

    console.log("Plot axes");
}

