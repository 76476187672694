
import {
    AbsValueMeta,
    AnglesMeta,
    AsymptotesMeta,
    BasicsEquationsMeta,
    BasicsFractionsMeta,
    BasicsPiecewiseMeta,
    BasicsPowerFuncsMeta,
    BasicsSqrtMeta,
    Derivatives1Meta,
    DomainsMeta,
    ExtremesMeta,
    FunctionGraphTransformations2Meta,
    FunctionGraphTransformationsMeta,
    FunctionLineMeta,
    FunctionNotationMeta,
    FunctionParityMeta,
    FunctionQuadraticMeta,
    FunctionShapeMeta,
    FunctionsNotesMeta,
    FunctionZooMeta,
    GoniometricMeta,
    Integration1Meta,
    Integration2Meta,
    Integration3Meta,
    Integration4Meta,
    Integration5Meta,
    IntegrationDefiniteMeta,
    IntegrationFubMeta,
    InverseFunctionMeta,
    LimitsMeta,
    PolynomialDivisionMeta,
    PolynomialFactorizationMeta,
    SectionBasicsMeta,
    SectionDerivativesMeta,
    SectionFunctionsMeta,
    SectionIntegrationMeta,
    SignOfExpressionMeta,
    SinCosTgValuesMeta,
    SubstitutionMeta,
    TangentLineMeta,
    TaylorMeta,
} from "./chapters";


export interface Section {
    title: string,
    shortTitle: string,
    path: string,
    element: (sectionNumber:string) => JSX.Element,
    sectionNumber: string,
}

export interface MenuItem {
    section: Section,
    menuitems?: MenuItem[],
}

function m(section: Section, sections?: Section[]): MenuItem {
    if (sections === undefined) {
        return {
            section: section,
        };
    }
    return {
        section: section,
        menuitems: sections.map(s => m(s)),
    };
}

// ORDER MATTERS! This defines the order of items in the menu (!)
export const sections: MenuItem[] = [
    m(
        SectionBasicsMeta,
        [
            BasicsEquationsMeta,
            BasicsFractionsMeta,
            AnglesMeta,
            SinCosTgValuesMeta,
            BasicsPiecewiseMeta,
            FunctionZooMeta,
            FunctionLineMeta,
            FunctionQuadraticMeta,
            BasicsPowerFuncsMeta,
            BasicsSqrtMeta,
            PolynomialDivisionMeta,
            PolynomialFactorizationMeta,
            DomainsMeta,
            SignOfExpressionMeta,
            AbsValueMeta,
        ],
    ),
    m(
        SectionFunctionsMeta,
        [
            SubstitutionMeta,
            FunctionNotationMeta,
            FunctionsNotesMeta,
            FunctionGraphTransformationsMeta,
            FunctionGraphTransformations2Meta,
            FunctionParityMeta,
            InverseFunctionMeta,
            GoniometricMeta,
        ],
    ),
    m(
        SectionDerivativesMeta,
        [
            LimitsMeta,
            Derivatives1Meta,
            TangentLineMeta,
            TaylorMeta,
            ExtremesMeta,
            AsymptotesMeta,
            FunctionShapeMeta,
        ],
    ),
    m(
        SectionIntegrationMeta,
        [
            Integration1Meta,
            Integration2Meta,
            Integration3Meta,
            Integration4Meta,
            Integration5Meta,
            IntegrationDefiniteMeta,
            IntegrationFubMeta,
        ],
    ),
]


function setSectionNumbers(menuitems: MenuItem[], prefix="") {
    let n:number = 1;
    for (const m of menuitems) {
        m.section.sectionNumber = prefix + (prefix ? "." : "") + n.toString();
        // console.log("Setting section number", s.sectionNumber, "for", s.title);
        if (m.menuitems !== undefined) {
            setSectionNumbers(m.menuitems, m.section.sectionNumber);
        }
        n += 1;
    }
}

setSectionNumbers(sections, "");
