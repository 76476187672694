import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';

export const SectionBasicsMeta = {
    title: "Základy",
    shortTitle: "Základy",
    path: Path.section_basics,
    element: (sectionNumber:string) => <SectionBasics sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionBasics({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionBasicsMeta.title}>
        <p>
        Tato kapitola je opakováním známých věcí ze střední školy,
        u kterých ale často nastává neznalost. Zároveň je opakovánim s trochou nadhledu
        a s důrazem na praktické výpočetní obraty.
        </p>
        <p>
        Pokud opakování ze střední školy potřebujete, doporučuji:
        </p>
        <ul>
            <li><p>
            Knihy "Odmaturuj z matematiky" I, II, III (sbírka).
            Knihy jsou výborně zpracované, a jsou k dostání nové nebo z antikváriátů
            (i z online antikvariátů typu reknihy, knihobot atp.).
            </p></li>
            <li><p>
            Valášek (mathematicator)
            </p></li>
            <li><p>
            Isibalo
            </p></li>
        </ul>
        </ChapterWrapper>
    );
}

