import React, { useEffect, useRef, useState } from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';
import { TOCProps, defineTOCElements } from '~/TOC';

const angles = require('../assets/images/angles.png');
const angles_all = require('../assets/images/angles_all.png');
const radians_def = require('../assets/images/radians_def.png');
const radians_ex = require('../assets/images/radians_ex.png');


export const AnglesMeta = {
    title: "Úhly, radiány",
    shortTitle: "Úhly, radiány",
    path: Path.angles,
    element: (sectionNumber:string) => <Angles sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "angles-radians",
    "angles-radtodeg",
    "angles-significant-angles",
];

// udělat úlohu na tabuli - kružnice o poloměru 1m, úhel alpha ~ 15 stupňů,
// jaký je oblouk?
// měřit provázkem ; magnetky? (vykolíkovat)
// úhloměr, metrové prkno (jako pravítko)

export function Angles({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={AnglesMeta.title} TOCSpec={TOCSpec}>
        <h2 id="angles-radians">Radiány</h2>

        <p>
        Radiány jsou definované tak, že velikost úhlu v radiánech je číselně rovna délce oblouku, který úhel vytýká na jednotkové kružnici.
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={radians_def} style={{width: "35%"}}/>
        </div>
        Příklady:
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={radians_ex} style={{width: "75%"}}/>
        </div>

        <h2 id="angles-radtodeg">Přepočty</h2>

        <p>Přepočet radiánů ze stupňů/na stupně se dělá 'trojčlenkou' / 'přímou úměrou':
        {`$$
            x[^{\\circ}] = x[\\text{rad}] \\cdot \\frac{180}{\\pi} \\qquad   x[\\text{rad}] = x[^{\\circ}] \\cdot \\frac{\\pi}{180}
        $$`}
        </p>

        <p>
        Pozn.: Kalkulačky umějí počítat jak v radiánech, tak ve stupních
        - a aktuálně používaná stupnice bývá indikovaná jako [DEG] (stupně) nebo [RAD] (radiány).
        Pokud zadáte např. {"$\\sin(1)$"}, pak:
        </p>
        <ul>
            <li>
            máte-li aktuálně aktivní stupně [DEG], počítáte: {"$\\sin(1^{\\circ}) = 0.017$"},
            </li>
            <li>
            máte-li aktuálně aktivní radiány [RAD], počítáte:
                {"$\\sin(1[\\text{rad}]) = \\sin(57.2^{\\circ}) = 0.841$"}.</li>
        </ul>

        <h2 id="angles-significant-angles">Význačné úhly</h2>

        <p>Tyto úhly je dobré si pamatovat:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={angles} style={{width: "95%"}}/>
        </div>

        <p>Všechny v jednom obrázku:</p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={angles_all} style={{width: "50%"}}/>
        </div>

        <p>Je důležité si pamatovat dělení na čtvrtiny, a vše v prvním kvadrantu - zbytek lze operativně odvodit ze symetrie.</p>
        <Footer/>
        </ChapterWrapper>
    );
}

