import React from 'react';
import { IntegralAreaPlot } from '~/applets/IntegralArea';

import { sin, cos } from 'mathjs'

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const IntegrationDefiniteMeta = {
    title: "Určitý integrál",
    shortTitle: "Určitý integrál",
    path: Path.integration_definite,
    element: (sectionNumber:string) => <IntegrationDefinite sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "int-definite-principle",
    "int-definite-area",
    "int-definite-improper",
];

function f(x: number) {
    // return x*x*x / 4;
    return sin(x);
}

function F(x: number) {
    // return x*x*x*x / 16;
    return -cos(x);
}


export function IntegrationDefinite({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={IntegrationDefiniteMeta.title} TOCSpec={TOCSpec}>

        <p>
        Určitý integrál má <strong>meze</strong>: Dolní mez a horní mez. Integrujeme od {"$-$"} do.
        Výsledkem určitého integrálu je číslo (hodnota, skalár).
        </p>

        <h2 id="int-definite-principle">Princip</h2>
        <p>Je-li {"$\\int f(x)\\,\\text{d}x = F(x)$"}, pak určitý integrál se počítá:
        {`$$
            \\int_a^b f(x) \\,\\text{d}x = \\left[F(x)\\right]_a^b = F(b) - F(a)
        $$`}
        Zápis {"$\\left[F(x)\\right]_a^b$"} znamená, že do {"$F(x)$"} dosadíme horní mez {"$b$"} a
        dolní mez {"$a$"} a hodnoty odečteme.
        </p>
        <p><strong>Základní vozrec:</strong>
        {`$$
            \\int_{-1}^1 x^2 \\,\\text{d}x = \\left[\\frac{1}{3}x^3\\right]_{-1}^1 = \\frac{1}{3}1^3 - \\frac{1}{3}(-1)^3 = \\frac{2}{3}
        $$`}
        </p>
        <p><strong>Per partes:</strong>
        {`\\begin{align*}
            \\int_0^\\pi x\\cos x \\,\\text{d}x = \\left[x\\sin x\\right]_0^\\pi - \\int_0^\\pi \\sin x \\,\\text{d}x & = \\left[x\\sin x\\right]_0^\\pi + \\left[\\cos x\\right]_0^\\pi = \\\\
                                                                                                                  & = (\\pi\\sin \\pi - 0\\sin 0) + (\\cos \\pi - \\cos 0) = - 2
        \\end{align*}`}
        </p>

        <p><strong>Substituce:</strong><br/>
        Při substituci potřebujeme <span style={{color: 'green'}}>přepočítat meze</span>:
        {`\\begin{align*}
            \\int_{\\color{RoyalBlue}{0}}^{\\color{BrickRed}{1}} \\frac{e^x}{e^{x}+1} \\,\\text{d}x = \\begin{array}{|c|}
                t = e^x \\\\
                \\text{d}t = e^x \\,\\text{d}x \\\\
                {\\color{green}\\text{d. mez: } x = {\\color{RoyalBlue}{0}} \\ \\rightarrow \\ t = e^0 = {\\color{Purple}{1}}} \\\\
                {\\color{green}\\text{h. mez: } x = {\\color{BrickRed}{1}} \\ \\rightarrow \\  t = e^1 = {\\color{red}{e}}}
                \\end{array}
            = \\int_{\\color{Purple}{1}}^{\\color{red}{e}} \\frac{1}{t+1} \\,\\text{d}t & = \\left[\\ln(t+1)\\right]_1^e = \\\\
                                                     & = \\ln(e+1) - \\ln(1+1) = 0.62
        \\end{align*}`}
        </p>

        <h2 id="int-definite-area">Význam - plocha</h2>
        <p>Hodnota určitého integrálu je plocha oblasti mezi grafem funkce {"$f$"} a
        osou {"$x$"} na intervalu od {"$a$"} do {"$b$"}.
        </p>
        <p>
        Tato plocha je kladná, pokud je funkce {"$f$"} nad osou {"$x$"} a <strong>záporná</strong>, pokud je pod osou {"$x$"}.
        </p>
        {/* <p>Pokud funkce je na intervalu {"$\\langle a, b \\rangle$"} nad i pod osou,
        kladné a záporné části ploch se 'vyrušují'.
        </p> */}

        <IntegralAreaPlot computef={f} computeF={F} xmin={-5} xmax={5} ymin={-1.2} ymax={1.2} fname={"\\sin x"}/>


        <h2 id="int-definite-improper">Nevlastní integrál</h2>
        <p>Pokud ve výrazu {"$\\left[ F(x) \\right]_a^b$"} nelze přímo dosadit meze, použujeme místo toho limitu:
        {`$$
            \\int_1^\\infty \\frac{1}{x^2} \\,\\text{d}x = \\left[-\\frac{1}{x}\\right]_1^\\infty = \\lim_{x \\to \\infty}\\left(-\\frac{1}{x}\\right) + \\frac{1}{1} = 0 + 1 = 1
        $$`}
        Dosazení limity lze pro zjednodušení zápisu provést i takto, pokud to není neurčitý výraz:
        {`$$
            \\int_1^\\infty \\frac{1}{x^2} \\,\\text{d}x =  \\left[-\\frac{1}{x}\\right]_1^\\infty = -\\frac{1}{\\infty} + \\frac{1}{1} = 0 + 1 = 1
        $$`}
        </p>

        {/* TODO plocha muze byt konecna nebo nekonecna 1/x^2 1/x */}

        <Footer/>
        </ChapterWrapper>
    );
}
