import React  from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

// const angles = require('../assets/images/angles.png');

export const FunctionsNotesMeta = {
    title: "Funkce: poznámky",
    shortTitle: "Funkce: poznámky",
    path: Path.functions_notes,
    element: (sectionNumber:string) => <FunctionsNotes sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "funcnotes-def",
    "funcnotes-spec",
    "funcnotes-narrowing",
    "funcnotes-domainmissing",
    "funcnotes-variousrules",
];

// vysvětlit to od kruhu, napsat že bod patří kruhu K ; pak napsat (x,y) \in f

//

export function FunctionsNotes({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionsNotesMeta.title} TOCSpec={TOCSpec}>

        <h2 id="funcnotes-def">Definice</h2>

        <p>
        <strong>Kartézský součin</strong> dvou množin je množina všech uspořádaných dvojic, kde první prvek pochází z první množiny a druhý prvek z druhé množiny.
        Kartézský součin množin {"$\\mathbb{R}$"} a {"$\\mathbb{R}$"} je množina všech uspořádaných dvojic reálných čísel - tedy bodů (x, y) - tedy rovina,
        v které běžně kreslíme grafy funkcí, křivek, atd.:
        {`$$
            \\mathbb{R} \\times \\mathbb{R} = \\{ (x, y) \\ | \\ x \\in \\mathbb{R}, y \\in \\mathbb{R} \\}
        $$`}
        Je to množina všech bodů (nebo vektorů) s reálnými souřadnicemi dimenze dva.
        </p>

        <p>
        <strong>Relace</strong> je libovolná podmnožina kartézského součinu.
        V rovině {`$\\mathbb{R} \\times \\mathbb{R}$`} je to tedy libovolná podmnožina této roviny - obdélník, kruh, kružnice, křivka, bod, graf libovolné
        funkce, vertikální pás šířky 10, cokoli. Když bod {"$(x, y)$"} patří do podmnožiny {"$R$"} kartézského součinu, říkáme, že
        prvek {"$x$"} je v relaci {"$R$"} s prvkem {"$y$"}.
        </p>

        <p>
        <strong>Funkce</strong> je speciální případ relace, kde žádný prvek {"$x$"} z první množiny
        není v relaci s více než jedním prvkem {"$y$"} z druhé množiny.
        V rovině {`$\\mathbb{R} \\times \\mathbb{R}$`} to znamená, že když uděláme vertikální řez danou relací (podmnožinou plochy)
        na libovolné pozici {"$x$"}, musíme ji protnout v nejvýše jednom bodě.
        Tím se automaticky vylučují množiny (relace) typu plocha, a také křivky, které nad libovolným bodem {"$x$"} procházejí vícekrát.
        </p>

        <p>
        Některá {"$x$"} nemusí být v relaci s žádným {"$y$"} (vertikální řez neprotne relaci v žádném bodě) -
        v těchto {"$x$"} řekneme, že funkce není definovaná.
        V ostatních {"$x$"} máme v relaci vždy právě jeden prvek {"$y$"} - ten označíme jako {"$f(x)$"} a nazveme ho
        hodnotou funkce v bodě {"$x$"}. Funkce je zde definovaná.
        </p>

        <p>
        Množinu těch {"$x$"}, pro které je funkce definovaná, nazýváme definičním oborem funkce: {"$D_f$"}.
        </p>

        <p>
        Main takeaway: <strong>Funkce je množina.</strong> Funkce je množina všech uspořádaných dvojic (argument, hodnota), tj.
        {`$$
            f = \\{ (x, y) \\ | \\ x \\in D_f, y \\text{ dáno jednoznačně pro každé } x \\in D_f  \\}.
        $$`}
        Pokud každý bod {"$(x, y)$"} z funkce {"$f$"} zakreslíme do roviny {`$\\mathbb{R} \\times \\mathbb{R}$`}, dostaneme její graf.
        Funkce je tedy totožná se svým grafem jako podmnožinou roviny {`$\\mathbb{R} \\times \\mathbb{R}$`}. Slova graf funkce
        a funkce jsou synonyma.
        </p>

        <p>
        Když bod {"$(x, y)$"} patří funkci {"$f$"}, píšeme to tradičně jako {"$y = f(x)$"}, a ne jako {"$(x, y) \\in f$"} - což je ale naprosto korektní.
        </p>

        <h2 id="funcnotes-spec">Zadání funkce</h2>

        <p>
        Všimněte si, že doposud nebylo řečeno, že by funkce byla "nějaké pravidlo" nebo "vzorec" ... to proto, že není. Funkce je množina.
        Pokud nějaké pravidlo nebo vzorec máme, funkce je výsledkem aplikace tohoto pravidla na všechny argumenty ze svého
        definiční oboru, zaznamenání dvojic (argument, hodnota) a vytvoření množiny všech těchto dvojic. Tato množina dvojic čísel
        už v sobě nenese žádnou informaci o podobě "vzorce", kterým byla vytvořena.
        </p>

        <p>
        Nastává zde jasné rozlišení mezi "vzorcem / pravidlem" a funkcí jako takovou. A toto rozlišení je potřeba,
        aby člověk mohl uvažovat s jasnou myslí, a nepletl tyto dva koncepty dohromady. Jak si ukážeme, jsou to opravdu různé koncepty:
        </p>

        <p><strong>Různé vzorce mohou definovat stejnou funkci</strong>:
        {`$$\\begin{align*}
             f(x) & = \\sin^2 x,       && D_f = \\mathbb{R}, \\\\
             f(x) & = 1 - \\cos^2 x,   && D_f = \\mathbb{R}, \\\\
             f(x) & = \\frac{1 - \\cos 2x }{2},    && D_f = \\mathbb{R}.
        \\end{align*}$$`}
        Tyto vzorce jsou různé, ale všechny zadávají stejnou funkci. Graf bude ve všech případech stejný. Pro každé {"$x$"} dají všechny stejnou hodnotu.
        </p>

        <p><strong>Stejný vzorec může definovat různé funkce</strong>:
        {`$$\\begin{align*}
             f_1(x) & = x^2,         && D_{f_1} = \\mathbb{R}, \\\\
             f_2(x) & = x^2,         && D_{f_2} = \\langle 0, \\infty), \\\\
             f_2(x) & = x^2,         && D_{f_3} = (\\infty, 0 \\rangle.
        \\end{align*}$$`}
        Vzorec {"$x^2$"} je ve všech případech stejný, ale liší se definiční obory.
        Protože funkce (=graf funkce) je množina bodů v rovině,
        bude v každém případě jiná. Například bod {"$(-2, 4)$"} bude patřit do první funkce, ale ne do druhé.
        </p>

        <p>
        Mohlo by se zdát, že to je "jen slovíčkaření", ale není tomu tak:
        </p>
        <p>
        O funkci {"$f_1$"} nemůžeme říct, že je rostoucí ani klesající. Funkce {"$f_2$"} je rostoucí, funkce {"$f_3$"} klesající.
        Jak by to mohlo platit, kdyby to byly stejné funkce?
        </p>
        <p>
        O první funkci {"$f_1$"} můžeme říct, že je sudá, ale o druhé a třetí to rozhodně říct nemůžeme.
        Jak by to jednou mohlo platit a podruhé ne, kdyby to byly stejné funkce?
        </p>
        <p>
        Funkce {"$f_1$"} není prostá - neexistuje k ní inverzní funkce. Funkce {"$f_2$"} a {"$f_3$"} jsou prosté.
        Inverzní funkce k funkci {"$f_2$"} je funkce {"$f_2^{-1}(x) = \\sqrt{x}, x \\geq 0$"}.
        Inverzní funkce k funkci {"$f_3$"} je funkce {"$f_3^{-1}(x) = -\\sqrt{x}, x \\geq 0$"}.
        Kdyby {"$f_2$"} a  {"$f_3$"} byly stejné funkce, jak by mohly mít různé inverzní funkce?
        </p>


        <p>
        Když tedy chceme <strong>zadat funkci jednoznačně</strong>, bez prostoru pro různé interpretace, musíme zadat
        nejen <strong>vzorec/pravidlo</strong>, ale i <strong>definiční obor</strong>.
        </p>

        <h2 id="funcnotes-narrowing">Zúžení</h2>

        <p>
        Pokud máme funkci definovanou na nějakém definičním oboru, můžeme ji zúžit na podmnožinu tohoto definičního oboru.
        Použijeme původní vzorec/pravidlo, ale zúžíme/zmenšíme definiční obor.
        V příkladu výše jsou funkce {"$f_2$"} i {"$f_3$"} zúžením funkce {"$f_1$"}.
        Zúžená funkce je různá funkce od původní funkce, protože má jiný definiční obor.
        </p>

        <h2 id="funcnotes-domainmissing">Definiční obor nezadaný?</h2>

        <p>Výše jsem si uvedli, že:<br/>
        "Když chceme <strong>zadat funkci jednoznačně</strong>, musíme zadat nejen <strong>vzorec/pravidlo</strong>, ale
        i <strong>definiční obor</strong>."
        </p>

        <p>
        Praxe je ale často jiná. Často se definiční obor nezmiňuje - ale to neznamená, že by nebyl jednoznačně určen -
        pokud se nezmiňuje, pak se jím automaticky rozumí množina všech {"$x$"}, pro které vzorec dává smysl, tedy největší množina argumentů,
        pro které může být funkce daným vzorcem definována.
        </p>

        <p>
        Například když mluvíme o funkci {"$\\log x$"}, myslíme tím funkci {"$\\log x$"} s definičním oborem {"$(0, \\infty)$"}.
        </p>

        <p>Jindy se definiční obor nezmiňuje proto, že se rozumí z kontextu.
        Když se někde dočteme, že {"$\\arcsin x$"}, je inverzní funkce k funkci {"$\\sin x$"},
        pak je jasné, že definiční obor {"$\\sin x$"} v tomto případě je {"$\\langle -\\frac{\\pi}{2}, \\frac{\\pi}{2} \\rangle$"}.
        Předpokládá se totiž, že už jsme prošli studiem, kde jsme se dozvěděli, že k funkci, která není prostá, nelze definovat funkci inverzní;
        pokud ale funkci zúžíme tak, aby prostá byla, inverzní funkci definovat lze;
        a sinus zužujeme standardně na interval {"$\\langle -\\frac{\\pi}{2}, \\frac{\\pi}{2} \\rangle$"}. Pak už se to ale nikde neopakuje -
        předpokládá se znalost.
        </p>

        <h2 id="funcnotes-variousrules">Různá pravidla</h2>

        <p>Pravidlo/vzorec pro zadání funkce může nabýt různých podob:
        </p>
        <ul>
            <li><p>
            {"$f(x) = x^2 - 1$"}, definiční obor {"$\\mathbb{R}$"}. Klasický vzorec.
            </p></li>
            <li><p>
            {"$f(0) = 1, f(n) = nf(n-1)$"}, definiční obor {"$\\{0, 1, 2, \\ldots \\}$"}. Rekurentní vzorec, který definuje faktoriál.
            </p></li>
            <li><p>
            {"$F(x) = \\int_{-\\infty}^x f(t) \\text{d} t$"}. Definiční obor {"$\\mathbb{R}$"}. Definice distribuční funkce {"$F$"} náhodné veličiny pomocí
            hustoty {"$f$"} jejího rozdělení, pomocí integrálu jako funkce horní meze.
            </p></li>
        </ul>
        <p>
        Pravidlo může být libovolně "obskurní", dokud platí, že pro každé {"$x$"} z definičního oboru dává jednoznačně určenou hodnotu.
        </p>

        <p>Obskurní pravidlo, které funguje:<br/>
        Pro každé racionální číslo {"$x = \\frac{p}{q}$"} definujeme hodnotu {"$f(x) = q$"}. Tedy hodnota je číslo ve jmenovateli v základním (nesoudělném) tvaru.
        Základní tvar je jednoznačný, takže hodnota je jednoznačná.
        </p>

        <p>Obskurní pravidlo, které nefunguje:<br/>
        Pro každé reálné číslo {"$x$"} definujeme hodnotu {"$g(x)$"} tak, že je to číslice na prvním místě za desetinnou čárkou
        v desetinném zápisu čísla {"$x$"}.
        </p>

        <p>Tohle nefunguje proto, že např. číslo {"$1$"} má dva různé desetinné zápisy:
        {`$$\\begin{align*}
            1 &= 1.00000\\ldots \\\\
            1 &= 0.99999\\ldots
        \\end{align*}$$`}
        (a podobně každé celé číslo). První číslice za desetinnou čárkou je tedy buď {"$0$"} nebo {"$9$"} - není to jednoznačné.
        Pokud jsme ale o této skutečnosti nevěděli, nemuseli jsme si nejednoznačnosti být vědomi. Proto je potřeba dávat si pořád
        pozor na to, jestli víme, co děláme.
        </p>

        <p>Pozn.: Kdo nevětí, že 1 = 0.9999..., spočítá si součet geometrické řady:
        {`$$
            \\frac{9}{10} + \\frac{9}{100} + \\frac{9}{1000} + \\ldots = \\frac{\\frac{9}{10}}{1 - \\frac{1}{10}} = 1.
        $$`}
        </p>


        <Footer/>
        </ChapterWrapper>
    );
}

