import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const PComputationMeta = {
    shortTitle: "$\\small{P\\left(\\langle a, b\\rangle\\right)}$",
    title: "Výpočet pravděpodobnosti intervalů $\\langle a, b \\rangle$",
    path: Path.pcomuptation,
    element: (sectionNumber:string) => <PComputation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];



export function PComputation({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={PComputationMeta.title} TOCSpec={TOCSpec}>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>A. Výpočet pomocí {"$p(x)$"}:<br/><br/>
                Pro interval {'$I = \\langle a, b \\rangle, \\langle a, b), (a, b\\rangle, (a, b)$'}:
                {`$$
                    P(I) = \\sum_{x_i \\in I} p(x_i)
                $$`}
                <i>Slovně: sečteme pravděpodobnosti těch {"$x_i$"}, které jsou v intervalu {"$I$"}.
                Dávame pozor na krajní body - patří tam, nebo nepatří?
                </i>
                </p>
                <p>&nbsp;</p>
                <p>B. Výpočet pomocí {"$F(x)$"}:<br/><br/>
                Pro interval {"$I = {\\color{red}{(}} a, b {\\color{red}{\\rangle}}$"}:
                {`$$
                    P(I) = F(b) - F(a)
                $$`}
                U jiných intervalů si musíme 'pohrát' s krajními body - přičíst nebo odečíst, dle
                logiky věci, kterou chápeme. U menších příkladů je efektivnější
                převést {"$F(x)$"} na {"$p(x)$"} a pak počítat dle A.
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>A. Výpočet pomocí {"$f(x)$"}:<br/><br/>
                Pro interval {"$I = \\langle a, b \\rangle, \\langle a, b), (a, b\\rangle, (a, b)$"}:
                {`$$
                    P(I) = \\int_{a}^{b} f(x) \\, \\text{d}x
                $$`}
                <i>Slovně: určíme velikost plochy pod grafem {"$f(x)$"} od {"$a$"} do {"$b$"}. Typ intervalu (uzavřený, otevřený) nehraje roli.</i>
                </p>
                <p>&nbsp;</p>
                <p>B. Výpočet pomocí {"$F(x)$"}:<br/><br/>
                Pro interval {"$I = \\langle a, b \\rangle, \\langle a, b), (a, b\\rangle, (a, b)$"}:
                {`$$
                    P(I) = F(b) - F(a)
                $$`}
                <i>Typ intervalu (uzavřený, otevřený) nehraje roli.</i>
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Je dána pravděpodobnostní funkce {"$p(x)$"}:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 & 2 & 3 & 4 \\\\\ \\hline
                p(x_i) & 0.2 & 0.3 & 0.4 & 0.1
                \\end{array}
                $$`}
                Určete pravděpodobnost intervalů:
                {`$$\\begin{align*}
                & P(\\langle 2, 3 \\rangle) = ? \\\\
                & P(\\langle 2, 3)) = ? \\\\
                & P((2, 3 \\rangle) = ? \\\\
                & P((2, 3)) = ?
                \\end{align*}$$`}
                Řešení:
                {`$$
                \\begin{array}{|c|c|c|}
                \\text{I} & \\text{do I patří} & \\text{pravděpodobnost} \\\\ \\hline
                \\langle 2, 3 \\rangle & 2, 3 & 0.3 + 0.4 = 0.7 \\\\
                \\langle 2, 3) & 2 & 0.3 \\\\
                (2, 3 \\rangle & 3 & 0.4 \\\\
                (2, 3) & & 0
                \\end{array}
                $$`}
                </p>
                <p>
                Určete pravděpodobnost intervalů:
                {`$$\\begin{align*}
                & P(\\langle 2.5, 3.5 \\rangle) = ? \\\\
                & P(\\langle 2.5, 3.5)) = ? \\\\
                & P((2.5, 3.5 \\rangle) = ? \\\\
                & P((2.5, 3.5)) = ?
                \\end{align*}$$`}
                Řešení:
                {`$$
                \\begin{array}{|c|c|c|}
                \\text{I} & \\text{do I patří} & \\text{pravděpodobnost} \\\\ \\hline
                \\langle 2.5, 3.5 \\rangle & 3 & 0.4 \\\\
                \\langle 2.5, 3.5) & 3 & 0.4 \\\\
                (2.5, 3.5 \\rangle & 3 & 0.4 \\\\
                (2.5, 3.5) & 3 & 0.4
                \\end{array}
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Je dána hustota pravděpodobnosti {"$f(x)$"}:
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                      x & \\text{ pro } x \\in \\langle 0, 1)  \\\\
                                      (2-x) & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                        0 & \\text{ pro } x \\geq 2 \\end{cases}
                $$`}
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$\\langle 0.1, 0.9 \\rangle$"}:
                {`$$
                \\int_{0.1}^{0.9} x \\, \\text{d}x = \\left[\\frac{x^2}{2}\\right]_{0.1}^{0.9} = \\frac{0.81 - 0.01}{2} = 0.4
                $$`}
                Identický výpočet i výsledek je pro intervaly {"$\\langle 0.1, 0.9)$"}, {"$(0.1, 0.9 \\rangle$"} a {"$(0.1, 0.9)$"}.
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$\\langle 0.5, 1.5 \\rangle$"}:
                {`$$\\begin{align*}
                & \\int_{0.5}^{1} f(x) \\, \\text{d}x = \\\\
                & = \\int_{0.5}^{1} x \\, \\text{d}x + \\int_{1}^{1.5} (2-x) \\, \\text{d}x = \\\\
                & = \\left[\\frac{x^2}{2}\\right]_{0.5}^{1} + \\left[2x - \\frac{x^2}{2}\\right]_{1}^{1.5} = \\\\
                & = \\frac{1 - 0.25}{2} + \\left(3 - \\frac{2.25}{2} - 2 + \\frac{1}{2}\\right) = \\\\
                & = 0.375 + 0.375 = 0.75
                \\end{align*}$$`}
                Identický výpočet i výsledek je pro intervaly {"$\\langle 0.5, 1.5)$"}, {"$(0.5, 1.5 \\rangle$"} a {"$(0.5, 1.5)$"}.
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Je dána distribuční funkce {"$F(x)$"}:
                {`$$
                F(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\
                                      0.2 & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                      0.5 & \\text{ pro } x \\in \\langle 2, 3) \\\\
                                      1 & \\text{ pro } x \\geq 3 \\end{cases}
                $$`}
                </p>
                <p>
                Určete pravděpodobnost intervalu {"$( 1, 2 \\rangle$"}:
                {`$$
                P((1, 2 \\rangle) = F(2) - F(1) = 0.5 - 0.2 = 0.3
                $$`}
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$\\color{BrickRed}{\\langle 1, 2 \\rangle}$"}:
                {`$$\\begin{align*}\\small
                & {\\color{BrickRed}{\\langle 1, 2 \\rangle}} = {\\color{green}{\\{1\\}}} \\cup {\\color{RoyalBlue}{(1, 2 \\rangle}} \\quad \\text{(disjunktní sjednocení)} \\\\
                & P({\\color{BrickRed}{\\langle 1, 2 \\rangle}}) = P({\\color{green}{\\{1\\}}}) + P({\\color{RoyalBlue}{(1, 2 \\rangle}}) \\\\
                & \\ \\\\
                & P({\\color{green}{\\{1\\}}}) = p({\\color{green}{1}}) = | \\text{velikost schodu v x = 1} | =  \\\\
                & = 0.2 - 0 = {\\color{green}{0.2}} \\\\
                & \\ \\\\
                & P({\\color{RoyalBlue}{(1, 2 \\rangle}}) = F(2) - F(1) = {\\color{RoyalBlue}{0.3}} \\\\
                & \\ \\\\
                & P({\\color{BrickRed}{\\langle 1, 2 \\rangle}}) = {\\color{green}{0.2}} + {\\color{RoyalBlue}{0.3}} = 0.5
                \\end{align*}$$`}
                <i>Pozn.: To už vychází lepší převést {"$F(x)$"} na {"$p(x)$"} a použít první metodu.</i>
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Je dána distribuční funkce {"$F(x)$"}:
                {`$$
                F(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                      x^2 & \\text{ pro } x \\in \\langle 0, 1) \\\\
                                      1 & \\text{ pro } x \\geq 1 \\end{cases}
                $$`}
                </p>
                <p>
                Určete pravděpodobnost intervalu {"$\\langle 0.4, 0.6 \\rangle$"}:
                {`$$\\small
                P(\\langle 0.4, 0.6 \\rangle) = F(0.6) - F(0.4) = 0.36 - 0.16 = 0.2
                $$`}
                Identický výpočet i výsledek je pro intervaly {"$\\langle 0.4, 0.6)$"}, {"$(0.4, 0.6 \\rangle$"} a {"$(0.4, 0.6)$"}.
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$\\langle 0.5, 1.5 \\rangle$"}:
                {`$$\\small
                P(\\langle 0.5, 1.5 \\rangle) = F(1.5) - F(0.5) = 1 - 0.25 = 0.75
                $$`}
                Identický výpočet i výsledek je pro intervaly {"$\\langle 0.5, 1.5)$"}, {"$(0.5, 1.5 \\rangle$"} a {"$(0.5, 1.5)$"}.
                </p>
            </div>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

