import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';
import { ExternalLink } from '~/ExternalLink';

// const extremes_5a = require('../assets/images/extremes_5a.png');


export const FunctionShapeMeta = {
    title: "Průběh funkce",
    shortTitle: "Průběh funkce",
    path: Path.function_shape,
    element: (sectionNumber:string) => <FunctionShape sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];


export function FunctionShape({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionShapeMeta.title} TOCSpec={TOCSpec}>

        V podstatě děláme třikrát to stejné (analýzu), a nakonec určujeme asymptoty a kreslíme graf:

        <h2>Analýza (building block)</h2>
        <p>
        Máme funkci {"$g(x)$"} a potřebujeme zjistit:
        </p>
        <ul>
            <li>definiční obor</li>
            <li>body nespojitosti, a limity (zleva, zprava) k těmto bodům</li>
            <li>nulové body (kde je {"$g(x) = 0$"})</li>
            <li>znaménko funkce na intervalech - hraničními body intervalů jsou body nespojitosti, nulové body a hranice definičního oboru</li>
        </ul>

        <h2>Průběh funkce</h2>
        <p>
        Vyšetřujeme průběh funkce {"$f$"}:
        </p>
        {/* <ul>
            <li>Analýza aplikovaná na {"$f$"}. Znaménko říká, kde je funkce nad osou {"$x$"} (kladná) a kde pod ní (záporná).</li>
        </ul> */}
        <table className='simple-table simple-table-prubeh' style={{fontSize: 'smaller', tableLayout: 'auto'}}>
            <thead>
                <tr>
                    <th>Analýza aplikovaná<br/> na</th>
                    <th>Znaménko {"$+$"}</th>
                    <th>Znaménko {"$-$"}</th>
                    <th>Nulové body</th>
                    <th>Body nespojitosti</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$f(x)$"}</td>
                    <td>graf {"$f$"} nad osou {"$x$"}</td>
                    <td>graf {"$f$"} pod osou {"$x$"}</td>
                    <td>kořeny</td>
                    <td>asymptoty bez směrnice (u nesp. 2. druhu); event. může být extrém u nesp. 1. druhu</td>
                </tr>
                <tr>
                    <td>{"$f'(x)$"}</td>
                    <td>{"$f$"} rostoucí {"$\\nearrow$"}</td>
                    <td>{"$f$"} klesající {"$\\searrow$"}</td>
                    <td>extrémy, pokud se v nich mění znaménko {"$f'$"}</td>
                    <td>zlomy, pokud je v nich funkce {"$f$"} spojitá; zlom může být extrém</td>
                </tr>
                <tr>
                    <td>{"$f''(x)$"}</td>
                    <td>{"$f$"} konvexní {"$\\smile$"}</td>
                    <td>{"$f$"} konkávní {"$\\frown$"}</td>
                    <td>inflexní body, pokud se v nich mění znaménko {"$f''$"}</td>
                    <td>inflexní body, pokud se v nich <br/> mění znaménko {"$f''$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Průběh funkce vyšetřujeme tak, že:<br/><br/>
        - aplikujeme analýzu na {"$f$"} <br/>
        - aplikujeme analýzu na {"$f'$"} <br/>
        - aplikujeme analýzu na {"$f''$"} <br/>
        - spočítáme asymptoty <br/>
        - veškeré zjištěné informace nakonec použijeme k nakreslení grafu {"$f$"}
        </p>

        <p>Prakticky toto může být docela na dlouho. Proto na zkouškové písemce lze čekat následující:
        </p>
        <ul>
            <li><p>Zadání bude "určete intervaly monotónnosti", nebo "určete intervaly konvexity", nebo "určete extrémy", nebo "určete asymptoty"
            - prostě budete počítat jen jednu specifickou část průběhu.
            </p></li>
            <li><p>Zadání bude "určete průběh funkce", ale funkce bude velice jednoduchá, např. {"$f(x) = x^2 + x - 2$"}. Zde půjde
            o to provést všechny kroky vyšetření průběhu, ale výpočty budou jednoduché.
            </p></li>
        </ul>

        <h2>Poznámky</h2>
        <p>Někdy se u analýzy {"$f$"} nelze rozumě dopočítat nulových bodů (např. je-li {"$f$"} polynom vyššího stupně, nebo rovnice {"$f(x) = 0$"} vede na např. na rovnici {"$e^{-x} -ax = 0$"} atp.).
        V tom případě se tento krok vynechá a pokračuje se dál. U školských příkladů je naděje, že derivace bude jednodušší funkce než výchozí funkce, takže analýzu znamének a nulových bodů lze s větší nadějí na úspěch provést
        u derivace než u výchozí funkce.
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}

