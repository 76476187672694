import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const TransformfFMeta = {
    title: "Přechod od $f(x),p(x)$ k $F(x)$ a naopak",
    shortTitle: "$\\small{p(x), f(x) \\leftrightarrow F(x)}$",
    path: Path.transform_fF,
    element: (sectionNumber:string) => <TransformfF sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];



export function TransformfF({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={TransformfFMeta.title} TOCSpec={TOCSpec}>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>
                {"$p(x) \\rightarrow F(x)$"}: kumulativní součty zleva
                {`$$
                    F(x) = \\sum_{x_i \\leq x} p(x_i)
                $$`}
                </p>
                <p>
                {"$F(x) \\rightarrow p(x)$"}: výšky schodů (diference)
                {`$$
                    p(x_i) = F(x_i) - F(x_{i-1})
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                    {"$f(x) \\rightarrow F(x)$"}: <span style={{fontSize: 'smaller'}}>plocha pod hustotou od {"$-\\infty$"} po {"$x$"}</span>
                    {`$$
                        F(x) = \\int_{-\\infty}^{x} f(t) \\, \\text{d}t
                    $$`}
                </p>
                <p>
                    {"$F(x) \\rightarrow f(x)$"}: derivace
                    {`$$
                        f(x) = F'(x)
                    $$`}
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Máte {"$p(x)$"}, určete {"$F(x)$"}:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 & 2 & 8 & 9 \\\\ \\hline
                p(x_i) & 0.2 & 0.3 & 0.4 & 0.1
                \\end{array}
                $$`}
                Kumulujeme:
                {`$$\\begin{align*}
                & 0.2, \\\\
                & 0.2 + 0.3 = 0.5, \\\\
                & 0.2 + 0.3 + 0.4 = 0.9, \\\\
                & 0.2 + 0.3 + 0.4 + 0.1 = 1
                \\end{align*}$$`}
                Výsledek:
                {`$$
                    F(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\ 0.2 & \\text{ pro } x \\in \\langle 1, 2) \\\\ 0.5 & \\text{ pro } x \\in \\langle 2, 8) \\\\ 0.9 & \\text{ pro } x \\in \\langle 8, 9) \\\\ 1 & \\text{ pro } x \\geq 9 \\end{cases}
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Máte {"$f(x)$"}, určete {"$F(x)$"}:
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\ e^{-x} & \\text{ pro } x \\geq 0 \\end{cases}
                $$`}
                Integrujeme:
                {`$$\\begin{align*}
                F(x) & = \\int_{-\\infty}^{x} f(t) \\, \\text{d}t = ?\\\\
                \\end{align*}$$`}
                Pro {"$x < 0$"} je:
                {`$$
                    \\int_{-\\infty}^{x} f(t) \\, \\text{d}t = \\int_{-\\infty}^{x} 0 \\, \\text{d}t = 0
                $$`}
                Pro {"$x \\geq 0$"} je:
                {`$$\\begin{align*}
                    \\int_{-\\infty}^{x} f(t) \\, \\text{d}t & = \\int_{-\\infty}^{0} 0 \\, \\text{d}t +  \\int_{0}^{x} e^{-t} \\, \\text{d}t = \\\\
                    & = 0 + \\left[-e^{-t}\\right]_0^x = 1 - e^{-x}
                \\end{align*}$$`}
                Výsledek:
                {`$$
                    F(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\ 1 - e^{-x} & \\text{ pro } x \\geq 0 \\end{cases}
                $$`}
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Máte {"$F(x)$"}, určete {"$p(x)$"}:
                {`$$
                F(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\
                                    0.2 & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                    0.7 & \\text{ pro } x \\in \\langle 2, 3) \\\\
                                      1 & \\text{ pro } x \\geq 3 \\end{cases}
                $$`}
                Diference (výšky schodů):
                {`$$\\begin{align*}
                \\text{v } x = 1: && 0.2 - 0 &= 0.2 \\\\
                \\text{v } x = 2: && 0.7 - 0.2 &= 0.5 \\\\
                \\text{v } x = 3: && 1 - 0.7 &= 0.3
                \\end{align*}$$`}
                Výsledek:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 & 2 & 3 \\\\ \\hline
                p(x_i) & 0.2 & 0.5 & 0.3
                \\end{array}
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
            <p>
            Máte {"$F(x)$"}, určete {"$f(x)$"}:
            {`$$
            F(x) = \\begin{cases} 0 & \\text{ pro } x < 3 \\\\
                                  (x-3)^2 & \\text{ pro } x \\in \\langle 3, 4) \\\\
                                  1 & \\text{ pro } x \\geq 4 \\end{cases}
            $$`}
            Derivujeme:
            {`$$
            F'(x) : \\begin{cases} 0' = 0 &  \\\\
                                    \\left[(x-3)^2\\right]' = 2(x-3) &  \\\\
                                    1' = 0 &
            \\end{cases}
            $$`}
            Výsledek:
            {`$$
            f(x) = \\begin{cases} 0 & \\text{ pro } x < 3 \\\\
                                2(x-3) & \\text{ pro } x \\in \\langle 3, 4) \\\\
                                0 & \\text{ pro } x \\geq 4
            \\end{cases}
            $$`}
            </p>
            </div>
        </div>





        <Footer/>
        </ChapterWrapper>
    );
}

