import React from 'react';
import Footer from './Footer';


export function LandingPage()  {

    return (
        <div className="chapter-container">
        <div className="centered-content">

            <h1>Matematická analýza - přehled</h1>

            <i>Ing. Jan Novotný</i><br/><br/>

            <em>Praktické poznámky ke cvičením v kurzu 'esmat' na ÚSI VUT Brno.</em><br/>

            <p>Ve vývoji...
            </p>


        <Footer/>
        </div>
        </div>
    );
}
