import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const fparity_diagrams = require('../assets/images/fparity_diagrams.png');
const cosex = require('../assets/images/cosex.png');


export const FunctionParityMeta = {
    title: "Liché a sudé funkce",
    shortTitle: "Liché a sudé funkce",
    path: Path.function_parity,
    element: (sectionNumber:string) => <FunctionParity sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "fparity-summary",
    "fparity-odd-even",
    "fparity-computation",
    "fparity-functions",
    "fparity-operations",
    "fparity-domain",
    "fparity-both",
    "fparity-use",
];


export function FunctionParity({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionParityMeta.title} TOCSpec={TOCSpec}>
        <h2 id="fparity-summary">Shrnutí</h2>

        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>Sudé funkce</th>
                    <th>Liché funkce</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Symetrie podle osy y.</td>
                    <td>Symetrie podle středu (0,0).</td>
                </tr>
                <tr>
                    <td>{"$f(x) = f(-x)$"}</td>
                    <td>{"$f(x) = -f(-x)$"}</td>
                </tr>
                <tr>
                    <td>{"$f(-x) = f(x)$"}</td>
                    <td>{"$f(-x) = -f(x)$"}</td>
                </tr>
            </tbody>
        </table>

        <p>
        Ne každá funkce musí být sudá nebo lichá (!). Např. {"$e^x$"} není ani jedno.
        </p>

        <p>
        Test sudosti: Do vzorce {"$f(x)$"} dosadíme&nbsp;
        {"$-x$"} za {"$x$"} a upravujeme, dokud nevyjde původní funkce {"$f(x)$"}.<br/>
        Př.: {"$f(x) = x^2 \\ \\rightarrow \\ f(-x) = (-x)^2 = x^2 = f(x)$"}. /ok: sudá/
        </p>

        <p>Test lichosti: Do vzorce {"$f(x)$"} dosadíme&nbsp;
        {"$-x$"} za {"$x$"} a upravujeme, dokud nevyjde původní funkce s opačným znaménkem: {"$-f(x)$"}.<br/>
        Př.: {"$f(x) = x^3 \\ \\rightarrow \\ f(-x) = (-x)^3 = -x^3 = -f(x)$"}. /ok: lichá/
        </p>


        <h2 id="fparity-odd-even">Lichost, sudost</h2>

        <p>
        Některé funkce jsou speciální tím, že mají 'symetrické' hodnoty:
        </p>


        <ul>
            <li><p>
            Pro funkci {"$\\cos$"} platí, že {"$\\cos(x^{\\circ}) = \\cos(-x^{\\circ})$"}:
            </p>
            <table className='simple-table'>
                <thead>
                    <tr>
                        <td>{"$-x$"}</td><td>{"$x$"}</td><td>{"$\\cos(-x)$"}</td><td>{"$\\cos(x)$"}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$-10^{\\circ}$"}</td>
                        <td>{"$10^{\\circ}$"}</td>
                        <td>0.985</td>
                        <td>0.985</td>
                    </tr>
                    <tr>
                        <td>{"$-20^{\\circ}$"}</td>
                        <td>{"$20^{\\circ}$"}</td>
                        <td>0.940</td>
                        <td>0.940</td>
                    </tr>
                    <tr>
                        <td>{"$-30^{\\circ}$"}</td>
                        <td>{"$30^{\\circ}$"}</td>
                        <td>0.866</td>
                        <td>0.866</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                </tbody>
            </table>
            <p>
            O takové funkci říkáme, že je <strong>sudá</strong>. Platí pro ni {"$f(-x) = f(x)$"}
            </p>
            </li>

            <li>
            <p>
            Pro funkci {"$\\sin$"} platí, že {"$\\sin(-x^{\\circ}) = -\\sin(x^{\\circ})$"}:
            </p>
            <table className='simple-table'>
                <thead>
                    <tr>
                        <td>{"$-x$"}</td><td>{"$x$"}</td><td>{"$\\sin(-x)$"}</td><td>{"$\\sin(x)$"}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{"$-10^{\\circ}$"}</td>
                        <td>{"$10^{\\circ}$"}</td>
                        <td>-0.174</td>
                        <td>0.174</td>
                    </tr>
                    <tr>
                        <td>{"$-20^{\\circ}$"}</td>
                        <td>{"$20^{\\circ}$"}</td>
                        <td>-0.342</td>
                        <td>0.342</td>
                    </tr>
                    <tr>
                        <td>{"$-30^{\\circ}$"}</td>
                        <td>{"$30^{\\circ}$"}</td>
                        <td>-0.500</td>
                        <td>0.500</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                </tbody>
            </table>
            <p>
            O takové funkci říkáme, že je <strong>lichá</strong>. Platí pro ni {"$f(-x) = -f(x)$"}.
            </p>
            </li>
        </ul>

        <p>Toto se odráží v podobě (symetrii) grafu funkce:</p>

        <ul>
        <li><p>
        Graf sudé funkce je zrcadlově symetrický podle osy y:
        Na pozicích {"$x$"} a {"$-x$"} má stejnou hodnotu ("na stejné výšce").
        </p></li>
        <li><p>
        Graf liché funkce je středově symetrický podle počátku:
        Na pozicích {"$x$"} a {"$-x$"} má stejně velkou hodnotu (bráno absolutně), ale s opačným znaménkem.
        </p></li>
        </ul>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={fparity_diagrams} style={{width: "75%"}}/>
        </div>

        <p>
        Pozn.: Náhodně zvolená funkce (např. {"$e^{x}$"}) obecně nebude ani sudá ani lichá - její graf nebude symetrický.
        </p>

        <p>
        Když říkáme, že graf je symetrický podle osy y, pak vlastně říkáme, že:
        </p>
        <ul>
            <li>
            Kdybychom graf převrátili podle osy y, tak padne přesně sám na sebe.
            </li>
            <li>
            Kdybychom definovali převrácenou funkci {"$g(x) = f(-x)$"},
            tak její graf by se přesně kryl s grafem původní funkce {"$f(x)$"}.
            </li>
        </ul>

        <p>
        Když říkáme, že graf je symetrický podle středu, pak vlastně říkáme, že:
        </p>
        <ul>
            <li>
            Kdybychom graf otočili o 180°, tak padne přesně sám na sebe.
            </li>
            <li>
            Kdybychom definovali 'středově převrácenou' funkci {"$g(x) = -f(-x)$"},
            tak její graf by se přesně kryl s grafem původní funkce {"$f(x)$"}.
            </li>
        </ul>

        <h2 id="fparity-computation">Praktické ověření</h2>

        <p>Př.: [sudá] {"$f(x) = \\frac{x^2}{1-x^2}$"}.<br/>
        Dosadíme {"$-x$"} za {"$x$"}: {"$f(-x) = \\frac{(-x)^2}{1-(-x)^2} = \\frac{x^2}{1-x^2} = f(x)$"}.
        {"$\\ \\ \\rightarrow $"} Funkce je sudá.
        </p>

        <p>Př.: [lichá] {"$f(x) = e^x - e^{-x}$"}<br/>
        Dosadíme {"$-x$"} za {"$x$"}: {"$f(-x) = e^{-x} - e^{x} = -(e^x - e^{-x}) = -f(x)$"}.
        {"$\\ \\ \\rightarrow $"} Funkce je lichá.
        </p>

        <p>Př.: [ani sudá ani lichá] {"$f(x) = x + 1$"}<br/>
        Dosadíme {"$-x$"} za {"$x$"}: {"$f(-x) = -x + 1 \\neq f(x), \\neq -f(x)$"}.
        {"$\\ \\ \\rightarrow $"} Funkce není ani sudá ani lichá.
        </p>

        <p>Pozn.: Někdy není na první pohled vidět, jestli se funkce rovnají - vezměme&nbsp;
        {"$f(x) = \\ln\\big(\\frac{1-x}{1+x}\\big).$"}<br/>
        Dosadíme {"$-x$"} za {"$x$"}: {"$f(-x) = \\ln\\big(\\frac{1+x}{1-x}\\big)$"}.
        Nezkušené oko neodhalí vztah k původní funkci, ale platí:
        {`$$
            \\ln\\Big(\\frac{1+x}{1-x}\\Big) = \\ln\\Big[ \\Big(\\frac{1-x}{1+x}\\Big)^{-1}\\Big] = (- 1) \\cdot \\ln\\Big(\\frac{1-x}{1+x}\\Big) = -f(x).
        $$`}
        Funkce je tedy lichá.
        </p>

        <h2 id="fparity-functions">Sudé a liché funkce</h2>

        <p>Sudé funkce jsou např.:
        {`$$
            \\cos(x), |x|, x^2, x^4, x^6, \\ldots, x^{-2}, x^{-4}, x^{-6}, \\ldots
        $$`}
        Všimněte si, že (kromě absolutní hodnoty) jsou to <strong>sudé</strong> mocniny proměnné {"$x$"}.
        A že v Taylorově rozvoji pro {"$\\cos(x)$"} jsou pouze <strong>sudé</strong> mocniny {"$x$"}.
        </p>

        <p>Liché funkce jsou např.:
        {`$$
            \\sin(x), \\text{tg}(x), x, x^3, x^5, x^7, \\ldots, x^{-1}, x^{-3}, x^{-5}, \\ldots
        $$`}
        Všimněte si, že jsou to <strong>liché</strong> mocniny proměnné {"$x$"}.
        A že v Taylorově rozvoji pro {"$\\sin(x)$"} jsou pouze <strong>liché</strong> mocniny {"$x$"}.
        </p>


        <h2 id="fparity-operations">Operace zachovávající sudost/lichost</h2>

        <p>
        [A] Součin, podíl:
        </p>
        <table className='simple-table'>
            <thead>
                <tr>
                    <th>Funkce</th>
                    <th>Analogie s čísly 1 a (-1)</th>
                    <th>Funkce</th>
                    <th>Analogie s čísly 1 a (-1)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>sudá {"$\\cdot$"} sudá = sudá</td>
                    <td>{"$1 \\cdot 1 = 1$"}</td>
                    <td>sudá {"$/$"} sudá = sudá</td>
                    <td>{"$1 / 1 = 1$"}</td>
                </tr>
                <tr>
                    <td>sudá {"$\\cdot$"} lichá = lichá</td>
                    <td>{"$1 \\cdot (-1) = -1$"}</td>
                    <td>sudá {"$/$"} lichá = lichá</td>
                    <td>{"$1 / (-1) = -1$"}</td>
                </tr>
                <tr>
                    <td>lichá {"$\\cdot$"} sudá = lichá</td>
                    <td>{"$(-1) \\cdot 1 = -1$"}</td>
                    <td>lichá {"$/$"} sudá = lichá</td>
                    <td>{"$(-1) / 1 = -1$"}</td>
                </tr>
                <tr>
                    <td>lichá {"$\\cdot$"} lichá = sudá</td>
                    <td>{"$(-1) \\cdot (-1) = 1$"}</td>
                    <td>lichá {"$/$"} lichá = sudá</td>
                    <td>{"$(-1) / (-1) = 1$"}</td>
                </tr>
            </tbody>
        </table>

        <p>Chceme-li např. ukázat, že lichá {"$\\cdot$"} lichá = sudá,
        počítáme:
        {`$$
            f(-x)g(-x) = \\big(-f(x)\\big)\\big(-g(x)\\big) = f(x)g(x)
        $$`}
        </p>

        <p>
        [B] Součet, rozdíl:
        </p>
        <table className='simple-table'>
            {/* <thead>
                <tr>
                    <th>Funkce</th>
                </tr>
            </thead> */}
            <tbody>
                <tr>
                    <td>sudá {"$\\pm$"} sudá = sudá</td>
                </tr>
                <tr>
                    <td>lichá {"$\\pm$"} lichá = lichá</td>
                </tr>
            </tbody>
        </table>

        <p>
        [C] {"$f\\big($"}sudá{"$\\big)$"} = sudá:
        </p>

        <p>
        Složení libovolné funkce se sudou funkcí (sudá je vnitřní složka) je sudá funkce.
        Např. {"$e^{x^2}$"} je sudá funkce, {"$\\sin(x^2)$"} je sudá funkce, {"$\\frac{1}{1+x^2}$"} je sudá funkce, atd.
        </p>

        <p>
        [D1] sudá(lichá) = sudá:
        </p>
        <p>
        Složení sudé funkce s lichou funkcí (lichá je vnitřní složka) je sudá funkce.
        Např. {"$\\cos(x^3)$"} je sudá funkce.
        Obecně to neplatí, když do sudé funkce vložíme nesymetrickou funkci, např. {"$e^x$"},
        potom výsledná funkce nemusí být sudá: {"$\\cos(e^x)$"}:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={cosex} style={{width: "35%"}}/>
        </div>

        <p>
        [D2] lichá(lichá) = lichá:
        </p>
        <p>
        Ověření: {"$f\\big(g(-x)\\big) = f\\big(-g(x)\\big) = -f\\big(g(x)\\big)$"} /ok: lichá/. Příklad: {"$(x^3)^5 = x^{15}$"}.
        </p>

        <p>
        [E] Lineární komibnace:
        </p>
        <p>
        Lineární kombinace sudých funkcí je sudá funkce,<br/>
        lineární kombinace lichých funkcí je lichá funkce.
        </p>

        <p>
        [F] Inverzní funkce:
        </p>
        <p>
        Pokud je lichá funkce prostá (podmínka existence inverzní funkce), pak její inverzní funkce je také lichá.
        Např. {"$\\text{atctg}, \\arcsin$"} jsou liché funkce.
        Sudá funkce není prostá*, takže nemá inverzní funkci.<br/>
        <i>Otázka: Cosinus je sudý, tak jak to, že existuje {"$\\arccos$"}? Odpověď: {"$\\arccos$"} se definuje jako inverzní funkce k funkci "{"$\\cos$"} na intervalu 0 až {"$\\pi$"}".
        To znamená, že vezmeme vzorec {"$x \\mapsto \\cos(x)$"} a definujeme jím funkci pouze na intervalu 0 až {"$\\pi$"}. (Je to restrikce "celé" funkce {"$\\cos$"} na tento interval.)
        Tato funkce je prostá, a není sudá (její graf je jen v pravo od osy y, rozhodně není symetrický podle osy y). Viz sekce "Definiční obor" níže.</i>
        </p>
        <p>*Leda by její definiční obor byl jediný bod, a to nula: {"$D_f = \\{0\\}$"}.
        </p>

        <h2 id="fparity-domain">Definiční obor</h2>
        <p>Abychom o funkci mohli říct, že je sudá nebo lichá,
        musí mít <strong>symetrický definiční obor</strong>.
        </p>
        <p>
        Je-li např.  {"$x = 1 \\in D_f$"}, pak nutně také {"$-x = -1 \\in D_f$"},
        jinak by zápis {"$f(-x) = f(-1)$"} nebyl platný.
        </p>

        <h2 id="fparity-both">Funkce sudá a lichá současně</h2>

        <p>Aby funkce byla současně sudá i lichá, musí současně platit:
        {`$$\\begin{align*}
            f(x) &= f(-x), \\\\
            f(x) &= -f(-x).
        \\end{align*}$$`}
        Sečtením rovnic dostaneme {"$2f(x) = 0$"}, tedy {"$f(x) = 0$"}.
        Jediná funkce, která je současně sudá i lichá, je tedy nulová funkce.
        </p>

        <p><i>Otázka: Kdybychom se vyjádřili matematicky 100% přesně (s přesnou
        definicí funkce jako množiny uspořádaných dvojic), pak bychom mohli
        konstatovat, že existuje více různých funkcí, které jsou současně sudé i liché.
        Čím by se tyto funkce lišily? Dokážete uvést příklad?</i>
        </p>

        <h2 id="fparity-use">Praktické využití</h2>

        <p>Pro výpočet {"$f(x)$"}:
        např. chceme-li určit {"$\\cos(-0.1)$"}, můžeme si ušetřit jedno kliknutí
        (na minus) na kalkulačce a zadat místo toho {"$\\cos(0.1)$"}.
        </p>

        <p>Pro výpočet integrálů:</p>
        <p>Integrál liché funkce přes symetrický interval je 0:
        {`$$
            \\int_{-a}^{a} \\text{lichá}(x)\\text{d} x= 0.
        $$`}
        </p>
        <p>Integrál sudé funkce přes symetrický interval je 2x integrál přes polovinu intervalu:
        {`$$
            \\int_{-a}^{a} \\text{sudá}(x)\\text{d} x = 2 \\int_{0}^{a} \\text{sudá}(x)\\text{d} x.
        $$`}
        </p>

        <p>
        Symetrická rozdělení pravděpodobnosti mají sudou hustotu
        nebo pravděpodobnostní funkci. Kvantily normálního rozdělení N(0,1)
        a Studentova t-rozdělení jsou tabelovány jen pro kladné hodnoty (záporné
        se odvodí ze symetrie = ze sudosti hustoty).
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}

