import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';
import { ExternalLink } from '~/ExternalLink';

const extremes_5a = require('../assets/images/extremes_5a.png');
const extremes_5b = require('../assets/images/extremes_5b.png');


export const ExtremesMeta = {
    title: "Extrémy",
    shortTitle: "Extrémy",
    path: Path.extremes,
    element: (sectionNumber:string) => <Extremes sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "ext-summary",
    "ext-stac",
    "ext-borders",
    "ext-breaks",
    "ext-discont",
    "ext-glob",
];


// hrot není obecný pojem pro 'špici'

// vezmi si stéblo

export function Extremes({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={ExtremesMeta.title} TOCSpec={TOCSpec}>

        <p>
        Hezky pojednané extrémy na <ExternalLink href={"https://www.karlin.mff.cuni.cz/~portal/karel.trnka/derivace/?page=34lokextremtm"}>CUNI</ExternalLink>.
        Extrémy na <ExternalLink href={"https://www.matweb.cz/extremy-funkce/"}>matweb</ExternalLink>.
        Příklady s výsledky na <ExternalLink href={"https://www.priklady.com/cs/index.php/vysetrovani-prubehu-funkce-vlastnosti-funkci/lokalni-extremy-funkce"}>priklady.com</ExternalLink>.
        </p>

        {/* <h2>Motivace</h2>
        <p>Pokud je nějaká závislost monotónní</p> */}

        <h2 id="ext-summary">Shrnutí</h2>

        <p>Lokální extrém funkce může nastat:</p>
        <ul>
            <li>1. Ve stacionárním bodě, tj. tam, kde {"$f'(x) = 0$"}</li>
            <li>2. V krajním bodě zadaného intervalu (když zadání říká, že hledáme extrém na daném intervalu).</li>
        </ul>
        A potom také:
        <ul>
            <li>3. V krajních bodech intervalů, ze kterých sestává definiční obor.</li>
            <li>4. V bodě, kde graf funkce má 'zlom' - funkce je tam spojitá, ale derivace zleva a zprava se neshodují - funkce tam nemá derivaci.</li>
            <li>5. V bodě, kde je funkce definovaná ale nespojitá.</li>
        </ul>

        <p>V každém z pěti případů v daném bodě lokální extrém nastat může, ale také nemusí. Čili tyto body jsou
        apriori jen <strong>podezřelé</strong> z existence extrému - dokud je neprozkoumáme blíže.
        </p>

        <p>Na obrázku níže je ilustrováno všech 5 případů, a extrém nastává:</p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={extremes_5a} style={{width: "95%"}}/>
        </div>

        <p>Na obrázku níže je ilustrováno všech 5 případů, a extrém nenastává:</p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={extremes_5b} style={{width: "95%"}}/>
        </div>

        {/* {`\\begin{align*}
            f(x) = \\begin{cases}
                x(x - 1) \\sin \\frac{1}{x(x - 1)}  \\\\
                 \\qquad \\quad \\small{\\text{pro } x \\in (0, 1),} \\\\
                0 \\\\
                \\qquad \\quad \\small{\\text{pro } x = 0, x = 1}
                \\end{cases}
        \\end{align*}`} */}


        {/* {`\\begin{align*}
            f(x) = \\begin{cases}
                4(x^2 - \\frac{1}{64}) \\sin \\frac{1}{\\sqrt{x^2 - \\frac{1}{64}}}  \\\\
                 \\qquad \\small{\\text{pro } x \\in (-\\frac{1}{8}, \\frac{1}{8}),} \\\\
                0 \\\\
                \\qquad \\small{\\text{pro } x = \\pm \\frac{1}{8}}
                \\end{cases}
        \\end{align*}`} */}

        <p>
        Když hledáme globální extrém na zadaném intervalu, tak podezřelé body (stacionární, zlomy, ...)
        ležící mimo tento interval můžeme ignorovat - nezajímají nás. Vybíráme jen z těch, které leží v zadaném intervalu.
        </p>

        <p>Pokud nám jde o to určit, zda v podezřelém bodě extrém je nebo není, můžeme ověřit nutné a postačující podmínky.
        Níže si je uvádíme pro scénář 1. a 4. V ostatních případech je sepsání podmínek náročnější na detail.
        </p>

        {/* <p>Pokud je funkce shora omezená, a má-li alespoň jedno lokální maximum, pak globální maximum je největším z lokálních maxim (může jich být více).
        Jinak globální maximum neexistuje:
        </p>
        <ul>
        <li><p>Funkce {"$f(x) = \\text{arctg} x $"} je shora omezená, ale nemá žádné lokální maximum. Tím pádem nemá ani globální maximum.</p></li>
        <li><p>Funkce {"$f(x) = x^4- x^2$"} má lokální maximum v bodě {"$x = 0$"}, ale není shora omezená. Takže nemá globální maximum.</p></li>
        </ul>

        <p>(Analogicky pro minimum a omezenost zdola.)</p> */}

        <p>
        <ExternalLink href="https://cs.wikipedia.org/wiki/Weierstrassova_v%C4%9Bta">Weierstrassova věta</ExternalLink> říká, že <strong>spojitá</strong> funkce
        na <strong>uzavřeném intervalu</strong> nabývá (globálního) maxima a minima.
        Pokud máme za úkol najít globální maximum nebo minimum spojité funkce na uzavřeném intervalu,
        pak stačí vyčíslit hodnotu ve všech podezřelých bodech (v tomto případě jsou relevantní 1., 2. a 4.), bez nutnosti určovat pro každý z nich
        individuálně, zda je to extrém, a jaký. Největší z vyčíslených hodnot je globální maximum, nejmenší globální minimum.
        </p>
        {/* <p>Pokud je funkce (spojitá i nespojitá) na zadaném intervalu (otevřeném i uzavřeném) nebo na definičním oboru shora omezená,
        a má aspoň jedno lokální maximum,
        </p>
        <p>Pokud je funkce na zadaném intervalu nebo na definičním oboru zdola omezená, lze aplikovat
        stejný postup pro minimum.
        </p> */}



        <h2 id="ext-stac">1. Stacionární body</h2>

        <p>Lokální extrém hledáme řešením rovnice {"$f'(x) = 0$"}, čímž získáme tzv. <strong>stacionární</strong> body
        = body <strong>podezřelé</strong> z toho, že by mohly být extrémy (ale taky to mohou být inflexní body).
        </p>
        <p>Abychom zjistili, zda podezřelý bod je <strong>min</strong>, <strong>max</strong>, nebo <strong>inflexní bod</strong>, máme k dispozici dva přístupy:
        </p>
        <ul>
            <li>Určíme znaménko {"$f'(x)$"} vlevo a vpravo od podezřelého bodu:
                <table className='simple-table'>
                    <tbody>
                    <tr>
                        <td>{"$+ \\nearrow | - \\searrow$"}</td>
                        <td>{"$- \\searrow | + \\nearrow$"}</td>
                        <td>{"$+ \\nearrow | + \\nearrow$"}</td>
                        <td>{"$- \\searrow | - \\searrow$"}</td>
                    </tr>
                    <tr>
                        <td>maximum</td>
                        <td>minimum</td>
                        <td>inflexní bod</td>
                        <td>inflexní bod</td>
                    </tr>
                    </tbody>
                </table>
                <p></p>
            </li>
            <li>Použijeme druhou derivaci vyčíslenou v podezřelém bodě (označíme si ho {"$a$"}):
                <table className='simple-table'>
                    <tbody>
                    <tr>
                        <td>{"$f''(a) < 0$"}</td>
                        <td>{"$f''(a) > 0$"}</td>
                        <td>{"$f''(a) = 0$"}</td>
                    </tr>
                    <tr>
                        <td>maximum</td>
                        <td>minimum</td>
                        <td style={{'color': 'red'}}>nevíme</td>
                    </tr>
                    </tbody>
                </table>
                <p>Tato metoda má nevýhodu, že nevede k rozhodnutí v případě, kdy druhá derivace je nulová.
                Všimněte si funkcí {"$f(x) = x^4$"} a {"$f(x) =  -x^4$"} a {"$f(x) = x^3$"}.
                Všechny tyto funkce mají v bodě {"$a = 0$"} druhou derivaci nulovou, ale první tam má minimum,
                druhá maximum a třetí inflexní bod (!).
                </p>
                <p>
                Zobecněné kritérium, které funguje vždy, je následující:
                </p>
                <p>Počítáme postupně druhou, třetí, čtvrtou, ... derivaci vyčíslenou v podezřelém bodě {"$a$"}, dokud nenarazíme na <strong>první nenulovou hodnotu</strong>.
                Dejme tomu, že jsem se zastavili u {"$n$"}-té derivace. Potom:
                </p>
                <ul>
                    <li>{"$n$"} je sudé: Je-li {"$f^{(n)}(a) > 0$"}: minimum, je-li {"$f^{(n)}(a) < 0$"}: maximum.
                    </li>
                    <li>{"$n$"} je liché: inflexní bod.</li>
                </ul>
                <p>
                Př.: Funkce {"$f(x) = x^4$"}:
                {`\\begin{align*}
                    f'(x) &= 4x^3 & f'(0) &= 0 \\\\
                    f''(x) &= 12x^2 & f''(0) &= 0 \\\\
                    f'''(x) &= 24x & f'''(0) &= 0 \\\\
                    f^{(4)}(x) &= 24 & f^{(4)}(0) &= 24 > 0 \\ \\ \\text{zde se zastavíme}
                \\end{align*}`}
                První nenulová derivace byla čtvrtá - čtyři je sudé číslo - takže je zde buď min nebo max; hodnota je kladná, takže je zde min.
                </p>
                <p>
                Př.: Funkce {"$f(x) = x^3$"}:
                {`\\begin{align*}
                    f'(x) &= 3x^2 & f'(0) &= 0 \\\\
                    f''(x) &= 6x & f''(0) &= 0 \\\\
                    f'''(x) &= 6 & f'''(0) &= 6 > 0 \\ \\ \\text{zde se zastavíme}
                \\end{align*}`}
                První nenulová derivace byla třetí - tři je liché číslo - takže je zde inflexní bod.
                </p>
            </li>
        </ul>

        <h2 id="ext-borders">2. + 3. Okraje intervalu</h2>
        <p>Pokud je funkce definovaná na celém {"$\\mathbb{R}$"}, a hledáme glob. extrémy na celém {"$\\mathbb{R}$"}, pak zde není co řešit.</p>

        <p>
        Pokud lze krajní bod intervalu dosadit (funkce je v něm definovaná), pak v něm může být lokální extrém.
        </p>

        <p>
        Funkce {"$\\sqrt{x}$"} má definiční obor {"$\\langle0, \\infty)$"}. Levý okraj lze dosadit, je v něm lokální i globální minimum.
        Pravý okraj je nevlastní - nelze dosadit.
        </p>

        <p>
        Funkce {"$\\arcsin x$"} má definiční obor {"$\\langle-1, 1\\rangle$"}. Levý okraj lze dosadit, je v něm lokální i globální maximum: {"$\\arcsin -1 = -\\frac{\\pi}{2}$"}.
        Pravý okraj lze dosadit, je v něm lokální i globální maximum: {"$\\arcsin 1 = \\frac{\\pi}{2}$"}.
        </p>

        <p>
        Funkce {"$\\ln x$"} má definiční obor {"$(0, \\infty)$"}. Levý okraj nelze dosadit, pravý okraj nelze dosadit.
        </p>

        <p>
        Funkce {"$\\frac{1}{x}$"} má definiční obor sestávající ze dvou intervalů:
        {"$(- \\infty, 0) \\cup (0, \\infty)$"}. Žádný z okrajů nelze dosadit.
        </p>

        <p>
        Máme-li v zadání řečeno, že hledáme extrémy na intervalu {"$I=\\langle a, b\\rangle$"},
        pak se nám může stát, že v bodech {"$a$"} a {"$b$"} bude globální extrém na intervalu {"$I$"}.
        Spočítáme tedy {"$f(a)$"} a {"$f(b)$"} a porovnáme s dalšími lokálními extrémy.
        </p>


        <h2 id="ext-breaks">4. Zlomy</h2>
        <p>V bodě, je funkce definovaná a spojitá, ale kde zároveň neexistuje její derivace, určíme derivaci zleva a zprava (limitou).
        Pokud se neshodují, je tam zlom. Ne každý zlom je ale extrém - záleží na tom, zda se ve zlomu mění znaménko derivace:
        </p>
        <table className='simple-table'>
            <tbody>
            <tr>
                <td>{"$+ \\nearrow | - \\searrow$"}</td>
                <td>{"$- \\searrow | + \\nearrow$"}</td>
                <td>{"$+ \\nearrow | + \\nearrow$"}</td>
                <td>{"$- \\searrow | - \\searrow$"}</td>
            </tr>
            <tr>
                <td>maximum</td>
                <td>minimum</td>
                <td>není extrém</td>
                <td>není extrém</td>
            </tr>
            </tbody>
        </table>
        <p>
        Např. funkce {"$f(x) = |x|$"} má v bodě {"$x = 0$"} zlom, a má tam minimum. Derivace zleva je -1, zprava 1.
        </p>
        <p>Funkce {"$f(x) = x - \\frac{1}{2}|x|$"} má v bodě {"$x = 0$"} zlom, ale nemá tam extrém. Derivace zleva je {"$-\\frac{3}{2}$"}, zprava {"$- \\frac{1}{2}$"}.
        </p>
        <p>Pokud je zlom 'nekonečně špičatý', jmenuje se hrot.
        Např funkce {"$f(x) = \\sqrt[3]{x}$"} má v bodě {"$x = 0$"} hrot.
        </p>
        {/* Dát grafy a vzorce příkladů rovnou do tabulky */}

        <h2 id="ext-discont">5. Body nespojitosti, kde je funkce definovaná</h2>

        <p>Toto se týká 'divnějších' funkcí, takže se s tím spíše nepotkáte.</p>
        <p>Např funkce {"$f(x) = |\\text{sgn}(x)|$"} má v bodě {"$x = 0$"} minimum.</p>

        <p>Běžnější případ je totiž ten, že funkce (např. {"$\\frac{1}{x}$"}) v bodě nespojitosti definovaná není.
        Tam pak samozřejmě není extrém.
        </p>

        <h2 id="ext-glob">Globální extrém</h2>
        <p>
        Globální extrém nemusí existovat. Ukažme si to na maximu:
        </p>
        <ul>
            <li><p>Funkce {"$f(x) = x^2$"} není shora omezená - nemá maximum (nabývá libovolně velkých hodnot).</p></li>
            <li><p>Funkce {"$f(x) = \\text{arctg} x $"} je shora omezená, ale nemá globální maximum.</p></li>
            <li><p>Funkce {"$f(x) = \\frac{1}{1+16x^2} - \\frac{2}{1+x^2}$"} je shora omezená a dokonce má i lokální maximum, ale nemá globální maximum.</p></li>
        </ul>
        <p>
        Vykreslete si grafy!
        </p>
        <p>
        Když globální extrém existuje, je zároveň i lokálním extrémem. Např. globální maximum, pokud existuje,
        nastává v jednom z lokálních maxim (nebo i ve více, pokud mají stejnou hodnotu/výšku).
        </p>
        <Footer/>
        </ChapterWrapper>
    );
}

