import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const ExpectationMeta = {
    shortTitle: "$\\small{\\text{E}(X)}$",
    title: "Střední hodnota $E(X)$",
    path: Path.expectation,
    element: (sectionNumber:string) => <Expectation sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];


export function Expectation({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={ExpectationMeta.title} TOCSpec={TOCSpec}>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>
                {`$$
                    \\text{E}(X) = \\sum_{i=1}^n p(x_i) \\cdot x_i
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                {`$$
                    \\text{E}(X) = \\int_{-\\infty}^{\\infty} f(x) \\cdot x \\, \\text{d}x
                $$`}
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Určete {"$\\text{E}(X)$"}:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 & 2 & 3 & 4 \\\\ \\hline
                p(x_i) & 0.5 & 0.3 & 0.2 & 0.1
                \\end{array}
                $$`}
                Sčítáme:
                {`$$\\begin{align*}
                \\text{E}(X) & = 0.5 \\cdot 1 + 0.3 \\cdot 2 + 0.2 \\cdot 3 + 0.1 \\cdot 4 \\\\
                             & = 0.5 + 0.6 + 0.6 + 0.4 \\\\
                             & = 2.1
                \\end{align*}$$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Určete {"$\\text{E}(X)$"}:
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                      x & \\text{ pro } x \\in \\langle 0, 1)  \\\\
                                      (2-x) & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                        0 & \\text{ pro } x \\geq 2 \\end{cases}
                $$`}
                Integrujeme:
                {`$$\\begin{align*}
                \\text{E}(X) & = \\int_{-\\infty}^{\\infty} f(x) \\cdot x \\, \\text{d}x = \\\\
                             & = \\int_{0}^{1} x \\cdot x \\, \\text{d}x + \\int_{1}^{2} (2-x) \\cdot x \\, \\text{d}x = \\\\
                             & = \\int_{0}^{1} x^2 \\, \\text{d}x + \\int_{1}^{2} (2x-x^2) \\, \\text{d}x = \\\\
                                & = \\left[\\frac{x^3}{3}\\right]_0^1 + \\left[x^2 - \\frac{x^3}{3}\\right]_1^2 = \\\\
                                & = \\left[\\frac{1}{3} - 0\\right] + \\left[4 - \\frac{8}{3} - \\left(1 - \\frac{1}{3}\\right)\\right] = \\\\
                                & = \\frac{1}{3} + \\frac{2}{3} = 1
                \\end{align*}$$`}
                </p>
                <p><i>Pozn.: Stačí integrovat přes intervaly, kde je hustota {"$f(x)$"} nenulová.</i></p>
            </div>
        </div>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
                <p>Osa x je 'páka', na každé pozici {"$x_i$"} je posazené závaží o váze {"$p(x_i)$"}.
                Střední hodnota je <strong>těžiště</strong>, tedy bod, kde páku podepřít, aby byla v rovnováze.
                </p>
                <p>Střední hodnota musí vyjít mezi nejmenší a největší hodnotou {"$x_i$"}:
                {`$$
                    \\min\\{x_i\\} \\leq \\text{E}(X) \\leq \\max\\{ x_i\\}
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>Střední hodnota je x-ovou souřadnicí <strong>těžiště</strong> plochy pod grafem funkce {"$f(x)$"}.
                Představte si že plocha mezi grafem {"$f(x)$"} a osou x je vyříznutá z lepenky a posazená
                na páku (osu {"$x$"}). Střední hodnota je bod, kde páku podepřít aby, byla v rovnováze.
                </p>
            </div>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

