import React, { useEffect, useRef, useState } from 'react';

import Plot from 'react-plotly.js';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { MathJaxWrapper } from '~/MathJaxWrapper';
import { MathJax } from 'better-react-mathjax';
import { areaColor, DistributionProps, fColor } from './IntegralArea';


export function IntegralAreaPlotFub({ computeF, computef, xmin, xmax, ymin, ymax, fname }: DistributionProps) {
    const [x, setx] = useState(xmin * 0.4 + xmax * 0.6);

    const n = React.useMemo(() => 100, []);

    const xValues = React.useMemo(
        () => Array.from({ length: n+1 }, (_, i) => xmin + i * (xmax - xmin) / n)
    , [xmin, xmax, n]);

    const fValues = React.useMemo(() => xValues.map(computef), [xValues, computef]);
    const FValues = React.useMemo(() => xValues.map(computeF), [xValues, computeF]);

    const handleSliderChange = React.useCallback((value: number | number[]) => {
        if (typeof value !== 'number') {
            setx(value[0]);
        }

    }, []);


    return (
        <div>
            <Plot
                data={[
                {
                    x: xValues,
                    y: FValues,
                    type: 'scatter',
                    mode: 'lines',
                    name:  'S',
                },
                {
                    x: [x],
                    y: [computeF(x)],
                    type: 'scatter',
                    mode: 'text+markers',
                    text: [computeF(x).toFixed(2)],
                    textposition: 'top right',
                    name: 'S(x)',
                    marker: { color: areaColor, size: 10}
                },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    //title: 'Plocha po x',
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax] },
                    yaxis: { range: [Math.min(...FValues)-0.2, Math.max(...FValues)+0.2] },
                    margin: { r: 150, t: 40, b: 20 },
                }}
            />
            <Plot
                data={[
                    {   // the fill of the area between f and x-axis
                        x: xValues.filter(t => t <= x),
                        y: fValues.slice(0, xValues.findIndex(t => t > x)),
                        fill: 'tozeroy',
                        type: 'scatter',
                        mode: 'none',
                        fillcolor: areaColor,
                        name: 'Plocha',
                    },
                    {   // the graph of f
                        x: xValues,
                        y: fValues,
                        type: 'scatter',
                        mode: 'lines',
                        name: 'f',
                        line: { color: fColor },
                    },
                ]}
                layout={{
                    width: 700,
                    height: 200,
                    title: "",
                    titlefont: { size: 16 },
                    xaxis: { range: [xmin, xmax] },
                    yaxis: { range: [ymin, ymax] },
                    margin: { r: 150, t: 40, b:20 },
                }}
            />
            <div style={{ paddingLeft: 80, paddingRight: 140 }}>
                <Slider
                    range
                    min={xmin}
                    max={xmax}
                    step={(xmax - xmin) / n}
                    defaultValue={x}
                    onChange={handleSliderChange}
                    styles={ { handle: { width: 12, height: 12, borderWidth: 6} } }
                />
            </div>
            <div>
                <MathJaxWrapper>
                <MathJax dynamic={true}>
                {`\\begin{align*}
                    \\int_{-\\infty}^{${x.toFixed(2)}} ${fname} \\, \\mathrm{d}x & = \\text{arctg}(${x.toFixed(2)}) + \\frac{\\pi}{2} = {\\colorbox{#FFB84D}{${(computeF(x)).toFixed(2)}}}
                \\end{align*}`}
                </MathJax>
                </MathJaxWrapper>
            </div>
        </div>
    );
}
