import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const piecewise_g1 = require('../assets/images/piecewise_g1.png');
const piecewise_diagram = require('../assets/images/piecewise_diagram.png');
const piecewise_cover = require('../assets/images/piecewise_cover.png');
const piecewise_discont_x2 = require('../assets/images/piecewise_discont_x2.png');
const piecewise_absx = require('../assets/images/piecewise_absx.png');


export const BasicsPiecewiseMeta = {
    title: "Funkce definovaná po částech (piecewise)",
    shortTitle: "Piecewise",
    path: Path.basics_piecewise,
    element: (sectionNumber:string) => <BasicsPiecewise sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "piecewise-summary",
    "piecewise-evaluation",
    "piecewise-examples",
    "piecewise-notes",
];


export function BasicsPiecewise({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BasicsPiecewiseMeta.title} TOCSpec={TOCSpec}>


        <h2 id="piecewise-summary">Princip</h2>
        <p>
        Funkce definovaná po částech je "slepená" jako mozaika z více předpisů na různých intervalech.
        </p>

        <p>
        Příklad:
        {`$$
            f(x) = \\begin{cases}
                {\\textcolor{red}{x}} & \\text{pro } {\\textcolor{red}{x < 0}} \\\\
                {\\textcolor{green}{\\sin x}} & \\text{pro } {\\textcolor{green}{x \\in \\langle 0, \\frac{\\pi}{2} \\rangle}} \\\\
                {\\textcolor{RedOrange}{1}} & \\text{pro } {\\textcolor{RedOrange}{x \\in ( \\frac{\\pi}{2}, 2 \\rangle}} \\\\
                {\\textcolor{blue}{(x-2)^2 + 1}} & \\text{pro } {\\textcolor{blue}{x > 2}}
            \\end{cases}
        $$`}
        Pořád se jedná o jedinou funkci (!). Nejedná se o čtyři funkce.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={piecewise_g1} style={{width: "75%"}}/>
        </div>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={piecewise_diagram} style={{width: "65%"}}/>
        </div>

        <p>Na konstrukci se můžeme podívat také tak, že známe jednotlivé vzorce a jejich grafy,
        ale zúžíme je na příslušný interval - tj. zakryjeme vše mimo daný interval:
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={piecewise_cover} style={{width: "45%"}}/>
        </div>
        <p>Nezakryté částí se pak spojí v jednu funkci.</p>

        <h2 id="piecewise-evaluation">Dosazení</h2>

        <p>
        Při dosazení je potřeba dát pozor na to, který vzorec aplikovat! Pro libovolné {"$x$"} je relevantní jen jeden vzorec!
        </p>
        <p>
        Dosaďme {"$x = -3$"} do {"$f(x)$"}.
        Protože {"$-3 \\in {\\textcolor{red}{(-\\infty, 0)}}$"}, použijeme první vzorec:
        {`$$
            f(x) = {\\textcolor{red}{x}} \\rightarrow f(-3) = -3
        $$`}
        </p>
        <p>
        Dosaďme {"$x = \\frac{4}{5}$"} do {"$f(x)$"}.
        Protože {"$\\frac{4}{5} \\in {\\textcolor{green}{\\langle 0, \\frac{\\pi}{2} \\rangle}}$"}, použijeme druhý vzorec:
        {`$$
            f(x) = {\\textcolor{green}{\\sin x}} \\rightarrow f\\left(\\frac{4}{5}\\right) = \\sin\\left(\\frac{4}{5}\\right) = 0.717
        $$`}
        </p>
        <p>
        Dosaďme {"$x = 1.9$"} do {"$f(x)$"}.
        Protože {"$1.9 \\in {\\textcolor{RedOrange}{(\\frac{\\pi}{2}, 2 \\rangle}}$"}, použijeme třetí vzorec:
        {`$$
            f(x) = {\\textcolor{RedOrange}{1}} \\rightarrow f(1.9) = 1
        $$`}
        </p>
        <p>
        Dosaďme {"$x = 3$"} do {"$f(x)$"}.
        Protože {"$3 \\in {\\textcolor{blue}{(2, \\infty)}}$"}, použijeme čtvrtý vzorec:
        {`$$
            f(x) = {\\textcolor{blue}{(x-2)^2 + 1}} \\rightarrow f(3) = (3-2)^2 + 1 = 2
        $$`}
        </p>

        <p><span style={{color: 'red'}}>Pozor: Je chyba dosadit do všech vzorců současně</span> - to je <span style={{color: 'red'}}>naprostý nesmysl</span>:
        {`$$
            \\cancel{ f(1.5) = \\begin{cases}
                1.5 &  \\\\
                \\sin(1.5) & \\\\
                1 & \\\\
                (1.5-2)^2 + 1 &
            \\end{cases}}
        $$`}
        Funkce má dávat jednu hodnotu pro každé {"$x$"}. Tady bychom měli na výběr hned ze čtyř hodnot (!).
        </p>

        <h2 id="piecewise-examples">Příklady</h2>
        <p>
        Funkce definované po částech rozhodně nemusí být spojité:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={piecewise_discont_x2} style={{width: "45%"}}/>
        </div>
        <p>Jedna z nejčastějších funkcí definovaných po částech je absolutní hodnota:
        {`$$
            |x| = \\begin{cases}
                -x & \\text{pro } x < 0 \\\\
                x & \\text{pro } x \\geq 0
            \\end{cases}
        $$`}
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={piecewise_absx} style={{width: "45%"}}/>
        </div>

        <h2 id="piecewise-notes">Poznámky</h2>
        <ul>
        <li><p>
        Uzavřenost a otevřenost hranic intervalů popužíváme dle potřeby - podle toho, který ze vzorců chceme, aby v krajním bodě platil (podle toho, jaký proces konstruovanou funkcí chceme modelovat).
        </p></li>
        <li><p>
        Intervaly se nesmí překrývat, jinak máme potenciální konflikt - pro stejné {"$x$"} bychom měli dva různé vzorce - dávající dvě různé hodnoty:
        {`$$
            \\cancel{ f(x) = \\begin{cases}
                x^2 &  \\text{pro } x \\in (-\\infty, 3\\rangle \\\\
                3x  & \\text{pro } x \\in (1, \\infty)
            \\end{cases}}
        $$`}
        Zde např. {"$x = 2$"} spadá do obou intervalů, takže jsou oba vzorce aplikovatelné, ale dávají různé výsledky: {"$2^2 = 4$"} a {"$3 \\cdot 2 = 6$"}. Funkce tedy není dobře definovaná.
        </p></li>
        <li><p>Předchozí pravidlo se někdy "uvolňuje", pokud víme, že funkce je <strong>spojitá v bodě přechodu</strong>. Pak oba vzorce dávají stejnou hodnotu, a povolujeme překryv v jediném bodě:
        {`$$
            f(x) = \\begin{cases}
                x^2 &  \\text{pro } x \\in (-\\infty, 2\\rangle \\\\
                2x  & \\text{pro } x \\in \\langle 2, \\infty)
            \\end{cases}
        $$`}
        Zde {"$x = 2$"} spadá do obou intervalů, takže jsou oba vzorce aplikovatelné. Dávají ale stejnou hodnotu: {"$2^2 = 4$"} a {"$2 \\cdot 2 = 4$"}. Funkce je tedy dobře definovaná.
        </p></li>
        </ul>

        <Footer/>
        </ChapterWrapper>
    );
}

