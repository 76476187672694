import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const tg45 = require('../assets/images/tg45.png');
const sincos_values = require('../assets/images/sincos_values.png');
const sincos_pos = require('../assets/images/sincos_pos.png');



export const SinCosTgValuesMeta = {
    title: "Sin, cos, tg: hodnoty ve význačných úhlech",
    shortTitle: "Sin, cos, tg: hodnoty",
    path: Path.sincostg_values,
    element: (sectionNumber:string) => <SinCosTgValues sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];

export function SinCosTgValues({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SinCosTgValuesMeta.title} TOCSpec={TOCSpec}>

        <h2>Hodnoty tangens</h2>
        <p>Že {"$\\text{tg}\\,45^\\circ = 1$"} je úplný základ. Je to vyjádření toho, že pravoúhlý trojúhelník
        s úhlem 45 stupňů má odvěsny stejně dlouhé - je symetrický (rovnoramenný), a tedy poměr odvěsen
        je 1:1.
        </p>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={tg45} style={{width: "30%"}}/>
        </div>
        <p>
        Pokud má svah úhel 45 stupňů, znamená to, že na 5 metrech vodorovné vzdálenosti se výška zvedne o 5 metrů.
        </p>

        <p>Hodnoty tangens pro ostatní úhly lze operativně získat podělením hodnot sinu a kosinu:
        {`$$
            \\text{tg}\\, x = \\frac{\\sin x}{\\cos x}
        $$`}
        </p>

        <h2>Hodnoty sinu a kosinu</h2>
        <p></p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={sincos_values} style={{width: "95%"}}/>
        </div>

        <h2>Pozice sinu a kosinu</h2>
        <p></p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={sincos_pos} style={{width: "50%"}}/>
        </div>


        <Footer/>
        </ChapterWrapper>
    );
}

