import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';
import { TangentDisplay } from '~/applets';
import { predefinedCases } from '~/applets/TangentDisplay';
import { defaultPlotProps } from '~/applets/PlotBasics';


export const TangentLineMeta = {
    title: "Tečna",
    shortTitle: "Tečna",
    path: Path.tangent_line,
    element: (sectionNumber:string) => <TangentLine sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [];


export function TangentLine({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={TangentLineMeta.title} TOCSpec={TOCSpec}>

        <h2>Odvození</h2>

        <p>
        Rovnice přímky procházející bodem {"$(x_0, y_0)$"} a mající směrnici {"$a$"}:
        {`$$\\begin{align*}
            y - y_0 &= a(x - x_0)
        \\end{align*}$$`}
        Máme funkci {"$f(x)$"} a chceme tečnu, která se dotýká grafu v nějakém jeho bodě.
        </p>

        <ul>
            <li>
            Bod na grafu je {"$(x_0, f(x_0))$"}, tj. pokud chceme x-ovou souřadníci {"$x_0$"}, pak {"$y_0 = f(x_0)$"}.
            </li>
            <li>
            Směrnice tečny v tomto bodě je lokální sklon funkce v tomto bodě, tedy derivace: {"$a = f'(x_0)$"}.
            </li>
        </ul>

        <p>
        Tečna má tedy rovnici:
        {`$$\\begin{align*}
            y - {\\color{blue}{y_0}} &= {\\color{Purple}{a}}(x - x_0) \\\\
            y - {\\color{blue}{f(x_0)}} &= {\\color{Purple}{f'(x_0)}}(x - x_0) \\\\
            & \\\\
            y  &= {\\color{blue}{f(x_0)}} + {\\color{Purple}{f'(x_0)}}(x - x_0)
        \\end{align*}$$`}
        </p>

        <TangentDisplay spec={predefinedCases[0]} initial_x0={1} plotProps={defaultPlotProps()}/>

        <Footer/>
        </ChapterWrapper>
    );
}

