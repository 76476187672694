import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

const graph_sqrt = require('../assets/images/sqrt.png');
const graph_sqrt_mini = require('../assets/images/sqrt_mini.png');

export const BasicsSqrtMeta = {
    title: "Druhá odmocnina",
    shortTitle: "Druhá odmocnina",
    path: Path.basics_sqrt,
    element: (sectionNumber:string) => <BasicsSqrt sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "sqrt-summary",
    "sqrt-sqrt",
    "sqrt-equation",
    "sqrt-absval",
    "sqrt-rules",
];


export function BasicsSqrt({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BasicsSqrtMeta.title} TOCSpec={TOCSpec}>

        {/* <p>
        Ref.: Odmaturuj z matematiky 1, vydání 2007, kapitola 3. <br/>
        Ref.: Zlomky na <ExternalLink href="https://www.matweb.cz/zlomky/">matweb</ExternalLink>.
        </p> */}

        <h2 id="sqrt-summary">Shrnutí</h2>
        <ul>
            <li>
            Odmocňovat můžeme jen nezáporné {"$x$"}: {"$\\ \\ x \\geq 0$"}.
            </li>
            <li>
            Hodnota {"$\\sqrt{x}$"} vychází vždy nezáporně: {"$\\ \\ \\sqrt{x} \\geq 0$"}.
            </li>
            <li>
            Platí {"$\\sqrt{x^2} = |x|$"}.
            </li>
            <li>
            Rovnice {"$x^2 = c$"} má dvě řešení: {"$x = \\sqrt{c}$"} a {"$x = -\\sqrt{c}$"}. <br/>
            <span style={{fontSize: "small"}}>Toto je pro {"$c \\geq 0$"}. Pro {"$c < 0$"} řešení neexistuje v reálných číslech.</span>
            </li>
            <li>
            Graf {"$\\sqrt{x}$"} vypadá takto:
            <img src={graph_sqrt_mini} style={{width: "10%"}}/>
            </li>
        </ul>
        <p>
        <i>Toto byste měli vědět automaticky, kdykoli, bez přemýšlení.</i>
        </p>

        <h2 id="sqrt-sqrt">Druhá odmocnina</h2>
        <p><strong>Druhou odmocninu je možné počítat jen z nezáporných čísel</strong>:
        {`$$\\begin{align*}
            \\sqrt{9} & = 3 & \\text{OK} \\\\
            \\textcolor{red}{\\cancel{\\sqrt{-9}}} & =  & \\text{není definováno} \\\\
        \\end{align*}$$`}
        Jinými slovy: Definiční obor funkce {"$\\sqrt{x}$"} je {"$\\langle 0, \\infty)$"}.
        </p>

        <p><strong>Druhá odmocnina je vždy nezáporné číslo</strong>:
        {`$$\\begin{align*}
            \\sqrt{9} & = 3 \\qquad  \\sqrt{2} = 1.41421356 \\qquad \\sqrt{0} = 0
        \\end{align*}$$`}
        Pozor (!):
        {`$$\\begin{align*}
            \\sqrt{9} & = \\textcolor{red}{\\cancel{-3}} \\qquad  \\sqrt{16} = \\textcolor{red}{\\cancel{-4}}
        \\end{align*}$$`}
        </p>
        <p>
        Graf funkce {"$\\sqrt{x}$"} vypadá takto:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={graph_sqrt} style={{width: "75%"}}/>
        </div>
        <p>
        Vidíte, že argument ({"$x$"}) je jen nezáporný (vpravo od počátku souřadnic),
        a funkční hodnota ({"$\\sqrt{x}$"}, na ose y) je vždy nezáporná (křivka je nad osou {"$x$"}).
        </p>

        <h2 id="sqrt-equation">Řešení rovnice {"$x^2 = c$"}</h2>
        <p>Máme rovnici {"$x^2 = c$"}, kde {"$c$"} je kladné číslo, např.:
        {`$$\\begin{align*}
            x^2 & = 9 \\\\
            x & = ?
        \\end{align*}$$`}
        Slovně: Jaké číslo {"$x$"} má tu vlastnost, že když ho dáme na druhou (neboli vynásobíme samo sebou), dostaneme {"$9$"}?
        Odpověď je {"$3$"}, protože {"$3\\cdot 3 = 9$"}; a také {"$-3$"}, protože {"$(-3)\\cdot (-3) = 9$"}.
        Řešení jsou tedy dvě:
        {`$$\\begin{align*}
            x & = 3 = \\sqrt{9} \\\\
            x & = -3 = -\\sqrt{9}
        \\end{align*}$$`}
        A obecně rovnice {"$x^2 = c$"} má dvě řešení:
        {`$$\\begin{align*}
            x & = \\sqrt{c} \\\\
            x & = -\\sqrt{c}
        \\end{align*}$$`}
        </p>

        <p>
        <strong>Řešení pomocí odmocniny:</strong><br/>

        Odmocnina je funkce prostá, takže ji lze aplikovat na obě strany rovnice, a je to ekvivalentní (korektní) úprava:
        {`$$\\begin{align*}
            L & = P \\\\
            \\sqrt{L} & = \\sqrt{P} \\\\
        \\end{align*}$$`}
        Aplikovat ji ale lze jen tehdy, když na obou stranách rovnice je nezáporný výraz (!).
        {`$$\\begin{align*}
            x^2 & = 9 \\\\
            \\sqrt{x^2} & = \\sqrt{9} \\\\
        \\end{align*}$$`}
        Q1: Vpravo je nezáporný výraz? Ano, {"$9$"} je nezáporné číslo. <br/>
        Q2: Vlevo je nezáporný výraz? Ano, {"$x^2$"} je vždy nezáporné číslo, ať už je {"$x$"} jakékoliv.
        Např. {"$0^2 = 0 \\geq 0, 1^2 = 1, (-1)^2 = 1 \\geq 0 $"} (viz graf funkce {"$x^2$"}).
        </p>

        <p>Pokračujeme v řešení:
        {`$$\\begin{align*}
            \\sqrt{x^2} & = \\sqrt{9} \\\\
            \\vert x \\vert & = 3 \\\\
            x & = 3 \\quad \\text{nebo} \\quad x = -3
        \\end{align*}$$`}
        Rovnice má dvě řešení: {"$x_1 = 3$"} a {"$x_2 = -3$"}.
        </p>

        <p>Není divu, vždyť se jedná o kvadratickou rovnici (!):
        {`$$\\begin{align*}
            x^2 - 9 & = 0 \\\\
            (x - 3)(x + 3) & = 0
        \\end{align*}$$`}
        nebo také:
        {`$$\\begin{align*}
            x_{1,2} & = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a} = \\frac{0 \\pm \\sqrt{0 - 4\\cdot 1\\cdot (-9)}}{2} = \\frac{\\pm 6}{2} = \\pm 3
        \\end{align*}$$`}
        </p>

        <h2 id="sqrt-absval">Absolutní hodnota </h2>
        <p>
        <span style={{color: 'red'}}>Je chybou napsat:</span>
        {`$$\\begin{align*}
            \\sqrt{x^2} & = \\textcolor{red}{\\cancel{x}}
        \\end{align*}$$`}
        <strong>Platí</strong>:
        </p>
        <p style={{backgroundColor: 'yellow'}}>
        {`$$\\begin{align*}
            \\sqrt{x^2} & = \\vert x \\vert
        \\end{align*}$$`}
        </p>
        <p>
        Příklad:
        {`$$\\begin{align*}
            \\sqrt{3^2} & = \\sqrt{9} = 3 = \\vert 3 \\vert \\\\
            \\sqrt{(-3)^2} & = \\sqrt{9} = 3 = \\vert -3 \\vert \\qquad \\text{x bylo -3, ale nám vychází 3, tedy } |x| \\ \\ (!) \\\\
        \\end{align*}$$`}

        Obecně:
        {`$$\\begin{align*}
            \\text{je-li } x \\geq 0: && \\sqrt{x^2} & = \\sqrt{x\\cdot x} = x = \\vert x \\vert \\\\
            \\text{je-li } x < 0:     && \\sqrt{x^2} & = \\sqrt{(-x)\\cdot(-x)} = -x = \\vert x \\vert
        \\end{align*}$$`}
        </p>
        Spodní rovnici čteme takto: Dejme tomu že {"$\\textcolor{green}{x} = \\textcolor{green}{-5}$"}. Pak:
        {`$$\\begin{align*}
            \\sqrt{(\\textcolor{green}{-5})^2} & = \\sqrt{(-(\\textcolor{green}{-5}))\\cdot(-(\\textcolor{green}{-5}))} = - (\\textcolor{green}{-5}) = 5 = \\vert \\textcolor{green}{-5} \\vert
        \\end{align*}$$`}
        <span style={{color: 'red'}}>Kdybychom tvrdili, že {"$\\sqrt{x^2} = x$"}, pak bychom tvrdili, že {"$\\sqrt{(-5)^2} = -5$"}, což je špatně!</span>

        <p>A jak je to s {"$\\left( \\sqrt{x}\\right)^2 \\ $"}? Platí:
        {`$$\\begin{align*}
            \\left( \\sqrt{x}\\right)^2 & = x
        \\end{align*}$$`}
        Tady není důvod psát {"$\\vert x \\vert$"}, protože argument {"$x$"} je apriori nezáporný -
        pro záporné {"$x$"} bychom nemohli odmocninu v prvé řadě vůbec počítat.
        </p>

        <h2 id="sqrt-rules">Pravidla počítání</h2>
        <p>
        <span style={{color: 'red'}}>Odmocninu nelze "roztrhnout":</span>
        {`$$\\begin{align*}
            \\sqrt{a + b} & = \\textcolor{red}{\\cancel{\\sqrt{a} + \\sqrt{b}}} \\\\
        \\end{align*}$$`}
        <i>Kdyby toto mělo platit, pak bychom se museli tvářit, že {"$\\sqrt{2} = \\sqrt{1 + 1} = 2$"}, což není pravda.</i>
        </p>
        <p><strong>Odmocnina součinu a podílu</strong>:
        {`$$\\begin{align*}
            \\sqrt{a\\cdot b} &= \\sqrt{a}\\cdot \\sqrt{b} && L: \\sqrt{4\\cdot 9} = \\sqrt{36} = 6 \\\\
                              &                            && P: \\sqrt{4}\\cdot \\sqrt{9} = 2\\cdot 3 = 6 \\\\
                              & && \\\\
            \\sqrt{\\frac{a}{b}} &= \\frac{\\sqrt{a}}{\\sqrt{b}} && L: \\sqrt{\\frac{36}{9}} = \\sqrt{4} = 2 \\\\
                                &                                && P: \\frac{\\sqrt{36}}{\\sqrt{9}} = \\frac{6}{3} = 2
        \\end{align*}$$`}
        </p>
        <p><strong>Vytýkání z odmocniny</strong>:
        {`$$\\begin{align*}
            \\sqrt{a^2b} &= |a| \\sqrt{b}  && \\sqrt{12} = \\sqrt{4 \\cdot 3} = \\sqrt{2^2\\cdot 3} = \\sqrt{2^2} \\cdot \\sqrt{2} = 2\\sqrt{2} \\\\
                         &                 && \\sqrt{\\frac{1}{12}} = \\sqrt{\\left(\\frac{1}{2}\\right)^2\\cdot \\frac{1}{3}} = \\frac{1}{2}\\cdot \\sqrt{\\frac{1}{3}}
        \\end{align*}$$`}
        </p>
        <p><strong>Vnoření do odmocniny</strong>:
        {`$$\\begin{align*}
            |a| \\sqrt{b} = \\sqrt{a^2b} && 2 \\sqrt{3} = \\sqrt{2^2\\cdot 3} = \\sqrt{12} \\\\
        \\end{align*}$$`}
        Příklad:
        {`$$\\begin{align*}
        \\sqrt{2} \\cdot \\sqrt{1 - \\frac{x^2}{2}} & = \\sqrt{\\left(\\sqrt{2}\\right)^2 \\cdot \\left(1 - \\frac{x^2}{2}\\right)}  = \\sqrt{2\\left(1 - \\frac{x^2}{2}\\right)} = \\sqrt{2 - x^2}
        \\end{align*}$$`}
        </p>

        <p>Odmocnina psaná jako na jednu polovinu:
        {`$$\\begin{align*}
            \\sqrt{x} & = x^{\\frac{1}{2}}
        \\end{align*}$$`}
        {/* Motivace je jasná: Má-li platit {"$\\sqrt{x} \\cdot \\sqrt{x} = x$"} */}
        </p>

        <p>Odmocnina mocniny (většinou pouze pro nezáporné {"$a$"}):
        {`$$\\begin{align*}
            \\sqrt{a^n} &= a^{\\frac{n}{2}} = \\left(\\sqrt{a}\\right)^n \\\\
        \\end{align*}$$`}
        Příklad:
        {`$$\\begin{align*}
            \\sqrt{a^4} & = a^{\\frac{4}{2}} = a^2 \\\\
            \\sqrt{a^4} & = \\left(\\sqrt{a}\\right)^4 = \\left(\\sqrt{a}\\right)^2 \\cdot \\left(\\sqrt{a}\\right)^2 = a \\cdot a = a^2
        \\end{align*}$$`}
        </p>
        {/* \\sqrt{\\frac{1}{2}} = \\frac{1}{3}\\left(3 \\sqrt{\\frac{1}{2}}\\right) = \\frac{1}{2}\\sqrt{9 \\frac{1}{2}} = \\frac{1}{2}\\sqrt{4.5} */}

        <Footer/>
        </ChapterWrapper>
    );
}

