import React from 'react';

import {MathJaxContext, MathJax} from "better-react-mathjax";


const config = {
    loader: { load: ["[tex]/html", '[tex]/cancel', '[tex]/color'] },
    packages: {'[+]': ['cancel']},
    tex: {
        packages: { "[+]": ["html", "cancel", "color"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
        ],
        displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
        ],
        processEscapes: false,
    }
};

export function MathJaxWrapper({children}: {children: JSX.Element}) {

    return (
        <MathJaxContext config={config} version={3}>
            {children}
        </MathJaxContext>
    );
}

