import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const BasicsEquationsMeta = {
    title: "Rovnice",
    shortTitle: "Rovnice",
    path: Path.basics_equations,
    element: (sectionNumber:string) => <BasicsEquations sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    'eq-intro',
    'eq-transf',
    'eq-transf-add',
    'eq-transf-multiply',
    'eq-transf-log',
    'eq-transf-delog',
    'eq-transf-finjective',
];


export function BasicsEquations({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BasicsEquationsMeta.title} TOCSpec={TOCSpec}>

        <ExternalLink href={"https://www.matweb.cz/rovnice/"}>matweb: co je to rovnice</ExternalLink> | <ExternalLink href={"https://www.matweb.cz/upravy-rovnic/"}>matweb: úpravy rovnic</ExternalLink> <br/>
        <ExternalLink href={"http://www.realisticky.cz/ucebnice/01%20Matematika%20S%C5%A0/02%20Funkce%20a%20rovnice/02%20Line%C3%A1rn%C3%AD%20rovnice%20a%20nerovnice/01%20Rovnice,%20ekvivalentn%C3%AD%20%C3%BApravy.pdf"}>Realisticky: ekvivalentní a důsledkové úpravy</ExternalLink>

        {/* TODO vysvetli dusledkove upravy ; nasobeni a deleni nulou ; vystup 0 = 0 ; vystup 0 = 1 */}

        <h2 id='eq-intro'>Rovnice</h2>
        <p>
        {`$$
            L = P
        $$`}
        Uprostřed je znak rovnosti '='.
        Vše, co je nalevo od něj, je <strong>levá strana</strong> (zkratka L).
        Vše, co je napravo od něj, je <strong>pravá strana</strong> (zkratka P).
        Příklad:
        {`$$
            2x + 3 = \\sin x + \\frac{1}{x}
        $$`}
        L: {"$2x + 3\\qquad$"} P: {"$\\sin x + \\frac{1}{x}$"}<br/>
        </p>

        <p style={{backgroundColor: 'lightyellow'}}>
        Jakoukoli úpravu rovnice provádíme tak, že stejnou operaci
        provedeme <strong>s celou levou stranou</strong> i <strong>s celou pravou stranou</strong>:
        {`$$
            \\text{operace}(L) = \\text{operace}(P) \\\\
            \\ \\
        $$`}
        </p>
        <p>Toto je základní striktní pravidlo, které bohužel často vidíme prorušené:
        {`$$\\begin{align*}
            \\ln x &= x + 3 \\qquad / e^L = e^P \\\\
            x &= \\cancel{e^x + 3} \\\\
            x & = e^{x+3} \\qquad \\text{[OK]}
        \\end{align*}$$`}
        </p>

        <h2 id='eq-transf'>Úpravy</h2>
        <p>
        <strong>Ekvivalentní</strong>: Úpravou se množina všech řešení nezmění. Žádné řešení nepřibude, žádné nevypadne.<br/><br/>
        <strong>Důsledkové</strong>: Úpravou se množina všech řešení změní. Buď nějaké řešení přibude, nebo nějaké vypadne.
        </p>

        {/* <p>Chceme dělat ekvivalentní úpravy, pokud to jen jde.</p> */}

        <p>Příklad důsledkových úprav - <strong>'násobení nulou'</strong>:
        {`$$\\begin{align*}
            x &= 4  \\quad / \\cdot x   && \\qquad \\text{má jedno řešení: } x = 4 \\\\
            x^2 &= 4x            && \\qquad \\text{má dvě řešení: } x_1 = 4, x_2 = 0
        \\end{align*}$$`}
        Když násobíme strany rovnice výrazem (činitelem), který může mít hodnotu nula, přidáváme tím řešení. Je potřeba dopsat podmínku {'$\\text{činitel} \\neq 0$'}.
        </p>

        <p>Příklad:
        {`$$\\begin{align*}
            x &= 4  \\quad / \\cdot \\cos x  \\quad {\\color{red}{\\cos x \\neq 0}}  && \\qquad \\text{má jedno řešení } x_* = 4\\\\
            x\\cos x &= 4\\cos x            && \\qquad \\text{má monoho řešení } x_* = 4, x_k = \\frac{\\pi}{2} + k\\pi, k \\in \\mathbb{Z}
        \\end{align*}$$`}
        Zapsaná podmínka {"${\\color{red}{\\cos x \\neq 0}}$"} nám připomíná, že ta řešení, kde {"$\\cos x = 0$"}, máme na konci vyloučit - nejsou to řešení původní rovnice.
        </p>

        <p>Příklad důsledkových úprav - <strong>'dělení nulou'</strong>:
        {`$$\\begin{align*}
            x^2 - x &= 0  \\quad /  :x   && \\qquad \\text{má dvě řešení: } x_1 = 0, x_2 = 1 \\\\
             x - 1 & = 0                 && \\qquad \\text{má jedno řešení: } x = 1
        \\end{align*}$$`}
        Když strany rovnice podělíme výrazem (dělitelem), který může mít hodnotu nula, připravujeme se tím o řešení. Řešení, o která se připravujeme,
        získáme řešením rovnice {"$\\text{dělitel} = 0$"}.
        </p>

        <p>Příklad:
        {`$$\\begin{align*}
            x(x-1) & = 2(x-1) \\quad /  :(x-1)   \\quad {\\color{red}{x - 1 =^? 0}}   && \\qquad \\text{má dvě řešení: } x_1 = 2, x_2 = 1 \\\\
            x &= 2                                                            && \\qquad \\text{má jedno řešení: } x = 2
        \\end{align*}$$`}
        Poznámka {"$\\color{red}{x - 1 =^? 0}$"} nám říká, abychom prověřili případy, kdy {"$x - 1 = 0$"}, budou to další řešení rovnice. Zde máme jedno: {"$x = 1$"}.
        </p>

        <h2 id='eq-transf-add'>Úprava: Přičtení výrazu</h2>
        <p>
        {`$$
            L + a = P + a
        $$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & x^2 + 2x &= 2  \\\\
            L + 1 &= P + 1  & (x^2 + 2x) + 1 &= (2) + 1  \\\\
                    &          & x^2 + 2x + 1 &= 3
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & x^2 + 2x &= 2  \\\\
            L - 2 & = P - 2  & (x^2 + 2x) - 2 &= (2) -2  \\\\
                               & & x^2 + 2x - 2 &= 0
        \\end{align*}$$`}
                {`$$\\begin{align*}
            L & = P          & x^2 &= x + 1 \\\\
            L - (x+1) & = P - (x+1)  & (x^2) - (x+1) &= (x + 1) - (x + 1)  \\\\
                               & & x^2 - x - 1 &= 0
        \\end{align*}$$`}
        Sem patří i odečtení výrazu: Odečtení výrazu {"$a$"} je to stejné jako přičtení výrazu {"$-a$"}.
        </p>

        <h2 id='eq-transf-multiply'>Úprava: Vynásobení výrazem</h2>
        <p>
        {`$$
           a \\cdot L = a \\cdot P
        $$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & 2x + 1&= 4  \\\\
            3 \\cdot L &= 3 \\cdot P  & 3 \\cdot (2x + 1) &= 3 \\cdot 4  \\\\
                    &          & 6x + 3 &= 12
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & 2e^x\\sin x &= e^x\\cos x  \\\\
            \\frac{1}{e^x} \\cdot L & = \\frac{1}{e^x} \\cdot P  & \\frac{1}{e^x} \\cdot \\left(2e^x\\sin x \\right)&= \\frac{1}{e^x} \\cdot \\left( e^x\\cos x  \\right) \\\\
                                 & & 2\\sin x  &= \\cos x
        \\end{align*}$$`}
        Sem patří i dělení výrazem: Dělení výrazem {"$a$"} je to stejné jako vynásobení výrazem {"$\\frac{1}{a}$"}.
        </p>

        <p>
        Pozor na násobení nebo dělení nulou - viz důsledkové úpravy!
        </p>

        <h2 id='eq-transf-log'>Úprava: Logaritmování</h2>
        <p>
        {`$$\\begin{align*}
           \\ln(L) &= \\ln(P) \\quad && \\small{\\text{přirozený logaritmus - základ e}} \\\\
           \\log(L) &= \\log(P) \\quad && \\small{\\text{dekadický logaritmus - základ 10}} \\\\
          \\log_a(L) &= \\log_a(P) \\quad && \\small{\\text{obecně logaritmus - základ a}}
        \\end{align*}$$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & 2x + 1&= e^{2x}  \\\\
            \\ln(L) &= \\ln(P)  & \\ln(2x + 1) &= \\ln(e^{2x})  \\\\
                    &          & \\ln(2x + 1) &= 2x
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & 10^x &= 2^{x+4}  \\\\
            \\log(L) &= \\log(P)  & \\log(10^x) &= \\log(2^{x+4})  \\\\
                    &          & x  &= (x+4) \\log(2)
        \\end{align*}$$`}
        </p>

        <h2 id='eq-transf-delog'>Úprava: Odlogaritmování ('e na')</h2>
        <p>
        {`$$\\begin{align*}
           e^{L} &= e^{P} \\\\
           10^{L} &= 10^{P} \\\\
           a^{L} &= a^{P}
        \\end{align*}$$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & 2x - 3 &= \\ln (x + 1)  \\\\
            e^{L} &= e^{P}  & e^{2x - 3} &= e^{\\ln (x + 1)}  \\\\
                    &          & e^{2x - 3} &= x + 1
        \\end{align*}$$`}
        {`$$\\begin{align*}
            L & = P          & \\log x &= 2  \\\\
            10^{L} &= 10^{P}  & 10^{\\log x} &= 10^2  \\\\
                    &          & x &= 100
        \\end{align*}$$`}
        </p>

        <h2 id='eq-transf-finjective'>Úprava: Aplikace prosté funkce</h2>
        <p>
        {`$$
           f(L) = f(P)
        $$`}
        Příklady:
        {`$$\\begin{align*}
            L & = P        & x + 1 &= 2  \\\\
            L^3 &= P^3  & (x + 1)^3 &= 2^3
        \\end{align*}$$`}
        Funkce {"$f(x) = x^3$"} je prostá. POZOR: Funkce {"$f(x) = x^2$"} není prostá - viz níže.
        {`$$\\begin{align*}
            L & = P        & \\arcsin(x) &= \\frac{\\pi}{2}  \\\\
            \\sin(L) &= \\sin(P)  & \\sin\\Big(\\arcsin(x)\\Big) &= \\sin\\Big(\\frac{\\pi}{2}\\Big)  \\\\
                    &          & x &= 1
        \\end{align*}$$`}
        Pozn.: Funkce {"$\\sin$"} není prostá. Je ale prostá na oboru hodnot L, tj. {"$\\langle -\\frac{\\pi}{2}; \\frac{\\pi}{2}\\rangle$"})
        i P, proto ji můžeme použít.
        </p>
        <p><strong>POZOR</strong>: Pokud funkce {"$f$"} <strong>není prostá</strong>, úprava typu
        {`$$
           f(L) = f(P)
        $$`}
        je <strong>důsledková</strong>: obecně získáme nějaká nová řešení, která nejsou řešením původní rovnice (!).
        {`$$\\begin{align*}
            L & = P        & x &= 2 \\\\
            L^2 &= P^2  & x^2 &= 2^2
        \\end{align*}$$`}
        Rovnice {"$x^2 = 4$"} má dvě řešení: {"$x = 2$"} a {"$x = -2$"}.
        Původní rovnice {"$x = 2$"} má ale jen jedno řešení: {"$x = 2$"}.
        Úprava nás tedy vede na scestí - není to ekvivalentní úprava.
        </p>
        <Footer/>
        </ChapterWrapper>
    );
}

