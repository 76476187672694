import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const Integration1Meta = {
    title: "Integrál 1",
    shortTitle: "Integrál 1",
    path: Path.integration1,
    element: (sectionNumber:string) => <Integration1 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [];


export function Integration1({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={Integration1Meta.title} TOCSpec={TOCSpec}>

        {/* <ExternalLink href={"https://www.matweb.cz/rovnice/"}>matweb: co je to rovnice</ExternalLink> <br/> */}

        <h2>Základní vzorce</h2>
        <p>
        {`$$\\begin{align*}
            \\int (f \\pm g) &= \\int f \\pm \\int g \\\\
            \\int c f  &= c \\int f \\\\
            \\int -f & = -\\int f \\\\
        \\end{align*}$$`}
        </p>
        <p>
        Vzorce zde píšeme bez {"$ + C$"} pro vizuální jednoduchost.

        {`$$\\begin{align*}

            f & \\rightarrow F                                  & f(g)g' & \\rightarrow F(g)                    & Příklad \\\\ \\hline
            x^{n+1} & \\rightarrow \\frac{x^{n+1}}{n+1}         & g^{n+1}g' & \\rightarrow \\frac{g^{n+1}}{n+1} & (\\sin x)^3 \\cos x & \\rightarrow \\frac{\\sin^4 x}{4} \\\\
            \\frac{1}{x} & \\rightarrow \\ln |x|                & \\frac{1}{g}g' & \\rightarrow \\ln |g|        & \\frac{1}{\\ln x} \\frac{1}{x} & \\rightarrow \\ln(\\ln x) \\\\
            e^{x} & \\rightarrow e^{x}                          & e^{g}g'  & \\rightarrow e^{g}                 & e^{\\sin x} \\cos x & \\rightarrow e^{\\sin x} \\\\
            a^{x} & \\rightarrow \\frac{a^{x}}{\\ln a}          & a^{g}g' & \\rightarrow \\frac{a^{g}}{\\ln a}  & & \\\\
            \\sin x & \\rightarrow -\\cos x                     & (\\sin g)g' & \\rightarrow -\\cos g           & (\\sin e^x)e^x & \\rightarrow -\\cos e^x \\\\
            \\cos x & \\rightarrow \\sin x                      & (\\cos g)g' & \\rightarrow \\sin g            & \\big( \\cos \\sqrt{x} \\big) {\\scriptsize \\frac{1}{2\\sqrt{x}}} & \\rightarrow \\sin \\sqrt{x} \\\\
            \\frac{1}{\\cos^2 x} & \\rightarrow \\text{tg}\\,x    & \\frac{g'}{\\cos^2 g} & \\rightarrow \\text{tg}\\,g  & \\frac{e^x}{\\cos^2 e^x} & \\rightarrow \\text{tg}\\,e^x \\\\
            \\frac{1}{\\sin^2 x} & \\rightarrow -\\text{cotg}\\,x & \\frac{g'}{\\sin^2 g} & \\rightarrow -\\text{cotg}\\,g & \\frac{\\cos x}{\\sin^2 (\\sin x)} & \\rightarrow -\\text{cotg}(\\sin x) \\\\
            \\frac{1}{\\sqrt{1-x^2}} & \\rightarrow \\arcsin x  & \\frac{g'}{\\sqrt{1-g^2}} & \\rightarrow \\arcsin g  & \\frac{3x^2}{\\sqrt{1-x^6}} & \\rightarrow \\arcsin x^3 \\\\
            \\frac{1}{1+x^2} & \\rightarrow \\text{arctg}\\,x     & \\frac{g'}{1+g^2} & \\rightarrow \\text{arctg}\\,g  & \\frac{2x}{1+x^4} & \\rightarrow \\text{arctg}\\,x^2
        \\end{align*}$$`}
        </p>
        <p>
        Poznámka: U vztahu pro integrál {"$\\frac{1}{\\sqrt{1-x^2}}$"} máme dvě varianty:
        {`$$
            \\frac{1}{\\sqrt{1-x^2}}  \\rightarrow \\arcsin x + C_1 = - \\arccos x + C_2
        $$`}
        Platí totiž
        {`$$
        \\arcsin x = - \\arccos x + \\frac{\\pi}{2}
        $$`}
        (ověřte si grafem), takže funkce {"$\\arcsin x$"} a {"$-\\arccos x$"} se liší jen o konstantu - proto jsou obě primitivní funkce.
        Je-li {"$C_1 = 0$"}, pak {"$C_2 = \\frac{\\pi}{2}$"}; obecně {"$C_2 = C_1 + \\frac{\\pi}{2}$"}.
        </p>

        <p>
        Další vztahy - porovnání a rozšířená varianta s parametrem {"$a$"}:
        {`$$\\begin{align*}
            \\frac{1}{1+x^2} & \\rightarrow \\text{arctg}\\,x                                          & \\frac{1}{a^2+x^2} & \\rightarrow \\frac{1}{a} \\text{arctg}\\,\\frac{x}{a} \\\\
            \\frac{1}{1 - x^2} & \\rightarrow \\frac{1}{2} \\ln \\left| \\frac{x+1}{x-1} \\right|    & \\frac{1}{a^2 - x^2} & \\rightarrow \\frac{1}{2a} \\ln \\left| \\frac{a+x}{a-x} \\right| \\\\
            \\\\
            \\frac{1}{\\sqrt{1-x^2}} & \\rightarrow \\arcsin x             & \\frac{1}{\\sqrt{a^2-x^2}} & \\rightarrow \\arcsin \\frac{x}{a} \\\\
            \\frac{1}{\\sqrt{1 + x^2}} & \\rightarrow  \\ln \\left( x + \\sqrt{1+x^2} \\right) & \\frac{1}{\\sqrt{a^2 + x^2}} & \\rightarrow \\ln \\left( x + \\sqrt{a^2 + x^2} \\right) \\\\
            \\frac{1}{\\sqrt{x^2 - 1}} & \\rightarrow  \\ln \\left| x + \\sqrt{x^2 - 1} \\right| & \\frac{1}{\\sqrt{x^2 - a^2}} & \\rightarrow  \\ln \\left| x + \\sqrt{x^2 - a^2} \\right| \\\\
        \\end{align*}$$`}
        Vztahy v pravé části si nemusíme pamatovat - pokud je zapomeneme, vyřešíme je substitucí {"$\\frac{x}{a} = t$"}. Tento proces ale zabírá čas.
        </p>

        <p>Poznámka: Výraz {"$ \\ln \\left( x + \\sqrt{1+x^2} \\right) $"} je vzorcem funkce {"$\\text{arcsinh}\\,x$"} ('arkus sinus hyperbolický'):
        {`$$
            \\text{arcsinh}\\,x = \\ln \\left( x + \\sqrt{1+x^2} \\right)
        $$`}
        Všimněte si, že definičním oborem je celé {"$\\mathbb{R}$"}, protože vždy platí {"$\\sqrt{1+x^2} > |x|$"}. To je také důvod, proč zde nepotřebujeme psát absolutní hodnotu.<br/>
        Protože platí obdoba goniometrické jedničky: {"$1 + \\sinh^2 x = \\cosh^2 x$"}, a vztahy pro derivace
        {"$ \\sinh' x =  \\cosh x$"} a {"$ \\cosh' x =  \\sinh x$"},
        v integrálu {"$\\frac{1}{\\sqrt{1+x^2}}$"} používáme substituci {"$x = \\sinh t$"}.
        </p>




        <Footer/>
        </ChapterWrapper>
    );
}

// \\frac{1}{1 - x^2} & \\rightarrow \\frac{1}{2} \\ln \\left| \\frac{x+1}{x-1} \\right| & \\frac{g'}{1 - g^2} & \\rightarrow \\frac{1}{2} \\ln \\left| \\frac{g+1}{g-1} \\right| & \\frac{e^x}{1-e^{2x}} & \\rightarrow \\frac{1}{2} \\ln \\left| \\frac{1+e^x}{1-e^x} \\right| \\\\

// {`$$\\begin{align*}
//     \\small
//         f & \\rightarrow F                                  & f(g)g' & \\rightarrow F(g) \\\\
//         x^{n+1} & \\rightarrow \\frac{x^{n+1}}{n+1}         & g^{n+1}g' & \\rightarrow \\frac{g^{n+1}}{n+1} \\\\
//         \\frac{1}{x} & \\rightarrow \\ln |x|                & \\frac{1}{g}g' & \\rightarrow \\ln |g| \\\\
//         e^{x} & \\rightarrow e^{x}                          & e^{g}g'  & \\rightarrow e^{g} \\\\
//         a^{x} & \\rightarrow \\frac{a^{x}}{\\ln a}          & a^{g}g' & \\rightarrow \\frac{a^{g}}{\\ln a} \\\\
//         \\sin x & \\rightarrow -\\cos x                     & (\\sin g)g' & \\rightarrow -\\cos g \\\\
//         \\cos x & \\rightarrow \\sin x                      & (\\cos g)g' & \\rightarrow \\sin g \\\\
//         \\frac{1}{\\cos^2 x} & \\rightarrow \\text{tg} x    & \\frac{g'}{\\cos^2 g} & \\rightarrow \\text{tg} g \\\\
//         \\frac{1}{\\sin^2 x} & \\rightarrow -\\text{cotg} x & \\frac{g'}{\\sin^2 g} & \\rightarrow -\\text{cotg} g \\\\
//         \\frac{1}{\\sqrt{1-x^2}} & \\rightarrow \\arcsin x  & \\frac{g'}{\\sqrt{1-g^2}} & \\rightarrow \\arcsin g \\\\
//         \\frac{1}{1+x^2} & \\rightarrow \\text{arctg} x     & \\frac{g'}{1+g^2} & \\rightarrow \\text{arctg} g \\\\
//         \\frac{1}{1 - x^2} & \\rightarrow \\frac{1}{2} \\ln \\left| \\frac{x+1}{x-1} \\right| & \\frac{g'}{1 - g^2} & \\rightarrow \\frac{1}{2} \\ln \\left| \\frac{g+1}{g-1} \\right| \\\\
//     \\end{align*}$$`}

