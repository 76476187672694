import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';
import { ExternalLink } from '~/ExternalLink';

const line_points = require('../assets/images/line_points.png');
const line_deltas = require('../assets/images/line_deltas.png');
const line_four = require('../assets/images/line_four.png');
const line_tga = require('../assets/images/line_tga.png');
const line_p45 = require('../assets/images/line_p45.png');
const line_m45 = require('../assets/images/line_m45.png');
const line_spread1 = require('../assets/images/line_spread1.png');
const line_spread2 = require('../assets/images/line_spread2.png');
const line_b = require('../assets/images/line_b.png');

export const FunctionLineMeta = {
    title: "Přímka",
    shortTitle: "Přímka",
    path: Path.function_line,
    element: (sectionNumber:string) => <FunctionLine sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec = [
    "line-summary",
    "line-through-origin",
    "line-slope",
    "line-tangens",
    "line-xeqy",
    "line-xeqminusy",
    "line-orientation",
    "line-known-angles",
    "line-generic",
    "line-mutual-position",
    "line-through-point",
];


export function FunctionLine({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionLineMeta.title} TOCSpec={TOCSpec}>

        {/* <i>... work in progress ...</i> */}

        <h2 id="line-summary">Shrnutí - co bezpečně vědět:</h2>
        <ul>
            <li><p>
            Obecná přímka má rovnici {"$y = ax + b$"}. <br/>
            Přímka procházející počátkem má rovnici {"$y = ax$"}.
            </p></li>
            <li><p>
            Číslo {"$a$"} se jmenuje <strong>směrnice</strong>; platí {"$a = \\frac{\\Delta y}{\\Delta x}$"}; platí {"$a = \\text{tg} \\alpha$"}, kde {"$\\alpha$"} je úhel, který přímka svírá s osou x.
            </p></li>
            <li><p>
            Je-li {"$a > 0$"}, přímka stoupá; je-li {"$a < 0$"}, přímka klesá. Pro {"$a = 0$"} je vodorovná.
            </p></li>
            <li><p>
            Směrnice {"$a=1$"}: přímka svírá s osou x úhel 45° (přírůstek {"$\\Delta x$"} a přírůstek {"$\\Delta y$"} jsou v poměru 1:1).
            </p></li>
            <li><p>
            Směrnice {"$a=-1$"}: přímka svírá s osou x úhel -45° (přírůstek {"$\\Delta x$"} a přírůstek {"$\\Delta y$"} jsou 1:-1).
            </p></li>
            <li><p>
            Směrnice {"$a > 1$"} znamená, že přímka je strmější než přímka s {"$a=1$"}. <br/>
            Směrnice {"$0 < a < 1$"} znamená, že přímka je plošší (povlovnější) než přímka s {"$a=1$"}.
            </p></li>
            <li><p>
            Přímka procházející bodem {"$[x_0, y_0]$"} má rovnici {"$y - y_0 = a(x - x_0)$"}.
            </p></li>
            <li><p>
            Parametr {"$b$"} určuje, kde přímka protne osu y. Pokud je {"$b > 0$"}, protne osu y nad počátkem, pokud je {"$b < 0$"}, protne osu y pod počátkem.
            Jmenuje se <strong>absolutní člen</strong>, historicky také <strong>úsek</strong>.
            </p></li>
            <li><p>
            Vektor určující směr přímky je {"$(1, a)$"}. Vektor s opačnou orientací je {"$(-1, -a)$"}.
            </p></li>
            <li><p>
            Přímka je polynom stupně 1.
            </p></li>
        </ul>

        Ref: <ExternalLink href='https://www.karlin.mff.cuni.cz/~portal/analyticka_geometrie/rovina.php?kapitola=obecnaRovnice'>Portál středoškolské matematiky CUNI</ExternalLink>.

        <h2 id="line-through-origin">Přímka procházející počátkem</h2>
        <p>Rovnice:
        {`$$
            y = {\\textcolor{red}{a}}x
        $$`}
        Je to vlastně přímá úměra. Koeficient {"${\\textcolor{red}{a}}$"} je <strong>směrnice</strong> přímky.
        </p>
        <p>Příklad:
        {`$$
            y = {\\textcolor{red}{2}}x
        $$`}

        {/*
        {`$$\\begin{array}{|l||c|c|c|c|c|c|c|c|c|} \\hline
            x                    & \\cdots & -3 & -2 & -1 & 0 & 1 & 2 & 3 & \\cdots \\\\ \\hline
            y = 2x               & \\cdots & -6 & -4 & -2 & 0 & 2 & 4 & 6 & \\cdots \\\\ \\hline
            y = \\frac{1}{2}x    & \\cdots & -\\frac{3}{2} & -1 & -\\frac{1}{2} & 0 & \\frac{1}{2} & 1 & \\frac{3}{2} & \\cdots \\\\ \\hline
            y = -3x              & \\cdots & 9 & 6 & 3 & 0 & -3 & -6 & -9 & \\cdots \\\\ \\hline
            y = -\\frac{1}{3}x   & \\cdots & 1 & \\frac{2}{3} & \\frac{1}{3} & 0 & -\\frac{1}{3} & -\\frac{2}{3} & -1 & \\cdots \\\\ \\hline
        \\end{array}$$`} */}

        Rovnici {"$y = {\\textcolor{red}{2}}x$"} splňují takové body {"$[x, y]$"}, kde {"$y$"} je dvakrát větší než {"$x$"}.
        Například bod {"$[1, 2]$"} tuto rovnici splňuje, ale bod {"$[1, 3]$"} ji nesplňuje, protože {"$3 \\neq 2 \\cdot 1$"}.
        Pokud na milimetrovém papíře / v rovině vyznačíme všechny body, které tuto rovnici splňují, zjistíme, že jsme nakreslili přímku:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_points} style={{width: "40%"}}/>
        </div>

        <h2 id="line-slope">Směrnice</h2>
        <p>Směrnice přímky je
        {`$$
            a = \\frac{\\textcolor{green}{\\Delta y}}{\\textcolor{RoyalBlue}{\\Delta x}} = \\frac{\\textcolor{green}{+2}}{\\textcolor{RoyalBlue}{+1}} = 2
        $$`}
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_deltas} style={{width: "40%"}}/>
        </div>
        <p>
        Níže je ilustrovaná situace pro přímku s kladnou a zápornou směrnicí, která je větší než 1 (v absolutní hodnotě) a menší než 1 (v absolutní hodnotě):
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_four} style={{width: "90%"}}/>
        </div>

        <h2 id="line-tangens">Směrnice je {"$\\text{tg} \\alpha$"}</h2>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_tga} style={{width: "30%"}}/>
        </div>
        <p>Z diagramu je vidět, že {"$\\textcolor{green}{\\Delta y} = a$"} a {"$\\textcolor{RoyalBlue}{\\Delta x} = 1$"} tvoří odvěsny pravoúhlého trojúhelníku.
        Jejich poměr je tedy tangens úhlu {"$\\alpha$"}:
        {`$$
            \\text{tg} \\alpha = \\frac{\\textcolor{green}{\\Delta y}}{\\textcolor{RoyalBlue}{\\Delta x}} = \\frac{\\textcolor{green}{a}}{\\textcolor{RoyalBlue}{1}} = a
        $$`}
        </p>


        <h2 id="line-xeqy">{"$y = x \\ \\ $"}: &nbsp; směrnice {"$a = 1$"}</h2>
        <p>Přímka se směrnicí {"$a = 1$"} má rovnici
        {`$$
            y = x
        $$`}
        To znamená, že je to osa prvního a třetího kvadrantu. Roste pod úhlem <span style={{color: "magenta"}}>45°</span>.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_p45} style={{width: "50%"}}/>
        </div>
        <p>Přírůstky na ose x a y jsou 1:1. Tato funkce se také nazývá <strong>identita</strong>. To je proto, že funkční hodnota (obraz) {"$x$"} je totožná s argumentem {"$x$"}.
        Funkce vlastně nic nedělám jen vrátí vstupní argument jako výsledek.
        </p>
        <p>Všimněte si, že platí {"$\\text{tg}(45°) = 1$"}.</p>

        <h2 id="line-xeqminusy">{"$y = -x \\ \\ $"}: &nbsp; směrnice {"$a = -1$"}</h2>
        <p>Přímka se směrnicí {"$a = -1$"} má rovnici
        {`$$
            y = -x
        $$`}
        To znamená, že je to osa druhého a čtvrtého kvadrantu. Klesá pod úhlem <span style={{color: "magenta"}}>-45°</span>.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_m45} style={{width: "50%"}}/>
        </div>
        <p>Přírůstky na ose x a y jsou 1:-1. Je to funkce, která obrací znaménko argumentu. Když je argument {"$x$"}, výstup je {"$-x$"}.
        </p>
        <p>Všimněte si, že platí {"$\\text{tg}(-45°) = -1$"}.</p>

        <h2 id="line-orientation">Orientace ve sklonu přímky dle hodnoty {"$a$"}</h2>
        <p>Čím větší je {"$a$"}, tím strmější je sklon přímky:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_spread1} style={{width: "90%"}}/>
        </div>
        <p>
        Vše v jednom obrázku:
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_spread2} style={{width: "70%"}}/>
        </div>
        <p>Teď už snad bude i jasné, že přímka s rovnicí {"$y = 1000x$"} bude hodně strmá, vizuálně bude téměř svislá.
            Přímka s rovnicí {"$y = 1000000x$"} bude ještě strmější, ještě bliší svislici (ale pořád ne úplně svislá).
        </p>

        <h2 id="line-known-angles">Přímky pod známými úhly</h2>
        <table className='simple-table'>
            <thead>
                <tr>
                <th>úhel [°]</th>
                <th>úhel [rad]</th>
                <th>směrnice ~ tangens</th>
                <th>rovnice přímky</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>{"$\\text{tg} 0 = 0$"}</td>
                    <td>{"$y = 0\\cdot x = 0$"} (osa x)</td>
                </tr>
                <tr>
                    <td>30°</td>
                    <td>{"$\\frac{\\pi}{6}$"}</td>
                    <td>{"$\\text{tg} \\frac{\\pi}{6} = \\frac{1}{\\sqrt{3}}$"}</td>
                    <td>{"$y = \\frac{1}{\\sqrt{3}}x$"}</td>
                </tr>
                <tr>
                    <td>45°</td>
                    <td>{"$\\frac{\\pi}{4}$"}</td>
                    <td>{"$\\text{tg} \\frac{\\pi}{4} = 1$"}</td>
                    <td>{"$y = x$"}</td>
                </tr>
                <tr>
                    <td>60°</td>
                    <td>{"$\\frac{\\pi}{3}$"}</td>
                    <td>{"$\\text{tg} \\frac{\\pi}{3} = \\sqrt{3}$"}</td>
                    <td>{"$y = \\sqrt{3}x$"}</td>
                </tr>
                <tr>
                    <td>90°</td>
                    <td>{"$\\frac{\\pi}{2}$"}</td>
                    <td>{"$\\lim_{\\alpha \\to \\frac{\\pi}{2}^-} \\text{tg} \\alpha = +\\infty$"}</td>
                    <td>{"$x = 0$"} (osa y)</td>
                </tr>
            </tbody>
        </table>
        <p>
        Přímka pod úhlem 90° je svislá, takže není funkcí (!). Je to množina bodů, jejichž x-ová souřadnice je 0. Proto má rovnici {"$x = 0$"}.
        </p>

        <h2 id="line-generic">Obecně položená přímka</h2>
        <p>
        Přímka majicí rovnici v obecném tvaru:
        {`$$
            y = ax + b
        $$`}
        neprochází počátkem, ale je posunutá o hodnotu {"$b$"} na ose y.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={line_b} style={{width: "60%"}}/>
        </div>
        <p>Pokud nás zajímá souřadnice {"$x$"} kde přímka protne osu x, můžeme to spočítat z rovnice:
        {`$$\\begin{align*}
            0 &= ax + b \\\\
            ax &= -b \\\\
            x &= -\\frac{b}{a}
        \\end{align*}$$`}
        </p>
        <p>
        Na významu směrnice se vůbec nic nezměnilo - hodnota {"$b$"} nemá žádný vliv na smysl a interpretaci směrnice tak, jak ji už známe.
        </p>

        <h2 id="line-mutual-position">Poloha dvou přímek</h2>

        <p>
        Dvě přímky jsou buď rovnoběžné, nebo se protínají. Mějme jejich rovnice:
        {`$$\\begin{align*}
            y &= a_1 x + b_1 \\\\
            y &= a_2 x + b_2
        \\end{align*}$$`}
        Aby byly <strong>rovnoběžné, musí platit {"$\\mathbf{a_1 = a_2}$"}</strong> - tj. jejich směrnice musí být stejné.
        Kdyby směrnice byly různé, přímky by měly různý sklon (směr), a tedy by se musely někde protnout.
        </p>

        <p>Pokud se přímky protínají, bod průsečíku má souřadnice {"$[x, y]$"} splňující obě rovnice:
        {`$$\\begin{align*}
            y &= a_1 x + b_1 \\\\
            y &= a_2 x + b_2
        \\end{align*}$$`}
        ... to jsou dvě lineární rovnice o dvou neznámých, které můžeme vyřešit. Řešení je bod průsečíku.
        </p>

        <p>Pokud jsou přímky na sebe <strong>kolmé</strong>, musí být skalární součin jejich směrových vektorů roven nule:
        {`$$\\begin{align*}
            (1, a_1) \\cdot (1, a_2) = 1 + a_1 a_2 &= 0 \\\\
                                           a_1 a_2 &= -1 \\\\
                                           \\mathbf{a_2} &\\mathbf{= -\\frac{1}{a_1}}
        \\end{align*}$$`}
        Směrnice druhé přímky je tedy převrácená hodnota směrnice první přímky, vzatá s opačným znaménkem.
        Např. tyto dvě přímky jsou na sebe kolmé:
        {`$$\\begin{align*}
            p_1: y &= 2x + 1 \\\\
            p_2: y &= -\\frac{1}{2}x - 1
        \\end{align*}$$`}
        </p>

        <h2 id="line-through-point">Přímka procházející zadaným bodem</h2>
        <p>
        Přímka procházející bodem {"$[x_0, y_0]$"} má rovnici
        {`$$
            y - y_0 = a(x - x_0) \\qquad \\qquad  y = y_0 + a(x - x_0)
        $$`}
        Když za {"$x$"} dosadíme {"$x_0$"}, dostaneme {"$y = y_0 + a \\cdot 0 = y_0$"} - tedy bod {"$[x_0, y_0]$"} leží na přímce.
        </p>


        <Footer/>
        </ChapterWrapper>
    );
}

