import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';

const cv_1_kalkulacka = require('../assets/images/cv_1_kalkulacka.png');


export const InverseFunctionMeta = {
    title: "Inverzní funkce",
    shortTitle: "Inverzní funkce",
    path: Path.inverse_function,
    element: (sectionNumber:string) => <InverseFunction sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
   "invf-calculation",
   "invf-check",
   "invf-notation",
   "invf-examples",
   "invf-mutual",
   "invf-graph",
];


export function InverseFunction({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={InverseFunctionMeta.title} TOCSpec={TOCSpec}>


        <h2 id="invf-calculation">Výpočet (mechanický)</h2>
        <p>
        Napíšeme zadání {"$y = f(x)$"}.
        Nalevo místo {"$y$"} napíšeme {"$x$"}, napravo všude místo {"$x$"} napíšeme {"$y$"}.
        Získáme {"$x = f(y)$"}.
        Z tohoto výrazu úpravami vyjádříme {"$y$"}: {"$y = g(x)$"}.
        Nalezená funkce {"$g(x)$"} je inverzní funkcí k funkci {"$f(x)$"}.
        </p>

        <h2 id="invf-check">Zkouška</h2>
        <p>
        Složením funkcí.
        Musí vyjít {"$f(g(x)) = x$"}, a také {"$g(f(x)) = x$"}.
        Stačí kontrolovat jen jeden způsob složení.
        Výraz {"$f(g(x))$"} znamená, že v {"$f(x)$"} každý výskyt {"$x$"} nahradíme vzorcem {"$g(x)$"}.
        </p>
        <p>
        Pozor: Ve výpočtu nemusí jít {"$y$"} vyjádřit - inverzní funkce nemusí existovat.
        Může jít ale vyjádřit za dodatečných předpokladů (omezení def. oboru {"$f(x)$"},
        aby na něm funkce byla prostá).
        </p>
        <p>
        Př.:  {"$y = \\frac{x - 1}{x + 2}$"} <br/><br/>
        Výpočet:
        {`$$\\begin{align*}
            x = \\frac{y - 1}{y + 2} \\ \\iff \\ (y + 2) x &= y - 1 \\ \\iff \\ yx - y = - 2x -1 \\ \\iff \\ \\\\
                                           y(x-1) &= - (2x+1) \\ \\iff \\ y = \\frac{2x + 1}{1-x}
        \\end{align*}$$`}

        Zkouška: {"$f(x) = \\frac{x - 1}{x + 2},   \\ \\ g(x) = \\frac{2x + 1}{1-x}$"}.

        {`$$
            f\\big(g(x)\\big) = \\frac{\\frac{2x + 1}{1-x} - 1}{ \\frac{2x + 1}{1-x} + 2} = \\frac{2x +1 - (1-x)}{2x+1 + 2(1-x)} = \\frac{3x}{3} = x  \\quad \\textrm{OK}
        $$`}

        {`$$
            g\\big(f(x)\\big) = \\frac{2\\frac{x - 1}{x + 2} + 1}{1-\\frac{x - 1}{x + 2}} = \\frac{2(x-1) + (x+2)}{(x+2) - (x-1)} = \\frac{3x}{3} = x  \\quad \\textrm{OK}
        $$`}
        </p>

        <h2 id="invf-notation">Značení</h2>
        <p>
        Inverzní funkce k {"$f(x)$"} se běžně značí {"$f^{-1}(x)$"}. <span style={{color: 'red'}}>Pozor, tohle značení neznamená {"$\\frac{1}{f(x)}$"}.</span>
        </p>

        <table className='simple-table'>
            <thead>
                <tr>
                    <th>funkce</th>
                    <th>inverzní funkce</th>
                    <th>značení na kalkulačce</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$ \\sin $"}</td>
                    <td>{"$ \\arcsin $"}</td>
                    <td>{"$ \\sin^{-1} $"}</td>
                </tr>
                <tr>
                    <td>{"$ \\cos $"}</td>
                    <td>{"$ \\arccos $"}</td>
                    <td>{"$ \\cos^{-1} $"}</td>
                </tr>
                <tr>
                    <td>{"$ \\text{tg} $"}</td>
                    <td>{"$ \\text{arctg} $"}</td>
                    <td>{"$ \\tan^{-1} $"}</td>
                </tr>
            </tbody>
        </table>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={cv_1_kalkulacka} style={{width: "36%"}}/>
        </div>


        <h2 id="invf-examples">Několik inverzních funkcí</h2>

        <table className='simple-table'>
            <thead>
                <tr>
                    <th>funkce</th>
                    <th>inverzní funkce</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$ x $"}</td>
                    <td>{"$ x $"}</td>
                </tr>
                <tr>
                    <td>{"$ \\frac{1}{x} $"}</td>
                    <td>{"$ \\frac{1}{x} $"}</td>
                </tr>
                <tr>
                    <td>{"$ e^x $"}</td>
                    <td>{"$ \\ln(x) $"}</td>
                </tr>
                <tr>
                    <td>{"$ 10^x $"}</td>
                    <td>{"$ \\log(x) $"}</td>
                </tr>
            </tbody>
        </table>

        <h2 id="invf-mutual">Vzájemnost</h2>
        <p>
        Funkce jsou k sobě inverzní navzájem: Je-li {"$f$"} inverzní ke {"$g$"}, pak je také {"$g$"} inverzní k {"$f$"}.<br/>
        Např.: Inverzní funkce k {"$\\sin(x)$"} je {"$\\arcsin(x)$"}. Inverzní funkce k {"$\\arcsin(x)$"} je {"$\\sin(x)$"}.
        </p>

        <h2 id="invf-graph">Graf inverzní funkce</h2>
        <p>
        Ozrcadlený kolem osy  {"$y = x$"} (úhlopříčka 1. a 3. kvadrantu).
        </p>
    <Footer/>
    </ChapterWrapper>
    );
}

