import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const PComputationHalfMeta = {
    shortTitle: "$\\small{P\\left((-\\infty, b\\rangle\\right), P\\left((a, \\infty)\\right)}$",
    title: "Výpočet p-sti intervalů $(-\\infty, b \\rangle$ a $(a, \\infty)$",
    path: Path.pcomuptation_half,
    element: (sectionNumber:string) => <PComputationHalf sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];



export function PComputationHalf({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={PComputationHalfMeta.title} TOCSpec={TOCSpec}>


        <p>
            Platí obecně (pro diskrétní i spojitou náhodnou veličinu):
            {`$$\\begin{align*}
                P\\left((b, \\infty)\\right) &= 1 - P\\left((-\\infty, b\\rangle\\right) \\\\
            \\end{align*}$$`}
            ...protože {"$A = (-\\infty, b\\rangle$"} a {"$B = (b, \\infty)$"} jsou doplňkové jevy,
            tj. {"$A \\cup B = \\mathbb{R}$"} a {"$A \\cap B = \\emptyset$"}. Jejich pravděpodobnosti
            se tedy musejí sečíst to jedničky: {"$P(A) + P(B) = 1$"}. Odtud {"$P(B) = 1 - P(A)$"}.
        </p>

        <div className='prob-theory'>
            <div style={{ flex: 1}}>
            Diskrétní
            </div>

            <div style={{ flex: 1}}>
            Spojitá
            </div>
        </div>


        <div className='prob-theory'>
            <div style={{ flex: 1}}>

                <p>A. Výpočet pomocí {"$p(x)$"}:
                {`$$\\begin{align*}
                    P\\left((-\\infty, b\\rangle\\right) &= \\sum_{x_i \\leq b} p(x_i) \\\\
                    P\\left((a, \\infty)\\right) &= \\sum_{x_i > a} p(x_i)
                \\end{align*}$$`}
                <i>Slovně: sečteme pravděpodobnosti těch {"$x_i$"}, které spadají do daného intervalu.
                </i>
                </p>
                <p>&nbsp;</p>
                <p>B. Výpočet pomocí {"$F(x)$"}:
                {`$$\\begin{align*}
                    P\\left((-\\infty, b\\rangle\\right) & = F(b) \\\\
                    P\\left((a, \\infty)\\right) & = 1 - F(a)
                \\end{align*}$$`}
                U intervalu {"$(-\\infty, b\\mathbf{\\color{RoyalBlue}{)}}$"} musíme navíc odečíst {"$p(b)$"},
                což je schod {"$F(x)$"} v bodě {"$b$"}.
                U intervalu {"$\\mathbf{\\color{RoyalBlue}{\\langle}} a, \\infty)$"} musíme navíc
                přičíst {"$p(a)$"}, což je schod {"$F(x)$"} v bodě {"$a$"}.
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>A. Výpočet pomocí {"$f(x)$"}:
                {`$$\\begin{align*}
                    P\\left((-\\infty, b\\rangle\\right) &= \\int_{-\\infty}^b f(x) \\, \\text{d}x \\\\
                    P\\left((a, \\infty)\\right) &= \\int_{a}^{\\infty} f(x) \\, \\text{d}x
                \\end{align*}$$`}
                <i>Slovně: určíme velikost plochy pod grafem {"$f(x)$"} mezi danými mezemiz. Typ intervalu (uzavřený, otevřený) nehraje roli.</i>
                </p>
                <p>&nbsp;</p>
                <p>B. Výpočet pomocí {"$F(x)$"}:
                {`$$\\begin{align*}
                    P\\left((-\\infty, b\\rangle\\right) & = F(b) \\\\
                    P\\left((a, \\infty)\\right) & = 1 - F(a)
                \\end{align*}$$`}
                <i>Typ intervalu (uzavřený, otevřený) nehraje roli.</i>
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Je dána pravděpodobnostní funkce {"$p(x)$"}:
                {`$$
                \\begin{array}{c|cccc}
                x_i & 1 & 2 & 3 & 4 \\\\\ \\hline
                p(x_i) & 0.2 & 0.3 & 0.4 & 0.1
                \\end{array}
                $$`}
                Určete pravděpodobnost intervalů:
                {`$$\\begin{align*}
                & P((-\\infty, 2 \\rangle) = ? \\\\
                & P((-\\infty, 2 )) = ? \\\\
                & P(\\langle 3, \\infty)) = ? \\\\
                & P((3, \\infty)) = ? \\\\
                \\end{align*}$$`}
                Řešení:
                {`$$
                \\begin{array}{|c|c|c|}
                \\text{I} & \\text{do I patří} & \\text{pravděpodobnost} \\\\ \\hline
                (-\\infty, 2 \\rangle & 1, 2 & 0.2 + 0.3 = 0.5 \\\\
                (-\\infty, 2) & 1 & 0.2 \\\\
                \\langle 3, \\infty) & 3, 4 & 0.4 + 0.1 = 0.5 \\\\
                (3, \\infty) & 4 & 0.1
                \\end{array}
                $$`}
                </p>
                <p>
                Určete pravděpodobnost intervalů:
                {`$$\\begin{align*}
                & P((-\\infty, 1.5 \\rangle) = ? \\\\
                & P((-\\infty, 1.5 )) = ? \\\\
                & P(\\langle 3.5, \\infty)) = ? \\\\
                & P((3.5, \\infty)) = ? \\\\
                \\end{align*}$$`}
                Řešení:
                {`$$
                \\begin{array}{|c|c|c|}
                \\text{I} & \\text{do I patří} & \\text{pravděpodobnost} \\\\ \\hline
                (-\\infty, 2 \\rangle & 1 & 0.2 \\\\
                (-\\infty, 2) & 1 & 0.2 \\\\
                \\langle 3, \\infty) & 4 & 0.1 \\\\
                (3, \\infty) & 4 & 0.1
                \\end{array}
                $$`}
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Je dána hustota pravděpodobnosti {"$f(x)$"}:
                {`$$
                f(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                      x & \\text{ pro } x \\in \\langle 0, 1)  \\\\
                                      (2-x) & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                        0 & \\text{ pro } x \\geq 2 \\end{cases}
                $$`}
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$(-\\infty, 0.5 \\rangle$"}:
                {`$$
                \\int_{0}^{0.5} x \\, \\text{d}x = \\left[\\frac{x^2}{2}\\right]_{0}^{0.5} = \\frac{0.25 - 0}{2} = 0.125
                $$`}
                Identický výpočet i výsledek je pro interval {"$(-\\infty, 0.5)$"}.
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$(0.5, \\infty)$"}:
                {`$$\\begin{align*}
                & \\int_{0.5}^{\\infty} f(x) \\, \\text{d}x = \\\\
                & = \\int_{0.5}^{1} x \\, \\text{d}x + \\int_{1}^{2} (2-x) \\, \\text{d}x = \\\\
                & = \\left[\\frac{x^2}{2}\\right]_{0.5}^{1} + \\left[2x - \\frac{x^2}{2}\\right]_{1}^{2} = \\\\
                & = \\frac{1 - 0.25}{2} + \\left(4 - \\frac{4}{2} - 2 + \\frac{1}{2}\\right) = \\\\
                & = 0.375 + 0.5 = 0.875
                \\end{align*}$$`}
                Identický výpočet i výsledek je pro interval {"$(0.5, \\infty)$"}.
                </p>
            </div>
        </div>

        <div className='prob-example'>
            <div style={{ flex: 1}}>
                <p>
                Je dána distribuční funkce {"$F(x)$"}:
                {`$$
                F(x) = \\begin{cases} 0 & \\text{ pro } x < 1 \\\\
                                      0.1 & \\text{ pro } x \\in \\langle 1, 2) \\\\
                                      0.3 & \\text{ pro } x \\in \\langle 2, 3) \\\\
                                      1 & \\text{ pro } x \\geq 3 \\end{cases}
                $$`}
                </p>
                <p>
                Určete pravděpodobnost intervalu {"$(-\\infty, 2 \\rangle$"}:
                {`$$
                P((-\\infty, 2 \\rangle) = F(2) = 0.3
                $$`}
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$(2, \\infty)$"}:
                {`$$
                P((2, \\infty)) = 1 - F(2) = 1 - 0.3 = 0.7
                $$`}
                </p>
                <p>&nbsp;</p>
                <p>
                Určete pravděpodobnost intervalu {"$\\color{BrickRed}{\\langle 2, \\infty)}$"}:
                {`$$\\begin{align*}\\small
                & {\\color{BrickRed}{\\langle 2, \\infty)}} = {\\color{green}{\\{2\\}}} \\cup {\\color{RoyalBlue}{(2, \\infty)}} \\quad \\text{(disjunktní sjednocení)} \\\\
                & P({\\color{BrickRed}{\\langle 2, \\infty)}}) = P({\\color{green}{\\{2\\}}}) + P({\\color{RoyalBlue}{(2, \\infty)}}) \\\\
                & \\ \\\\
                & P({\\color{green}{\\{2\\}}}) = p({\\color{green}{2}}) = | \\text{velikost schodu v x = 2} | =  \\\\
                & = 0.3 - 0.1 = {\\color{green}{0.2}} \\\\
                & \\ \\\\
                & P({\\color{RoyalBlue}{(2, \\infty)}}) = 1 - F(2) = {\\color{RoyalBlue}{0.7}} \\\\
                & \\ \\\\
                & P({\\color{BrickRed}{\\langle 2, \\infty)}}) = {\\color{green}{0.2}} + {\\color{RoyalBlue}{0.7}} = 0.9
                \\end{align*}$$`}
                <i>Pozn.: To už vychází lepší převést {"$F(x)$"} na {"$p(x)$"} a použít první metodu.</i>
                </p>
            </div>

            <div style={{ flex: 1}}>
                <p>
                Je dána distribuční funkce {"$F(x)$"}:
                {`$$
                F(x) = \\begin{cases} 0 & \\text{ pro } x < 0 \\\\
                                      x^2 & \\text{ pro } x \\in \\langle 0, 1) \\\\
                                      1 & \\text{ pro } x \\geq 1 \\end{cases}
                $$`}
                </p>
                <p>
                Určete p-st intervalu {"$(-\\infty, 0.6 \\rangle$"}:
                {`$$
                    P((-\\infty, 0.6 \\rangle) = F(0.6) = 0.36
                $$`}
                Identický výpočet i výsledek je pro interval {"$(-\\infty, 0.6)$"}.
                </p>
                <p>&nbsp;</p>
                <p>
                Určete p-st intervalu {"$( 0.4, \\infty)$"}:
                {`$$\\small
                P(( 0.4, \\infty)) = 1 - F(0.4) = 1 - 0.16 = 0.84
                $$`}
                Identický výpočet i výsledek je pro interval {"$( 0.4, \\infty)$"}.
                </p>
            </div>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

