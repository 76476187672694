import React from 'react';

import { createBrowserRouter, Outlet, RouterProvider, useLocation } from "react-router-dom";

import './App.css';
import { Section, sections } from './defs';
import { FooterMini } from './Footer';
import { LandingPage } from './LandingPage';
import { ContentsMenu } from './Menu';
import { NavBar } from './NavBar';
import { Path } from './paths';
import { version } from "./version";

import {
    AbsValueMeta,
    AnglesMeta,
    AsymptotesMeta,
    BasicsEquationsMeta,
    BasicsFractionsMeta,
    BasicsPiecewiseMeta,
    BasicsPowerFuncsMeta,
    BasicsSqrtMeta,
    CDFOverviewMeta,
    Derivatives1Meta,
    DomainsMeta,
    ExpectationMeta,
    ExtremesMeta,
    FunctionGraphTransformations2Meta,
    FunctionGraphTransformationsMeta,
    FunctionLineMeta,
    FunctionNotationMeta,
    FunctionParityMeta,
    FunctionQuadraticMeta,
    FunctionShapeMeta,
    FunctionsNotesMeta,
    FunctionZooMeta,
    GoniometricMeta,
    Integration1Meta,
    Integration2Meta,
    Integration3Meta,
    Integration4Meta,
    Integration5Meta,
    IntegrationDefiniteMeta,
    IntegrationFubMeta,
    InverseFunctionMeta,
    LimitsMeta,
    PComputationHalfMeta,
    PComputationMeta,
    PolynomialDivisionMeta,
    PolynomialFactorizationMeta,
    RVOverviewMeta,
    SectionBasicsMeta,
    SectionDerivativesMeta,
    SectionFunctionsMeta,
    SectionIntegrationMeta,
    SectionProbabilityMeta,
    SignOfExpressionMeta,
    SinCosTgValuesMeta,
    SubstitutionMeta,
    TangentLineMeta,
    TaylorMeta,
    TransformfFMeta,
    VarianceMeta,
} from './chapters';


const AppLayout = () => {
    const location = useLocation();
    const isHistogramApp = location.pathname.includes('histogram-app');

    return (
        <div className="App" style={{display: "flex", gap: 0, width: "100vw"}}>
            <div className="sidebar" style={{display: "flex", flexDirection: "column"}}>
                <NavBar/>
                <div className='menu-container' style={{flexGrow: 1}}>
                    <ContentsMenu sections={sections}/>
                </div>
                <div className="small-text">verze z {version}</div>
                <FooterMini />
            </div>
            <div className="page" style={{flexBasis: isHistogramApp ? '0' : '6', flexGrow: 6}}>
                <Outlet />
            </div>
        </div>
    );
};

function routerChild(section: Section) {
    return {
        path: section.path,
        element: section.element(section.sectionNumber),
    }

}

const router = createBrowserRouter([
    {
        path: Path.root,
        element: <AppLayout/>,
        children: [
            {
                path: '',
                element: (
                    <LandingPage/>
                )
            },
            routerChild(AbsValueMeta),
            routerChild(AnglesMeta),
            routerChild(AsymptotesMeta),
            routerChild(BasicsEquationsMeta),
            routerChild(BasicsFractionsMeta),
            routerChild(BasicsPiecewiseMeta),
            routerChild(BasicsPowerFuncsMeta),
            routerChild(BasicsSqrtMeta),
            routerChild(Derivatives1Meta),
            routerChild(DomainsMeta),
            routerChild(ExtremesMeta),
            routerChild(FunctionGraphTransformations2Meta),
            routerChild(FunctionGraphTransformationsMeta),
            routerChild(FunctionLineMeta),
            routerChild(FunctionNotationMeta),
            routerChild(FunctionParityMeta),
            routerChild(FunctionQuadraticMeta),
            routerChild(FunctionShapeMeta),
            routerChild(FunctionsNotesMeta),
            routerChild(FunctionZooMeta),
            routerChild(GoniometricMeta),
            routerChild(Integration1Meta),
            routerChild(Integration2Meta),
            routerChild(Integration3Meta),
            routerChild(Integration4Meta),
            routerChild(Integration5Meta),
            routerChild(IntegrationDefiniteMeta),
            routerChild(IntegrationFubMeta),
            routerChild(InverseFunctionMeta),
            routerChild(LimitsMeta),
            routerChild(PolynomialDivisionMeta),
            routerChild(PolynomialFactorizationMeta),
            routerChild(SectionBasicsMeta),
            routerChild(SectionDerivativesMeta),
            routerChild(SectionFunctionsMeta),
            routerChild(SectionIntegrationMeta),
            routerChild(SectionProbabilityMeta),
            routerChild(SignOfExpressionMeta),
            routerChild(SinCosTgValuesMeta),
            routerChild(SubstitutionMeta),
            routerChild(TangentLineMeta),
            routerChild(TaylorMeta),
            routerChild(RVOverviewMeta),
            routerChild(CDFOverviewMeta),
            routerChild(TransformfFMeta),
            routerChild(ExpectationMeta),
            routerChild(VarianceMeta),
            routerChild(PComputationMeta),
            routerChild(PComputationHalfMeta),
        ],
    }
]);


export default function App() {
    return (
        <RouterProvider router={router} />
    );
}



// vzorec je jen zápisem mentálního obrazu toho, co děláme
// nedosazujeme bezduše do vzorce
//  manipulace vzorce je technická /bez představy/, i když i to lze na
// určitých mezivýsledcích a určitě na finálním výsledku

//
// PAMATUJ SI, ŽE NEPises ucenbinci,
// ale manuál k typovým příkladům, které pokrývá cviko
//