import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const Integration3Meta = {
    title: "Integrál 3: Substituce",
    shortTitle: "Integrál 3 (SUB)",
    path: Path.integration3,
    element: (sectionNumber:string) => <Integration3 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [];


export function Integration3({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={Integration3Meta.title} TOCSpec={TOCSpec}>

        <h2>Shrnutí</h2>
        <p>Substituce je dvou typů:</p>
        <ul>
            <li>
            <p>Přímá substituce (substituce 1. typu):
            {`$$
                t = g(x) \\quad \\text{d}t = g'(x) \\text{d}x \\quad \\text{d}x = \\frac{1}{g'(x)} \\text{d}t
            $$`}
            Příklad:
            {`$$
                \\small{
                \\int x \\cos(x^2) \\text{d}x = \\left| \\begin{array}{c}
                t = x^2 \\\\
                \\text{d}t = 2x \\, \\text{d}x \\\\
                \\text{d}x = \\frac{1}{2x} \\, \\text{d}t
            \\end{array} \\right| = \\int \\cancel{x} \\cos t \\, \\frac{1}{2\\cancel{x}} \\, \\text{d}t = \\frac{1}{2} \\int \\cos t \\, \\text{d}t = \\frac{1}{2} \\sin t + C = \\frac{1}{2} \\sin(x^2) + C
            }
            $$`}
            </p>
            <p>
            Po provedení substituce musí stará proměnná {"$x$"} zmizet z integrálu - tím, že se vykrátí.
            Pokud zůstane, je něco špatně - substituce je buď nevhodná, nebo je v úpravě chyba.
            </p>
            </li>
            <li>
            <p>Nepřímá substituce (substituce 2. typu):
            {`$$
                x = g(t) \\quad \\text{d}x = g'(t) \\text{d}t \\quad \\text{d}t = \\frac{1}{g'(t)} \\text{d}x
            $$`}
            POZOR: Tato substituce dává korektní výsledek <span style={{color: "red"}}>jen pokud {"$g(t)$"} je prostá funkce!</span>
            </p>
            <p>Příklad:
            {`\\begin{align*}
                {\\small{\\int \\frac{1}{x^2 + 1} \\text{d}x \\ }} & {\\small{ = \\left| \\begin{array}{c}
                x = \\text{tg}\\, t \\\\
                \\text{d}x = \\frac{1}{\\cos^2 t} \\text{d}t
            \\end{array} \\right| = \\int \\frac{1}{\\text{tg}^2 \\, t + 1} \\cdot \\frac{1}{\\cos^2 t} \\, \\text{d}t = \\int \\frac{1}{\\sin^2 t + \\cos^2 t} \\, \\text{d}t = }}  \\\\
                & {\\small{= \\int 1 \\, \\text{d}t =  t + C = \\text{arctg} x + C}}

            \\end{align*}`}
            </p>
            <p>
            Po provedení substituce musí stará proměnná {"$x$"} zmizet z integrálu. Všude, kde je {"$x$"}, za něj dosadíme {"$g(t)$"}. Pokud zůstane, je něco špatně - v úpravě je chyba.
            </p>
            <p>
            Zpětná subsituce je {"$t = g^{-1}(x)$"}, tj. pomocí inverzní funkce. Inverzní funkce existuje, protože jsme použili prostou funkci {"$g(t)$"}.
            </p>

            </li>
        </ul>

        <Footer/>
        </ChapterWrapper>
    );
}
