import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const Integration5Meta = {
    title: "Integrál 5: sin, cos",
    shortTitle: "Integrál 5 (sin, cos)",
    path: Path.integration5,
    element: (sectionNumber:string) => <Integration5 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "int-sincos-intro",
    "int-sincos-1",
    "int-sincos-double",
    "int-sincos-",
    "int-sincos-ampphase",
    "int-sincos-notes",
];


export function Integration5({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={Integration5Meta.title} TOCSpec={TOCSpec}>

        <h2 id="int-sincos-intro">Principy</h2>
        <p>Integrály, kde se vyskytuje {"$\\sin$"} a {"$\\cos$"}, se řeší různými způsoby, s využitím různých
        vztahů mezi funkcemi sinus a kosinus. Níže si ukážeme ty jednodušší:
        </p>

        <h2 id="int-sincos-1">Goniometrická jednička</h2>
        <p>
        {`$$
            {\\color{BrickRed}{\\sin^2 x + \\cos^2 x = 1}}
        $$`}
        Příklad využití:
        {`\\begin{align*}
            \\int \\sin^3 x \\,\\text{d}x = \\int {\\color{BrickRed}{\\sin^2} x} \\sin x \\,\\text{d}x & = \\int {\\color{BrickRed}{(1 - \\cos^2 x)}} \\sin x \\,\\text{d}x = \\\\
                                                                                  & = \\int \\sin x \\,\\text{d}x - \\int \\cos^2 x \\sin x \\,\\text{d}x = \\\\
                                                                                  & = -\\cos x + \\frac{1}{3}\\cos^3 x + C  \\ \\ \\text{/sub: } \\cos x = t \\text{/}
        \\end{align*}`}
        </p>

        <p> Další příklad využití:
        {`\\begin{align*}
        \\int \\frac{{\\color{BrickRed}{1}}}{\\sin^2 x \\cos^2 x} \\,\\text{d}x &= \\int \\frac{{\\color{BrickRed}{\\sin^2 x + \\cos^2 x}}}{\\sin^2 x \\cos^2 x} \\,\\text{d}x = \\int \\frac{1}{\\cos^2 x} + \\frac{1}{\\sin^2 x} \\,\\text{d}x = \\text{tg}\\,x - \\text{cotg}\\,x + C
        \\end{align*}`}
        </p>

        <h2 id="int-sincos-double">Převod na dvojnásobný argument</h2>
        <p>
        {`$$
            {\\color{RoyalBlue}{\\cos^2 x = \\frac{1 + \\cos 2x}{2}}} \\qquad \\quad {\\color{green}{\\sin^2 x = \\frac{1 - \\cos 2x}{2}}}
        $$`}
        Příklad využití:
        {`\\begin{align*}
            \\int {\\color{RoyalBlue}{\\cos^2 x}} \\,\\text{d}x & = \\int {\\color{RoyalBlue}{\\frac{1 + \\cos 2x}{2}}} \\,\\text{d}x = \\frac{1}{2} \\int 1 + \\cos 2x \\,\\text{d}x = \\frac{1}{2} \\left(x + \\frac{1}{2}\\sin 2x\\right) + C \\\\
            \\int {\\color{green}{\\sin^2 x}} \\,\\text{d}x & = \\int {\\color{green}{\\frac{1 - \\cos 2x}{2}}} \\,\\text{d}x = \\frac{1}{2} \\int 1 - \\cos 2x \\,\\text{d}x = \\frac{1}{2} \\left(x - \\frac{1}{2}\\sin 2x\\right) + C
        \\end{align*}`}
        </p>

        <h2 id="int-sincos-">* Součin na součet</h2>
        <p><i>* tato sekce je pro pokročilé</i></p>
        <p>
        {`$$
            \\sin ax \\cdot \\cos bx = \\frac{1}{2} \\big[\\sin(a + b)x + \\sin(a - b)x\\big]
        $$`}
        Příklad využití:
        {`\\begin{align*}
            \\int \\sin 2x \\cdot \\cos 3x \\,\\text{d}x & = \\frac{1}{2} \\int \\sin 5x + \\sin (-x) \\,\\text{d}x = \\frac{1}{2} \\left(-\\frac{1}{5}\\cos 5x + \\cos x\\right) + C
        \\end{align*}`}
        </p>
        <p>Analogicky pro součin dvou sinů a součin dvou kosinů - viz součtové vzorce.</p>

        <h2 id="int-sincos-ampphase">* Amplituda, fáze</h2>
        <p><i>* tato sekce je pro pokročilé</i></p>
        <p>Libovolnou dvojici čísel {"$a, b$"} lze vyjádřit pomocí sinu a kosinu:
        {`$$
            (a, b) = \\sqrt{a^2 + b^2} \\cdot \\left(\\frac{a}{\\sqrt{a^2 + b^2}}, \\frac{b}{\\sqrt{a^2 + b^2}}\\right) = A \\cdot (\\cos \\varphi, \\sin \\varphi)
        $$`}
        kde {"$\\varphi$"} je úhel jednoznačně určený polohou bodu {"$\\left(\\frac{a}{\\sqrt{a^2 + b^2}}, \\frac{b}{\\sqrt{a^2 + b^2}}\\right)$"}
        na jednotkové kružnici, a konstanta {"$A = \\sqrt{a^2 + b^2}$"} je amplituda. Jiný pohled je, že {"$(A, \\varphi)$"} jsou polární
        souřadnice bodu v rovině - amplituda {"$A$"} je poloměr kružnice se středem v počátku, na které bod {"$(a, b)$"} leží.
        </p>
        <p>Součtový vzorec:
        {`$$
            \\cos x \\sin y + \\sin x \\cos y = \\sin(x + y)
        $$`}
        </p>
        <p>Příklad využití:
        {`\\begin{align*}
            \\int \\frac{1}{a\\sin x + b\\cos x} \\,\\text{d}x & = \\int \\frac{1}{A} \\cdot \\frac{1}{\\cos \\varphi \\sin x + \\sin \\varphi \\cos x} \\,\\text{d}x = \\frac{1}{A} \\int \\frac{1}{\\sin(x + \\varphi)} \\,\\text{d}x = \\cdots
        \\end{align*}`}
        ... dále řešíme např. substitucí {"$t = \\text{tg}\\,\\frac{x+\\varphi}{2}$"}... výsledek bude {"$\\frac{1}{A}\\ln|\\text{tg}\\,\\frac{x + \\varphi}{2}| + C$"}.
        </p>

        <h2 id="int-sincos-notes">* Poznámky</h2>
        <p><i>* tato sekce je pro pokročilé</i></p>
        <p>Existují rekurentní vzorce pro integrály typu
        {`$$
            \\int \\sin^m x \\cos^n x \\,\\text{d}x \\qquad \\text{pro } m, n \\in \\mathbb{Z}
        $$`}
        ... viz literatura.
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}
