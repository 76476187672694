import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

export const Integration4Meta = {
    title: "Integrál 4: f(ax+ b)",
    shortTitle: "Integrál 4 f(ax+ b)",
    path: Path.integration4,
    element: (sectionNumber:string) => <Integration4 sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "int-lin-principle",
    "int-lin-examples",
    "int-lin-note",
];


export function Integration4({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={Integration4Meta.title} TOCSpec={TOCSpec}>

        <h2 id="int-lin-principle">Princip</h2>
        <p>Pokud umíme zintegrovat funkci {"$f(x)$"}, umíme zintegrovat i funkci {"$f(ax + b)$"}:
        {`\\begin{align*}
            \\int f(x) \\,\\text{d}x &= F(x) + C \\qquad \\int f({\\color{magenta}{a}}x + b) \\,\\text{d}x = {\\color{magenta}{\\frac{1}{a}}}F({\\color{magenta}{a}}x+b) + C
        \\end{align*}`}
        Když je ve funkci místo {"$x$"} lineární člen {"$ax + b$"}, funguje to takto.
        Jiná než lineární vnitřní složka se takto ale řešit nedá!
        </p>

        <p>Příklad:
        {`\\begin{align*}
            \\int \\cos({\\color{RoyalBlue}{2}}x + 3) \\,\\text{d}x &= \\frac{1}{{\\color{RoyalBlue}{2}}}\\sin(2x + 3) + C
        \\end{align*}`}
        Zkouška:
        {`\\begin{align*}
            \\left(\\frac{1}{2}\\sin(2x + 3)\\right)' &= \\frac{1}{\\cancel{{\\color{RoyalBlue}{2}}}} \\cos(2x + 3) \\cdot \\cancel{{\\color{RoyalBlue}{2}}}  = \\cos(2x + 3) \\ \\text{[OK]}
        \\end{align*}`}
        Vidíme, že konstanta {"$2$"} při derivování vyběhne jako derivace vnitřní složky - a pokrátí se s konstantou {"$\\frac{1}{2}$"} před integrálem. Proto to funguje.
        </p>

        <h2 id="int-lin-examples">Ukázky</h2>
        <p>
        {`\\begin{align*}
            \\int (4x - 1)^3 \\,\\text{d}x &= \\frac{1}{4} \\cdot \\frac{1}{4} (4x - 1)^4 + C \\\\
            \\int \\sqrt{2x + 3} \\,\\text{d}x &= \\frac{1}{2} \\cdot \\frac{(2x + 3)^{\\frac{3}{2}}}{\\frac{3}{2}} + C = \\frac{1}{3} (2x + 3)^{\\frac{3}{2}} + C \\\\
            \\int \\sin(3x) \\,\\text{d}x &= -\\frac{1}{3}\\cos(3x) + C \\\\
            \\int e^{1-x} \\,\\text{d}x &= \\frac{1}{(-1)}e^{1-x} + C = -e^{1-x} + C \\\\
            \\int \\frac{1}{5x + 3} \\,\\text{d}x &= \\frac{1}{5}\\ln|5x + 3| + C \\\\
            \\int \\frac{1}{1 + (2x - 1)^2} \\,\\text{d}x &= \\frac{1}{2} \\text{arctg}\\,(2x - 1) + C \\\\
            \\int \\frac{1}{\\sqrt{1 - (3x)^2}} \\,\\text{d}x &= \\frac{1}{3} \\arcsin(3x) + C \\\\
            \\int \\frac{1}{\\cos^2(4x + 5)} \\,\\text{d}x &= \\frac{1}{4} \\text{tg}(4x + 5) + C
        \\end{align*}`}
        Pozor: I výraz jako {"$\\frac{x}{2}$"} je typu {"$ax + b$"}: {"$\\frac{x}{2} = \\frac{1}{2}x + 0$"}:
        {`\\begin{align*}
            \\int \\cos\\left(\\frac{x}{2}\\right) \\,\\text{d}x &= \\frac{1}{\\frac{1}{2}}\\sin\\left(\\frac{x}{2}\\right) + C = 2\\sin\\left(\\frac{x}{2}\\right) + C
        \\end{align*}`}
        Někdy je potřeba vzorec napřed transformovat, aby bylo vidět, že jde o integrál typu {"$f(ax + b)$"}:
        {`\\begin{align*}
            \\int \\frac{1}{4 + x^2} \\,\\text{d}x &= ? \\ \\ \\small{\\text{na pozici 4 chceme 1, aby to byl tvarově vzorec pro arctg:}} \\\\
                           \\frac{1}{4 + x^2} &= \\frac{1}{4(1 + \\frac{x^2}{4})} = \\frac{1}{4} \\cdot \\frac{1}{1 + (\\frac{x}{2})^2} \\\\
            \\frac{1}{4} \\int \\frac{1}{1 + (\\frac{x}{2})^2} \\,\\text{d}x &= \\frac{1}{4} \\frac{1}{\\frac{1}{2}} \\text{arctg}\\,\\left(\\frac{x}{2}\\right) + C = \\frac{1}{2} \\text{arctg}\\,\\left(\\frac{x}{2}\\right) + C
        \\end{align*}`}
        </p>

        <h2 id="int-lin-note">Poznámka</h2>
        <p>Pokud bychom na toto pravidlo zapomněli, pořád můžeme situaci řešit substitucí:
        {`$$
            t = ax + b \\ \\ \\rightarrow \\ \\ \\text{d}t = a \\,\\text{d}x  \\ \\ \\rightarrow \\ \\ \\text{d}x = \\frac{1}{a} \\,\\text{d}t
        $$`}
        Tím převedeme
        {`$$
            \\int f(ax + b) \\,\\text{d}x = \\int f(t) \\,\\frac{1}{a} \\,\\text{d}t = \\frac{1}{a} \\int f(t) \\,\\text{d}t = \\frac{1}{a} F(t) + C = \\frac{1}{a} F(ax + b) + C
        $$`}
        Příklad:
        {`\\begin{align*}
            \\int \\cos(3x + 2) \\,\\text{d}x &= \\begin{array}{|c|}
                t = 3x + 2 \\\\
                \\text{d}t = 3 \\,\\text{d}x \\\\
                \\text{d}x = \\frac{1}{3} \\,\\text{d}t
            \\end{array} = \\frac{1}{3} \\int \\cos t \\,\\text{d}t = \\frac{1}{3} \\sin t + C = \\frac{1}{3} \\sin(3x + 2) + C
        \\end{align*}`}
        Máme správný výsledek, jen jsme se s tím trochu víc nadřeli.
        </p>


        <Footer/>
        </ChapterWrapper>
    );
}
