import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const DomainsMeta = {
    title: "Definiční obory",
    shortTitle: "Definiční obory",
    path: Path.domains,
    element: (sectionNumber:string) => <Domains sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];


export function Domains({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={DomainsMeta.title} TOCSpec={TOCSpec}>

        <table className='simple-table' style={{tableLayout: 'auto'}}>
            <tbody>
            <tr>
                <td>Funkce 'bezproblémové'</td>
                <td>definované všude (lze dosadit libovolné {"$x$"})</td>
                <td>{"$ D_f = \\mathbb{R} $"}</td>
            </tr>
            <tr>
                <td>Funkce 'problémové'</td>
                <td>někde nedefinované (některá {"$x$"} nelze dosadit)</td>
                <td>{"$ D_f \\neq \\mathbb{R} $"}</td>
            </tr>
            </tbody>
        </table>


        <p>
        <strong>Problémové funkce</strong>:
        {`$$\\begin{align*}
        & \\sqrt{x}          &&  x \\geq 0          &&  \\small{D_f = \\langle 0, \\infty)}              && \\small{\\text{$x$ nesmí být záporné; $x$ musí být nezáporné}} \\\\
        & \\ln(x)            &&  x > 0              &&  \\small{D_f = (0, \\infty)}                      && \\small{\\text{$x$ musí být kladné; $x$ musí být ostře větší než 0}} \\\\
        & \\frac{1}{x}       &&  x \\neq 0          &&  \\small{D_f = (-\\infty, 0) \\cup (0, \\infty)}  && \\small{\\text{$x$ nesmí být nula; alt. zápis } D_f = \\mathbb{R}\\!\\setminus\\!\\{0\\}}  \\\\
        & \\arcsin(x)		 &&  -1 \\leq x \\leq 1 &&  \\small{D_f = \\langle -1, 1 \\rangle}           && \\small{\\text{$x$ musí být v rozmezí od -1 včetně do 1 včetně}} \\\\
        & \\arccos(x)        &&  -1 \\leq x \\leq 1 &&  \\small{D_f = \\langle -1, 1 \\rangle}           && \\small{\\text{$x$ musí být v rozmezí od -1 včetně do 1 včetně}}
        \\end{align*}$$`}

        {`$$\\begin{align*}
        & \\text{tg}\\,(x)   && \\quad  x \\neq \\frac{\\pi}{2} \\pm k\\pi \\ \\ \\forall k \\in \\mathbb{N}_{0}  & &  \\qquad \\small{\\textrm{$x$ nesmí být rovno } \\cdots, - \\frac{5\\pi}{2}, -\\frac{3\\pi}{2}, -\\frac{\\pi}{2}, \\frac{\\pi}{2},  \\frac{3\\pi}{2}, \\frac{5\\pi}{2}, \\cdots} \\\\
        & \\text{cotg}\\,(x) && \\quad  x \\neq \\pm k\\pi \\ \\ \\forall k \\in \\mathbb{N}_{0}  & &  \\qquad  \\small{\\textrm{$x$ nesmí být rovno } \\qquad \\ \\ \\cdots, - 2\\pi, - \\pi, 0,  \\pi, 2\\pi, \\cdots} \\\\
        \\end{align*}$$`}
        </p>

        <h2>Odmocnina</h2>
        <p>
        {`$$\\begin{align*}
        & \\sqrt{f(x)}            && f(x)  \\geq 0     && \\cdots  \\\\
        & \\sqrt{2x + 1}          && 2x +1 \\geq 0     &&  x \\geq -\\frac{1}{2} \\\\
        & \\sqrt{\\sin x}         && \\sin x \\geq 0   &&  x \\in \\cdots \\cup \\langle -2\\pi, -\\pi \\rangle \\cup \\langle 0, \\pi \\rangle \\cup \\langle 2\\pi , 3\\pi \\rangle \\cup \\langle 4\\pi , 5\\pi \\rangle \\cup \\cdots \\\\
        &                         &&			       &&  x \\in \\bigcup_{k \\in \\mathbb{Z}} \\langle 2k\\pi, (2k+1)\\pi \\rangle \\\\
        & \\sqrt{x^2 - 1}         && x^2 - 1 \\geq 0   && \\small{(x-1)(x+1) \\geq 0  \\iff x \\leq -1 \\lor x \\geq 1 \\iff  x \\in (-\\infty, -1\\rangle \\cup \\langle 1, \\infty)} \\\\
        & \\sqrt{\\textrm{něco}}  && \\textrm{něco} \\geq 0   && \\small{\\text{Jde o to přesně opsat vše pod odmocninou, položit to $\\geq 0$, a pak nerovnici vyřešit.}}
        \\end{align*}$$`}
        </p>


        <h2>Logaritmus</h2>
        <p>
        {`$$\\begin{align*}
        & \\ln{\\left(f(x) \\right)}           && f(x) > 0            && \\cdots  \\\\
        & \\ln{\\left(3x - 1 \\right)}         && 3x - 1 > 0          && x > \\frac{1}{3} \\\\
        & \\ln{\\left(\\ln x \\right)}         && \\ln x  > 0         && x > 1 \\\\
        & \\ln{\\left(\\textrm{něco}\\right)}  && \\textrm{něco} > 0  && \\small{\\textrm{Jde o to přesně opsat vše v logaritmu, položit to $> 0$, a pak nerovnici vyřešit.}}
        \\end{align*}$$`}
        Stejný postup platí pro dekadický logaritmus {"$\\log(x)$"} a logaritmus o libovolném základu {"$\\log_a(x)$"}.
        </p>

        <h2>Podíl</h2>
        <p>
        {`$$\\begin{align*}
        & \\frac{g(x)}{f(x)}        && f(x)      \\neq 0      && \\cdots  \\\\
        & \\frac{x}{5x - 2}         && 5x - 2  \\neq 0        && x \\neq \\frac{2}{5} \\\\
        &  \\frac{\\textrm{čitatel}}{\\textrm{jmenovatel}}    && \\textrm{jmenovatel} \\neq 0       && \\small{\\textrm{Jde o to, přesně opsat celý jmenovatel, a vyřešit rovnici jmenovatel $\\neq$ 0. }}
        \\end{align*}$$`}
        </p>


        <h2>Arkus sinus</h2>
        <p>
        {`$$\\begin{align*}
        & \\arcsin\\left(f(x)\\right)   &&  -1 \\leq f(x) \\leq 1    && \\cdots  \\\\
        & \\arcsin(2 - 3x)              &&  -1 \\leq 2 - 3x \\leq 1  &&  \\textrm{A) }  -1 \\leq 2 - 3x \\iff  x \\leq 1  \\\\
        &                               &&                           &&  \\textrm{B) }  2 - 3x \\leq 1  \\iff  x \\geq \\frac{1}{3}  \\\\
        &                               &&                           && \\textrm{Obě nerovnosti musí plati současně:  } x \\in \\langle \\frac{1}{3}, 1 \\rangle \\\\
        & \\arcsin{\\left(\\textrm{něco}\\right)}   && -1 \\leq \\textrm{něco} \\leq 1      && \\small{\\textrm{Jde o to přesně opsat vše v arkus sinu, a potom řešit dvě nerovnice: }} \\\\
        &                                                      &&                                                 && \\small{\\textrm{A) }  -1 \\leq \\textrm{něco}  \\quad  \\textrm{B) } \\textrm{něco} \\leq 1,}  \\\\
        &                                                      &&                                                 && \\small{\\rightarrow \\textrm{výsledkem je průnik množin získaných v A) a B).}}
        \\end{align*}$$`}
        Arkus kosinus: definiční obor se řeší stejně.
        </p>

        <h2>Tangens</h2>
        <p>
        {`$$\\begin{align*}
        & \\text{tg}\\,\\left(f(x)\\right)  &&  f(x)  \\neq \\frac{\\pi}{2} \\pm k\\pi, \\ \\forall k \\in \\mathbb{N}_{0}   && \\cdots  \\\\
        & \\text{tg}\\,(3x + 2)             &&  3x + 2 \\neq \\frac{\\pi}{2} \\pm k\\pi, \\ \\forall k \\in \\mathbb{N}_{0}   && x \\neq -2 + \\frac{\\pi}{6} \\pm k\\frac{\\pi}{3} \\ \\ \\forall k \\in \\mathbb{N}_{0}  \\\\
        & \\text{tg}\\,(\\textrm{něco})     && \\textrm{něco} \\neq \\frac{\\pi}{2} \\pm k\\pi, \\ \\forall k \\in \\mathbb{N}_{0}   && \\small{\\textrm{Jde o to, přesně opsat vše v tangentu, }} \\\\
        &                                   &&                                                                                                                 && \\small{\\textrm{položit to $ \\neq \\frac{\\pi}{2} \\pm k\\pi, \\ \\forall k \\in \\mathbb{N}_{0}$, a pak rovnici vyřešit.}}
        \\end{align*}$$`}
        Kotangens: definiční obor se řeší analogicky.
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}

