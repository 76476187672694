import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { Path } from '~/paths';

const powerfunc_four = require('../assets/images/powerfunc_four.png');
const powerf_x2 = require('../assets/images/powerf_x2.png');
const powerf_x3 = require('../assets/images/powerf_x3.png');
const powerf_x2_inv = require('../assets/images/powerf_x2_inv.png');
const powerf_x3_inv = require('../assets/images/powerf_x3_inv.png');

export const BasicsPowerFuncsMeta = {
    title: "Mocninné funkce",
    shortTitle: "Mocninné funkce",
    path: Path.basics_power_funcs,
    element: (sectionNumber:string) => <BasicsPowerFuncs sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "powf-table",
    "powf-four-graphs",
    "powf-x2",
    "powf-x3",
    "powf-x-2",
    "powf-x-1",
];


export function BasicsPowerFuncs({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BasicsPowerFuncsMeta.title} TOCSpec={TOCSpec}>

        Ref: <ExternalLink href='https://is.muni.cz/do/rect/el/estud/prif/ps23/metodika_matematiky/web/pages/04_05_mocninne.html'>Učební text MUNI</ExternalLink> i s příklady.
        Ref: <ExternalLink href='https://www.karlin.mff.cuni.cz/~portal/funkce/?page=defmoc'>Portál středoškolské matematiky CUNI</ExternalLink>.

        {/* <p>
        Ref.: Odmaturuj z matematiky 1, vydání 2007, kapitola 3. <br/>
        Ref.: Zlomky na <ExternalLink href="https://www.matweb.cz/zlomky/">matweb</ExternalLink>.
        </p> */}

        <h2 id="powf-table">Co je to za funkce?</h2>

        <p>
        Jsou to funkce typu "x na n-tou". Zde si ukážeme tu variantu, kde n je celé číslo. <br/>
        </p>
        <table className='simple-table simple-table-basic'>
            <thead>
                <tr>
                    <th>n</th>
                    <th>funkce</th>
                </tr>
            </thead>
            <tbody>
                <tr><td>Sudé {"$n > 0$"}</td><td>{"$x^2, x^4, x^6, x^8, \\ldots$"}</td></tr>
                <tr><td>Liché {"$n > 0$"}</td><td>{"$x^3, x^5, x^7, x^9, \\ldots$"}</td></tr>
                <tr><td>Sudé {"$n < 0$"}</td><td>{"$\\frac{1}{x^2}, \\frac{1}{x^4}, \\frac{1}{x^6}, \\frac{1}{x^8}, \\ldots$"}</td></tr>
                <tr><td>Liché {"$n < 0$"}</td><td>{"$\\frac{1}{x}, \\frac{1}{x^3}, \\frac{1}{x^5}, \\frac{1}{x^7}, \\ldots$"}</td></tr>
            </tbody>
        </table>

        <h2 id="powf-four-graphs">Čtyři grafy</h2>

        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={powerfunc_four} style={{width: "80%"}}/>
        </div>
        <p style={{backgroundColor: "yellow"}}>
        Tyto čtyři "tvary" je potřeba si bezpečně pamatovat - bez přemýšlení.
        </p>
        <p>Grafy všech ostatních mocninných funkcí vypadají velmi podobně - dle těchto čtyř "vzorů".
        Čili to, že si pamatujeme tyto čtyři, je vlastně skvělé zjednodušení - nemusíme si jich pamatovat nekonečně mnoho!
        </p>
        <p>
        {"$x^2$"}: Funkce je sudá, konvexní, prochází body {"$(-1,1)$"} a {"$(1,1)$"}. Pro {"$x = 0$"} má hodnotu {"$0$"}, všude jinde je kladná.
        </p>
        <p>
        {"$x^3$"}: Funkce je lichá, prochází body {"$(-1,-1)$"} a {"$(1,1)$"}. Pro {"$x = 0$"} má hodnotu {"$0$"}, a má tam inflexní bod.
        Její derivace je tam nula, což znamená že tam má vodorovnou tečnu (která splývá s osou x). Vlevo od nuly je záporná, vpravo kladná.
        </p>
        <p>
        {"$\\frac{1}{x^2}$"}: Funkce je sudá, prochází body {"$(-1,1)$"} a {"$(1,1)$"}. V {"$x = 0$"} není definovaná, limity z obou stran do 0 jsou nekonečno.
        Funkce je všude kladná.
        </p>
        <p>
        {"$\\frac{1}{x}$"}: Funkce je lichá, prochází body {"$(-1,-1)$"} a {"$(1,1)$"}. V {"$x = 0$"} není definovaná. Vlevo od nuly je záponá, vpravo kladná.
        </p>

        <h2 id="powf-x2">Sudé {"$n > 0$"}: dle vzoru {"$x^2$"}</h2>

        <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
            <img src={powerf_x2} style={{width: "60%"}}/>
        </div>

        <h2 id="powf-x3">Liché {"$n > 0$"}: dle vzoru {"$x^3$"}</h2>

        <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
            <img src={powerf_x3} style={{width: "60%"}}/>
        </div>

        <h2 id="powf-x-2">Sudé {"$n < 0$"}: dle vzoru {"$\\frac{1}{x^2}$"}</h2>

        <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
            <img src={powerf_x2_inv} style={{width: "60%"}}/>
        </div>

        <h2 id="powf-x-1">Liché {"$n < 0$"}: dle vzoru {"$\\frac{1}{x}$"}</h2>

        <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 20}}>
            <img src={powerf_x3_inv} style={{width: "60%"}}/>
        </div>

        <Footer/>
        </ChapterWrapper>
    );
}

