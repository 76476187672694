import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';
import { ExternalLink } from '~/ExternalLink';


export const LimitsMeta = {
    title: "Limity",
    shortTitle: "Limity",
    path: Path.limits,
    element: (sectionNumber:string) => <Limits sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "lim-proper",
    "lim-expr-ndefined",
    "lim-posnegzero",
    "lim-linf",
    "lim-poly-even",
    "lim-poly-odd",
    "lim-ratiof",
    "lim-rules",
    "lim-exp-vs-poly",
    "lim-log-vs-poly",
    "lim-three-famous",
    "lim-composed",
    "lim-substitution",
    "lim-transformations",
    "lim-suppl",
];


export function Limits({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={LimitsMeta.title} TOCSpec={TOCSpec}>

        <p>
        Spousta řešených příkladů je <ExternalLink href={"https://is.muni.cz/do/sci/UMS/el/analyza/pages/limity.html#limita-funkce"}>zde</ExternalLink>.
        Limita na <ExternalLink href={"https://www.matweb.cz/limita-funkce/"}>matweb</ExternalLink>.
        Limita na <ExternalLink href={"https://cs.wikipedia.org/wiki/Limita_funkce"}>Wikipedia</ExternalLink>.
        Řešené příklady na <ExternalLink href={"https://www.karlin.mff.cuni.cz/~portal/limita_a_spojitost/priklady.php?kapitola=limitaKnowHow"}>portálu cuni</ExternalLink>.
        Kniha MU: <ExternalLink href={"https://www.math.muni.cz/~dosla/download/skript.pdf"}>Kapitola 4 a 5</ExternalLink> - detailní vysvětlení, obrázky, řešené příklady.
        VUT FSI: <ExternalLink href={"https://mathonline.fme.vutbr.cz/Limita-a-spojitost-funkce/sc-18-sr-1-a-64/default.aspx"}>Matematika online</ExternalLink>
        {/* Řešené příklady z <ExternalLink href={"https://www.karlin.mff.cuni.cz/~zeleny/fsv/mat1/seminare/tema1-03.pdf"}>Karlovy univerzity</ExternalLink>. */}
        </p>

        {/* <h2>Shrnutí</h2>
        <p>
        | "vlastní/nevlastní" | neurčité výrazy | <br/>
        | limity polynomů | limity rac. lom. funkcí | {"$e^x$"} vs polynom |&nbsp; {"$\\ln{x}$"} vs polynom | 3 známé limity | <br/>
        | pravidla pro počítání limit | jednostranné limity | <br/>
        </p> */}

        <h2 id="lim-proper">Vlastní, nevlastní</h2>

        <p>
        <strong>Vlastní</strong> bod či hodnota je jakékoli konečné reálné číslo (např. 0, -1, 4.2, 1000, ...). <br/>
        <strong>Nevlastní</strong> body či hodnoty jsou: {"$-\\infty$"}, {"$\\infty$"} (které se někdy zapisuje i jako {"$+\\infty$"}).
        </p>

        <p>
        Když řekneme "Funkce {"$f$"} má nevlastní limitu ve vlastním bodě", znamená to např. že {"$\\lim_{x \\to 0} \\frac{1}{x^2} = \\infty$"}.
        Funkce {"$\\frac{1}{x^2}$"} má nevlastní limitu ({"$\\infty$"}) ve vlastním bodě ({"$0$"}).
        </p>

        <p>
        Když řekneme "Funkce {"$f$"} má vlastní limitu v nevlastním bodě", znamená to např. že {"$\\lim_{x \\to \\infty} \\big(3 + \\frac{1}{x}\\big) = 3$"}.
        Funkce {"$3 + \\frac{1}{x}$"} má vlastní limitu ({"$3$"}) v nevlastním bodě ({"$\\infty$"}) (pro {"$x$"} jdoucí do nekonečna).
        </p>


        <h2 id="lim-expr-ndefined">Neurčité výrazy</h2>

        <p>Počítání s nevlastními hodnotami se chová intuitivně, až na <strong><span style={{color: 'red'}}>neurčité výrazy</span></strong>:
        {`$$
            \\color{red}{\\frac{\\infty}{\\infty}, \\ \\frac{0}{0}, \\ 0 \\cdot \\infty, \\ \\infty - \\infty, \\ 0^0, \\ \\infty^0, \\ 1^{\\infty}}
        $$`}
        </p>

        <p>
        Jinak počítáme běžně dle intuice:
        {`$$
            2 \\cdot \\infty = \\infty, \\ (-2) \\cdot \\infty = -\\infty
        $$`}
        {`$$
            \\frac{1}{\\infty} = 0, \\ \\frac{1}{-\\infty} = 0
        $$`}
        {`$$
            \\frac{\\infty}{2} = \\infty, \\ \\frac{-\\infty}{2} = -\\infty,  \\ \\frac{\\infty}{-2} = -\\infty
        $$`}
        {`$$
            \\infty + 1 = \\infty, \\ \\infty - 100 = \\infty, \\ \\infty + \\infty = \\infty, \\\\
        $$`}
        {`$$
            -\\infty - 1 = -\\infty, \\ -\\infty + 100 = -\\infty, \\ -\\infty - \\infty = -\\infty, \\\\
        $$`}
        </p>

        <p>
        Neurčitý výraz nám říká "tudy cesta nevede - je to slepá ulička".
        Např.:
        {`$$
            \\lim_{x \\to \\infty} \\frac{x^2}{x} = \\lim_{x \\to \\infty} x = {\\color{PineGreen}{\\infty}}, \\ \\  \\lim_{x \\to \\infty} \\frac{x}{x^2} = \\lim_{x \\to \\infty} \\frac{1}{x} = {\\color{PineGreen}{0}}, \\ \\ \\lim_{x \\to \\infty} \\frac{2x}{3x} = \\lim_{x \\to \\infty} \\frac{2}{3} = {\\color{PineGreen}{\\frac{2}{3}}},
        $$`}
        ale kdybychom tyto limity počítali jako podíl limit, měli bychom ve všech případech neurčitý výraz {"$\\frac{\\infty}{\\infty}$"}:
        {`$$
            \\lim_{x \\to \\infty} \\frac{g}{h} = \\frac{\\lim_{x \\to \\infty} g}{\\lim_{x \\to \\infty} h} = {\\color{PineGreen}{\\frac{\\infty}{\\infty} = ?}}
        $$`}
        Vidíme, že za výrazem {"$\\frac{\\infty}{\\infty}$"} se v jednom případě skrývá {"$\\infty$"}, v druhém {"$0$"}, a ve třetím {"$\\frac{2}{3}$"},
        Výraz {"$\\frac{\\infty}{\\infty}$"} zatemňuje skutečný výsledek, a neposkytuje žádnou cestu, jak se k němu dobrat. Je to slepá ulička.
        </p>


        <h2 id="lim-posnegzero">Kladná a záporná nula</h2>

        <p>
        Výraz, kde se dělí nulou, je nedefinovaný:
        {`$$
            \\frac{1}{0} =?, \\ \\frac{\\infty}{0} =?
        $$`}

        V kontextu počítání limit lze ale dělit kladnou a zápornou nulou:
        {`$$
            \\frac{1}{+0} = \\infty, \\ \\frac{1}{-0} = -\\infty, \\ \\frac{\\infty}{+0} = \\infty, \\ \\frac{\\infty}{-0} = -\\infty, \\ \\frac{-\\infty}{+0} = -\\infty, \\ \\frac{-\\infty}{-0} = \\infty
        $$`}
        </p>

        <p>
        Jedná se o šikovnou pomůcku ve značení. Kladná nula je {"$+0$"}, záporná nula je {"$-0$"}.
        </p>

        <p>
        Proč to děláme? Limita:
        {`$$
            \\lim_{x \\to 0} \\frac{1}{x} = \\infty
        $$`}
        neexistuje, protože limita zprava je {"$+\\infty$"} a limita zleva je {"$-\\infty$"}:
        {`$$
            \\text{(zprava): } \\lim_{x \\to 0^+} \\frac{1}{x} = +\\infty
        $$`}
        Čtu: Blížím se s {"$x$"} k nule zprava. Hodnota {"$x$"} je kladná a stále menší a menší, ale pořád kladná.
        Hodnota {"$\\frac{1}{x}$"} je tedy kladná a stále větší a větší, až se blíží k {"$+\\infty$"}.
        Např. {"$x = 0.01$"}: {"$\\frac{1}{x} = 100$"}. {"$x = 0.0001$"}: {"$\\frac{1}{x} = 10000$"}. Atd.
        {`$$
            \\text{(zleva): } \\lim_{x \\to 0^-} \\frac{1}{x} = -\\infty
        $$`}
        Čtu: Blížím se s {"$x$"} k nule zleva. Hodnota {"$x$"} je záporná a v absolutní hodnotě stále menší a menší (bližší nule), ale pořád záporná.
        Hodnota {"$\\frac{1}{x}$"} je tedy záporná a v absolutní hodnotě stále větší a větší, až se blíží k {"$-\\infty$"}.
        Např. {"$x = - 0.01$"}: {"$\\frac{1}{x} = -100$"}. {"$x = -0.0001$"}: {"$\\frac{1}{x} = -10000$"}. Atd.
        </p>

        <p>
        Abychom tento argument nemuseli opakovaně rozepisovat, zavedeme zkratky: {"$+0$"} a {"$-0$"}:
        {`$$\\begin{align*}
            \\lim_{x \\to 0^+} \\frac{1}{x} &= \\frac{1}{+0} = +\\infty, \\\\
            \\lim_{x \\to 0^-} \\frac{1}{x} &= \\frac{1}{-0} = -\\infty.
        \\end{align*}$$`}
        </p>

        <p>
        Pozor: Výraz {"$\\frac{0}{0}$"} je stále neurčitý, i ve variantách: {"$\\frac{0}{+0}$"}, {"$\\frac{0}{-0}$"}, {"$\\frac{+0}{+0}$"}, {"$\\frac{-0}{-0}$"}, {"$\\frac{-0}{+0}$"}... (!).
        </p>

        <p>
            <span style={{color: 'red'}}>Pozor: Výrazy typu {"$(+0) + (-0)$"},  {"$(+0) - (+0)$"}, {"$(-0) - (-0)$"}, ... jsou neurčité, pokud bychom jimi chtěli dělit.</span>&nbsp;
            Nevíme totiž, která nula je větší, čili jestli nám vyjde kladná nebo záporná nula (!). <br/>
            (Pro srovnání, výraz typu {"$+0 + (+0)$"} je ok, je to {"$+0$"}.) Ukázka:
        {`$$\\begin{align*}
            \\lim_{x \\to 0^+} \\frac{1}{x^5 + x^2 - x} &= \\frac{1}{(+0)^5 + (+0)^2 - (+0)} = \\frac{1}{(+0) + (+0) - (+0)} = ? \\\\
            \\lim_{x \\to 0^+} \\frac{1}{x^5 + x^2 - x} &= \\left(\\lim_{x \\to 0^+} \\frac{1}{x}\\right) \\cdot \\left(\\lim_{x \\to 0^+} \\frac{1}{x^4 + x - 1}\\right) = \\frac{1}{+0} \\cdot  \\frac{1}{(-1)} = \\infty \\cdot (-1) = -\\infty
        \\end{align*}$$`}
        U otazníku jsem si nemohli být jistí, jestli ve jmenovateli bude kladná nebo záporná nula, tedy jestli limita celkově vyjde {"$\\infty$"} nebo {"$-\\infty$"}.
        Korektním postupem jsme pak došli k tomu, že dole je záporná nula.
        </p>


        <h2 id="lim-linf">Limita lineární funkce</h2>

        <p>
        {`$$
            f(x) = {\\color{Purple}{\\mathbf{a}}}x + b
        $$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a > 0}}$"}, pak přímka je rostoucí, a tedy:
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= {\\color{RoyalBlue}{-\\infty}}, & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= {\\color{red}{\\infty}} \\\\
            \\text{Př.: }  f(x) &= 2x + 3: & \\lim_{x \\to -\\infty} (2x + 3) &= -\\infty, & \\lim_{x \\to \\infty} (2x + 3) &= \\infty
        \\end{align*}$$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a < 0}}$"}, pak přímka je klesající, a tedy:
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= {\\color{red}{\\infty}}, & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= {\\color{RoyalBlue}{-\\infty}} \\\\
            \\text{Př.: }  f(x) &= -x + 1: & \\lim_{x \\to -\\infty} (-x + 1) &= \\infty, & \\lim_{x \\to \\infty} (-x + 1) &= -\\infty
        \\end{align*}$$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a = 0}}$"}, pak přímka je konstantní, a tedy:
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= b, & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= b \\\\
            \\text{Př.: } f(x) &= 5: & \\lim_{x \\to \\infty} 5 &= 5, & \\lim_{x \\to -\\infty} 5 &= 5
        \\end{align*}$$`}
        </p>

        <h2 id="lim-poly-even">Limity polynomů sudého stupně</h2>

        <p>
        Polynom sudého stupně je:
        {`$$
            f(x) = {\\color{Purple}{\\mathbf{a}}} x^{\\text{sudé kladné číslo}} + \\text{ocas},
        $$`}
        tj. např.:
        {`$$\\begin{align*}
            f(x) &= 3x^2 + 2x + 1, \\\\
            f(x) &= -x^4 + 3x^3 - 1, \\\\
            f(x) &= 2x^6 - 3x^2 + x.
        \\end{align*}$$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a > 0}}$"}, pak polynom 'vlevo přichází shora' a 'vpravo odchází nahoru':
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= {\\color{red}{\\infty}} & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= {\\color{red}{\\infty}} \\\\
            \\text{Př.: } f(x) &= 3x^2 + 2x + 1: & \\lim_{x \\to -\\infty} (3x^2 + 2x + 1) &= \\infty & \\lim_{x \\to \\infty} (3x^2 + 2x + 1) &= \\infty
        \\end{align*}$$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a < 0}}$"}, pak polynom 'vlevo přichází zdola' a 'vpravo odchází dolů':
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= {\\color{RoyalBlue}{-\\infty}} & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= \\color{RoyalBlue}{-\\infty} \\\\
            \\text{Př.: } f(x) &= -x^4 + 3x^3 - 1: & \\lim_{x \\to -\\infty} (-x^4 + 3x^3 - 1) &= -\\infty & \\lim_{x \\to \\infty} (-x^4 + 3x^3 - 1) &= -\\infty
        \\end{align*}$$`}
        </p>

        <h2 id="lim-poly-odd">Limity polynomů lichého stupně</h2>

        <p>
        Polynom lichého stupně je:
        {`$$
            f(x) = {\\color{Purple}{\\mathbf{a}}} x^{\\text{liché kladné číslo}} + \\text{ocas},
        $$`}
        tj. např.:
        {`$$\\begin{align*}
            f(x) &= 3x^3 + 2x^2 + 1, \\\\
            f(x) &= -x^5 + 3x - 1, \\\\
            f(x) &= 2x^7 - 3x^4 + x^2.
        \\end{align*}$$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a > 0}}$"}, pak polynom 'vlevo přichází zdola' a 'vpravo odchází nahoru':
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= {\\color{RoyalBlue}{-\\infty}} & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= {\\color{red}{\\infty}} \\\\
            \\text{Př.: } f(x) &= 3x^3 + 2x^2 + 1: & \\lim_{x \\to -\\infty} (3x^3 + 2x^2 + 1) &= -\\infty & \\lim_{x \\to \\infty} (3x^3 + 2x^2 + 1) &= \\infty
        \\end{align*}$$`}
        </p>

        <p>
        Je-li {"$\\color{Purple}{\\mathbf{a < 0}}$"}, pak polynom 'vlevo přichází shora' a 'vpravo odchází dolů':
        {`$$\\begin{align*}
            && \\lim_{x \\to \\color{RoyalBlue}{-\\infty}} f(x) &= {\\color{red}{\\infty}} & \\lim_{x \\to \\color{red}{\\infty}} f(x) &= \\color{RoyalBlue}{-\\infty} \\\\
            \\text{Př.: } f(x) &= -x^5 + 3x - 1: & \\lim_{x \\to -\\infty} (-x^5 + 3x - 1) &= \\infty & \\lim_{x \\to \\infty} (-x^5 + 3x - 1) &= -\\infty
        \\end{align*}$$`}
        </p>

        <p>
        Jak je vidět, na ocasu nezáleží - záleží jen na lichosti/sudosti stupně polynomu a na znaménku koeficientu {"$a$"}.
        </p>

        <h2 id="lim-ratiof">Limity rac. lom. funkcí</h2>

        <p>
        Racionální lomená funkce je podíl dvou polynomů:
        {`$$
            f(x) = \\frac{p(x)}{q(x)}.
        $$`}
        </p>

        <p>
        Postupujeme tak, že všechny členy zlomku podělíme nejvyšší mocninou {"$x^n$"}, jakou najdeme <strong>ve jmenovateli</strong>.
        </p>

        <p>
        Příklad 1:
        {`$$
            \\frac{3x^2 + 2x + 1}{x^3 - 1} = \\mid \\text{nejvyšší je } x^3 \\mid  = \\frac{\\frac{3x^2}{x^3} + \\frac{2x}{x^3} + \\frac{1}{x^3}}{\\frac{x^3}{x^3} - \\frac{1}{x^3}} =
            \\frac{\\frac{3}{x} + \\frac{2}{x^2} + \\frac{1}{x^3}}{1 - \\frac{1}{x^3}}
        $$`}

        Teď počítáme limitu:
        {`$$
            \\lim_{x \\to \\infty} \\frac{\\frac{3}{x} + \\frac{2}{x^2} + \\frac{1}{x^3}}{1 - \\frac{1}{x^3}} = \\frac{0 + 0 + 0}{1 - 0} = 0.
        $$`}

        Příklad 2:
        {`$$
            \\frac{3x^2 + 2x + 1}{-5x^2 + x} = \\mid \\text{nejvyšší je } x^2 \\mid  = \\frac{\\frac{3x^2}{x^2} + \\frac{2x}{x^2} + \\frac{1}{x^2}}{\\frac{-5x^2}{x^2} + \\frac{x}{x^2}} =
            \\frac{3 + \\frac{2}{x} + \\frac{1}{x^2}}{-5 + \\frac{1}{x}}
        $$`}
        Teď počítáme limitu:
        {`$$
            \\lim_{x \\to \\infty} \\frac{3 + \\frac{2}{x} + \\frac{1}{x^2}}{-5 + \\frac{1}{x}} = \\frac{3 + 0 + 0}{-5 + 0} = -\\frac{3}{5}.
        $$`}

        Příklad 3:
        {`$$
            \\frac{3x^2 + 2x + 1}{-x + 1} = \\mid \\text{nejvyšší je } x \\mid  = \\frac{\\frac{3x^2}{x} + \\frac{2x}{x} + \\frac{1}{x}}{\\frac{-x}{x} + \\frac{1}{x}} =
              \\frac{3x + 2 + \\frac{1}{x}}{-1 + \\frac{1}{x}}
        $$`}
        Teď počítáme limitu:
        {`$$
            \\lim_{x \\to \\infty} \\frac{3x + 2 + \\frac{1}{x}}{-1 + \\frac{1}{x}} = \\frac{\\infty + 2 + 0}{-1 + 0} = \\frac{\\infty}{-1} = -\\infty.
        $$`}
        </p>

        <h2 id="lim-rules">Pravidla počítání s limitami</h2>

        <p>
        "Konstantu lze vytknout před limitu":
        {`$$
            \\lim_{x \\to a} c \\cdot f(x) = c \\cdot \\lim_{x \\to a} f(x)
        $$`}
        </p>

        <p>
        {`$$
            \\lim_{x \\to 0} 3\\cdot \\Big(\\frac{1}{2}\\Big)^x  = 3 \\cdot \\lim_{x \\to 0} \\Big(\\frac{1}{2}\\Big)^x = 3 \\cdot 0 = 0
        $$`}
        </p>

        <p>
        Praktická aplikace je vythnutí znaménka minus (tj. vytknutí {"$(-1)$"}) před limitu:
        {`$$
            \\lim_{x \\to 0} \\frac{-x + 1}{2 + 3x}  = - \\lim_{x \\to 0} \\frac{x - 1}{2 + 3x} = - \\frac{1}{3}
        $$`}
        </p>

        <p>
        "Limita součtu je součet limit":
        {`$$
            \\lim_{x \\to a} \\Big(f(x) + g(x)\\Big) = \\lim_{x \\to a} f(x) + \\lim_{x \\to a} g(x)
        $$`}
        ... za předpokladu, že obě limity na pravé straně existují, a výpočet nevede na neurčitý výraz.
        </p>

        <p>
        {`$$\\begin{align*}
            \\lim_{x \\to \\infty} \\Big( \\frac{1}{x} + \\frac{1}{x^2} \\Big) &= \\lim_{x \\to \\infty} \\frac{1}{x} + \\lim_{x \\to \\infty} \\frac{1}{x^2} = 0 + 0 = 0 \ \ \\text{[ OK]} \\\\
            \\lim_{x \\to \\infty} \\Big( \\frac{1}{x} + x \\Big) &= \\lim_{x \\to \\infty} \\frac{1}{x} + \\lim_{x \\to \\infty} x = 0 + \\infty = \\infty \\ \\text{ [OK]} \\\\
            \\lim_{x \\to \\infty} \\Big( x^2 - \\ln x \\Big) &= \\lim_{x \\to \\infty} x^2 - \\lim_{x \\to \\infty} \\ln(x) = \\infty - \\infty = ? \\text{[neurčitý výraz - tudy cesta nevede]}
        \\end{align*}$$`}
        </p>

        <p>
        "Limita součinu je součin limit":
        {`$$
            \\lim_{x \\to a} \\Big(f(x) \\cdot g(x)\\Big) = \\lim_{x \\to a} f(x) \\cdot \\lim_{x \\to a} g(x)
        $$`}
        ... za předpokladu, že obě limity na pravé straně existují, a výpočet nevede na neurčitý výraz.
        </p>

        <p>
        {`$$\\begin{align*}
            \\lim_{x \\to \\infty} \\Big( x \\cdot \\text{arctg}(x) \\Big) &= \\lim_{x \\to \\infty} x \\cdot \\lim_{x \\to \\infty} \\text{arctg}(x) = \\infty \\cdot \\frac{\\pi}{2} = \\infty \\ \\text{ [OK]} \\\\
            \\lim_{x \\to \\infty} \\Big[ \\frac{x}{x+1}(2 - e^{-x}) \\Big] &= \\lim_{x \\to \\infty} \\frac{x}{x+1} \\cdot \\lim_{x \\to \\infty} (2 - e^{-x}) = 1 \\cdot 2 = 2 \\ \\text{ [OK]} \\\\
            \\lim_{x \\to \\infty} \\Big(  x e^{-x} \\Big) &= \\lim_{x \\to \\infty} x \\cdot \\lim_{x \\to \\infty} e^{-x} = \\infty \\cdot 0 = ? \\text{[neurčitý výraz - tudy cesta nevede]}
        \\end{align*}$$`}
        </p>

        <p>
        "Limita podílu je podíl limit":
        {`$$
            \\lim_{x \\to a} \\frac{f(x)}{g(x)} = \\frac{\\lim_{x \\to a} f(x)}{\\lim_{x \\to a} g(x)}
        $$`}
        ... za předpokladu, že obě limity na pravé straně existují, výpočet nevede na neurčitý výraz, a jmenovatel není nula: {"$\\lim_{x \\to a} g(x) \\neq 0$"}.
        </p>

        <p>
        {`$$
            \\lim_{x \\to \\infty} \\frac{2x + 1}{3 - x} = \\lim_{x \\to \\infty} \\frac{2 + \\frac{1}{x}}{\\frac{3}{x} - 1} = \\frac{\\lim_{x \\to \\infty} (2 + \\frac{1}{x})}{\\lim_{x \\to \\infty} (\\frac{3}{x} - 1)} = \\frac{2 + 0}{0 - 1} = -2 \\ \\text{ [OK]} \\\\
        $$`}

        {`$$
            \\lim_{x \\to \\infty} \\frac{2x + 1}{\\ln x} = \\frac{\\lim_{x \\to \\infty} (2x +1)}{\\lim_{x \\to \\infty} \\ln(x)} = \\frac{\\infty}{\\infty} = ? \ \ \\text{[neurčitý výraz - tudy cesta nevede]}
        $$`}
        </p>

        <h2 id="lim-exp-vs-poly">Exponenciála vs polynom</h2>

        <p>
        Fakt: Exponenciála roste rychleji než polynom libovoného stupně.
        {`$$\\begin{align*}
            \\lim_{x \\to \\infty} \\frac{e^x}{x^n} &= \\infty  \\quad \\text{pro libovolně velký exponent n}, \\\\
            \\lim_{x \\to \\infty} \\frac{x^n}{e^x} &= 0 \\quad \\text{pro libovolně velký exponent n}.
        \\end{align*}$$`}
        </p>

        <h2 id="lim-log-vs-poly">Logaritmus vs polynom</h2>

        <p>
        Fakt: Logaritmus roste pomaleji než {"$x$"}, a tedy než polynom libovoného stupně {"$\\geq 1$"}.
        {`$$\\begin{align*}
            \\lim_{x \\to \\infty} \\frac{\\ln x}{x} &= 0, \\\\
            \\lim_{x \\to \\infty} \\frac{x}{\\ln x} &= \\infty.
        \\end{align*}$$`}
        </p>

        <h2 id="lim-three-famous">Tři známé limity</h2>

        <p>
        {`$$\\begin{align*}
            \\lim_{x \\to 0} \\frac{\\sin x}{x} &= 1, \\\\
            \\lim_{x \\to 0} \\frac{e^x - 1}{x} &= 1, \\\\
            \\lim_{x \\to 0} \\frac{\\ln(x+1)}{x} &= 1.
        \\end{align*}$$`}
        </p>

        <p>Vykreslete si grafy!</p>


        <h2 id="lim-composed">S limitou do funkce</h2>

        <p>
        S limitou můžeme 'vlézt' do <strong>spojité</strong> funkce, pokud limita vychází konečně, a tedy ji lze dosadit:
        {`$$\\begin{align*}
        \\lim_{x \\to a} f(g(x)) &= f\\big(\\lim_{x \\to a} g(x)\\big), \\\\
            \\lim_{x \\to \\infty} e^{\\frac{1}{x}} &= e^{\\lim_{x \\to \\infty} \\frac{1}{x}} = e^0 = 1.
        \\end{align*}$$`}
        Toho se s radostí využívá při výpočtu limity
        {`$$\\begin{align*}
            \\lim_{x \\to a} f(x)^{g(x)} & = \\lim_{x \\to a} e^{\\ln f(x)^{g(x)}} = \\lim_{x \\to a} e^{g(x) \\ln f(x)} = e^{\\lim_{x \\to a} g(x) \\ln f(x)}.
        \\end{align*}$$`}
        </p>

        <p>
        Např.:
        {`$$
            \\lim_{x \\to \\infty} x^{\\frac{1}{x}} = e^{\\lim_{x \\to \\infty} \\frac{1}{x} \\ln x} = e^{\\lim_{x \\to \\infty} \\frac{\\ln x}{x}} = e^0 = 1.
        $$`}
        </p>


        <h2 id="lim-substitution">Substituce</h2>

        <p>
        Podobně jako u integrálu, limitu můžeme počítat substitucí. Substituujeme funkcí, která je <strong>prostá</strong>.
        {`$$
            \\lim_{x \\to 0} \\frac{\\sin 2x}{x} = ?
        $$`}
        Substituce: {"$t(x) = 2x$"}, tedy {"$x(t) = \\frac{t}{2}$"}.
        Když {"$x \\to 0$"}, pak také {"$t \\to 0$"}.
        {`$$
            \\lim_{x \\to 0} \\frac{\\sin 2x}{x} = \\lim_{t \\to 0} \\frac{\\sin t}{\\frac{t}{2}} = 2 \\lim_{t \\to 0} \\frac{\\sin t}{t} = 2 \\cdot 1 = 2.
        $$`}
        </p>


        <h2 id="lim-transformations">Neurčité výrazy - převody</h2>

        <p>Neurčité výrazy se mezi sebou někdy dají převádět:
        {`$$
            [0 \\cdot \\infty]: \\ a \\cdot b = \\frac{b}{\\frac{1}{a}} : \\Big[\\frac{\\infty}{\\infty}\\Big]
        $$`}

        {`$$
            [0 \\cdot \\infty]: \\ a \\cdot b = \\frac{a}{\\frac{1}{b}} : \\Big[\\frac{0}{0}\\Big]
        $$`}

        {`$$
            [{\\infty} - {\\infty}]: \\ a - b = \\frac{1}{\\frac{1}{a}} - \\frac{1}{\\frac{1}{b}} = \\frac{\\frac{1}{b} - \\frac{1}{a}}{\\frac{1}{ab}} \\ : \\Big[\\frac{0}{0}\\Big]
        $$`}
        </p>

        <h2 id="lim-suppl">Neurčité výrazy - dodatek</h2>

        <p>
        {"${\\infty} - {\\infty}$"}:
        {`$$\\begin{align*}
            \\lim_{x \\to \\infty} (x^2 - x) &= \\infty, \\\\
            \\lim_{x \\to \\infty} (x - x^2) &= -\\infty, \\\\
        \\end{align*}$$`}
        {`$$\\begin{align*}
            \\lim_{x \\to \\infty} \\sqrt{x} - \\sqrt{x - \\sqrt{x}} & = \\lim_{x \\to \\infty} \\frac{\\Big(\\sqrt{x} - \\sqrt{x - \\sqrt{x}}\\Big)\\Big(\\sqrt{x} + \\sqrt{x - \\sqrt{x}}\\Big)}{\\sqrt{x} + \\sqrt{x - \\sqrt{x}}} = \\\\
            & = \\lim_{x \\to \\infty} \\frac{x - (x - \\sqrt{x})}{\\sqrt{x} + \\sqrt{x - \\sqrt{x}}} = \\lim_{x \\to \\infty} \\frac{\\sqrt{x}}{\\sqrt{x} + \\sqrt{x - \\sqrt{x}}} = \\\\
            & = \\lim_{x \\to \\infty} \\frac{1}{1 + \\sqrt{1 - \\frac{1}{\\sqrt{x}}}} = \\lim_{x \\to \\infty} \\frac{1}{1 + \\sqrt{1 - 0}} = \\frac{1}{2}
        \\end{align*}$$`}
        Ve všech třech případech jsme měli na první pohled neurčitý výraz typu {"$\\infty - \\infty$"}, za kterým se po výpočtu skrývaly hodnoty {"$\\infty$"}, {"$-\\infty$"} a {"$\\frac{1}{2}$"}.
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}

