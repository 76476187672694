import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';


export const SectionProbabilityMeta = {
    title: "Pravděpodobnost a statistika",
    shortTitle: "Pravděpodobnost",
    path: Path.section_probability,
    element: (sectionNumber:string) => <SectionProbability sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}


export function SectionProbability({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SectionProbabilityMeta.title}>
        <p>
        Tato kapitola poskytuje minimalistický přehled některých témat z pravděpodobnosti a statistiky.
        </p>

        </ChapterWrapper>
    );
}

