import React from 'react';

import Footer from '~/Footer';
import { ChapterWrapper } from '~/ChapterWrapper';
import { Path } from '~/paths';

const cv1_znamenka_soucin = require('../assets/images/cv1_znamenka_soucin.png');
const cv1_znamenka_podil = require('../assets/images/cv1_znamenka_podil.png');


export const SignOfExpressionMeta = {
    title: "Znaménko funkcí",
    shortTitle: "Znaménko funkcí",
    path: Path.sign_of_expr,
    element: (sectionNumber:string) => <SignOfExpression sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
];


export function SignOfExpression({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={SignOfExpressionMeta.title} TOCSpec={TOCSpec}>



        <p>
        Řešení nerovnic {"$f(x) \\leq 0$"}, {"$f(x) < 0$"}, {"$f(x) \\geq 0$"}, {"$f(x) > 0$"} lze
        často provést určením intervalů, na kterých je funkce kladná nebo záporná.
        Zajímá nás tedy znaménko funkce (funkční hodnoty) {"$f(x)$"}.
        </p>

        <h2>Součin dvou čísel</h2>
        <p>
        {`$$\\begin{align*}
        & + . +  = +    && \\textrm{kladné krát kladné je kladné} \\\\
        &  - . -  = +   && \\textrm{záporné krát záporné je kladné} \\\\
        & + . -  = -    && \\textrm{záporné krát kladné je záporné} \\\\
        & - . +  = -    && \\textrm{kladné krát záporné je záporné} \\\\
        \\end{align*}$$`}

        Součin více čísel - příklad:  {"$+ . - . - = + . (- . - ) = + . + = +$"}. <br/>
        Počet záporných členů je lichý {"$\\rightarrow$"} výsledek je záporný. <br/>
        Počet záporných členů je sudý {"$\\rightarrow$"} výsledek je kladný. (Nulu bereme jako sudou.)
        </p>


        <h2>Podíl dvou čísel</h2>
        <p>
        {`$$\\begin{align*}
        & \\frac{+}{+}  = +  && \\textrm{kladné děleno kladným je kladné} \\\\
        & \\frac{-}{-}  = +   && \\textrm{záporné děleno záporným je kladné} \\\\
        & \\frac{+}{-}  = -   && \\textrm{kladné děleno záporným je záporné} \\\\
        & \\frac{-}{+}  = -   && \\textrm{záporné děleno kladným je záporné} \\\\
        \\end{align*}$$`}
        </p>

        <h2>Kombinované případy</h2>
        <p>
        Př.: {"$ \\frac{+.-.+}{-.+.-.+} = \\frac{-}{+} = -$"}
        </p>

        <h2>Znaménko polynomů</h2>
        <p>
        Př.: Najít intervaly, kde polynom {"$f(x) = x^2 - x + 2$"} je kladný a kde záporný. <br/>

        Rozložíme na součin kořenových činitelů: {"$x^2 - x - 2 = (x + 1)(x - 2)$"}. <br/>
        Kořeny jsou {"$x_1 = -1$"} a {"$x_2 = 2$"}.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={cv1_znamenka_soucin} style={{width: "55%"}}/>
        </div>

        <h2>Znaménko racionální lomené funkce</h2>
        <p>
        Př.: Najít intervaly, kde funckce {"$f(x) = \\frac{x-3}{x^2 - x - 2}$"} je kladná a kde záporná.<br/>
        Rozložíme čitatel i jmenovatel na součin kořenových činitelů. Kořeny jsou {"$x_1 = -1$"} a {"$x_2 = 2$"}, {"$x_3 = 3$"}.
        </p>
        <div style={{textAlign: "center", paddingBottom: 10}}>
            <img src={cv1_znamenka_podil} style={{width: "63%"}}/>
        </div>

        <h2>Znaménko vždy nezáporné</h2>
        <p>
        {"$x^2$"} je vždy nezáporné.<br/>
        {"$e^{x}$"}, {"$e^{-x}$"} je vždy kladné.<br/>
        </p>
        <Footer/>
        </ChapterWrapper>
    );
}

