import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';


export const PolynomialFactorizationMeta = {
    title: "Rozklad polynomů",
    shortTitle: "Rozklad polynomů",
    path: Path.polynomial_factorization,
    element: (sectionNumber:string) => <PolynomialFactorization sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [

];


export function PolynomialFactorization({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={PolynomialFactorizationMeta.title} TOCSpec={TOCSpec}>

        <p>Rozklad polynomu je převedení na součin závorek, ve kterých jsou co nejjednodušší polynomy:
        {`$$
            x^2 - 3x + 2 = (x - 1)(x - 2) \\qquad \\qquad x^3 + x^2 = (x^2)(x + 1)
        $$`}
        </p>
        <p>
        Teorie říká, že rozklad vždy existuje, a jaké má vlastnosti. Postupy, jak ho najít, jsou ale omezené.
        </p>

        <p>Zásadní je fakt [E], který se používá v rozkladu na parciální zlomky. Dostaneme se k němu přes pár mezikroků.</p>

        <p><strong>[A]</strong>&nbsp;Polynom <strong>stupně {"$\\mathbf{n}$"}</strong> má v komplexním oboru vždy rozklad na součin <strong>{"$\\mathbf{n}$"} kořenových činitelů</strong> ("lineárních závorek"):
        {`$$
            x^n + a_{n-1}x^{n-1} + \\ldots + a_1x + a_0 = (x - x_1)(x - x_2)\\ldots(x - x_n)
        $$`}
        Některé z ale kořenů mohou být komplexní, např. <br/>
        {`$$\\begin{align*}
            x^3 + x & = x(x - i)(x + i) \\\\
            x^2 + x + 1 & = \\left(x - \\frac{1}{2} + \\frac{\\sqrt{3}}{2}i\\right)\\left(x - \\frac{1}{2} - \\frac{\\sqrt{3}}{2}i\\right)
        \\end{align*}$$`}
        Pokud toto nastane, pak se tyto kořeny vždy vyskytují v komplexně sdružených dvojicích: {"$x_1 = a + bi, x_2 = a - bi$"}:
        {`$$\\begin{align*}
            i, & \\ -i \\\\
            \\frac{1}{2} + \\frac{\\sqrt{3}}{2}i, \\ & \\frac{1}{2} - \\frac{\\sqrt{3}}{2}i
        \\end{align*}$$`}
        V reálném oboru takovou dvojici závorek <strong>nerozkládáme</strong> - ponecháváme nerozložený <strong>kvadratický člen</strong>:
        {`$$\\begin{align*}
            x^3 + x & = x(x^2 + 1) \\\\
            x^2 + x + 1 & = x^2 + x + 1 \\ \\text{ (beze změny - nelze rozložit)}
        \\end{align*}$$`}
        </p>

        <p><strong>[B]</strong>&nbsp;Kořeny se v rozkladu mohou <strong>opakovat</strong>, např.:
        {`$$
            x^2 - 2x + 1 = (x - 1)(x - 1) = (x - 1)^2
        $$`}
        Zde říkáme, že kořen 1 má <strong>násobnost</strong> 2. Kořen 1 je dvojnásobný.
        </p>

        <p><strong>[C]</strong>&nbsp;
        Provedení rozkladu polynomů je obecně těžká věc - umíme efektivně rozložit jen polynomy malých stupňů, nebo se speciálními tvary:
        </p>
        <table className="simple-table">
            <thead>
                <tr>
                    <th>Stupeň</th>
                    <th>Rozklad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>2 (kvadratický)</td>
                    <td>Diskriminant {"$D$"}, {"$x_{1,2} = \\frac{-b \\pm \\sqrt{D}}{2a}$"}</td>
                </tr>
                <tr>
                    <td>3 (kubický)</td>
                    <td>Cardanovy vzorce; neučí se, dají se dohledat</td>
                </tr>
                <tr>
                    <td>4 (kvartický)</td>
                    <td>Cardanovy vzorce; neučí se, dají se dohledat</td>
                </tr>
                <tr>
                    <td>5 a vyšší</td>
                    <td>Neexistuje (z principu) obecný vzorec pro kořeny; řeší se numericky.</td>
                </tr>
            </tbody>
        </table>

        <p>Některé vyšší polynomy lze rozložit, protože je vidět že jsou to speciální případy - viz "Vzorce užitečné pro rozklad" dole. Např.:
        {`$$
            x^6 - 1 = (x^3 - 1)(x^3 + 1) = (x - 1)(x^2 + x + 1)(x + 1)(x^2 - x + 1)
        $$`}
        </p>

        <p>
        <strong>[D]</strong>&nbsp;Jakmile najdeme alespoň jeden kořen {"$a$"}, můžeme polynom podělit lineárním polynomem {"$(x - a)$"}. (Zbytek po dělení je zde z logiky věci nula.)
        Tím snížíme stupeň nerolozložené části polynomu o jedna - a můžeme pokračovat v rozkladu. Např. v následujícím příkladu si můžeme všimnout,
        že číslo 1 je kořenem (dosadíme za {"$x$"} 1 a dostaneme 0):
        {`$$\\begin{align*}
            x^3 + 3x^2 + 2x - 6 &=  \\ ? \\\\
            & \\\\
            (x^3 + 3x^2 + 2x - 6) &: {\\textcolor{blue}{(x - 1)}} = {\\textcolor{green}{x^2 + 4x + 6}} \\\\
            & \\\\
            x^3 + 3x^2 + 2x - 6 &= {\\textcolor{blue}{(x - 1)}}{\\textcolor{green}{(x^2 + 4x + 6)}} = {\\textcolor{blue}{(x - 1)}}{\\textcolor{green}{(x - 2)(x - 3)}}
        \\end{align*}$$`}
        </p>

        <p>
        <strong>[E]</strong>&nbsp;Když odhlédneme od toho, jak rozklad technicky provést, a podíváme se jen na výsledek, pak v rozkladu polynomu budou typově tyto členy:
        </p>

        <table className="simple-table">
            <thead>
                <tr>
                    <th>Typ členu</th>
                    <th>Popis</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"$(x - a)$"}</td>
                    <td>Lineární člen - jednoduchý kořen {"$a$"}</td>
                </tr>
                <tr>
                    <td>{"$(x - a)^n$"}</td>
                    <td>Lineární člen na n-tou - násobný kořen {"$a$"}</td>
                </tr>
                <tr>
                    <td>{"$(x^2 + bx + c)$"}</td>
                    <td>Nerozložitelný kvadratický člen</td>
                </tr>
                <tr>
                    <td>{"$(x^2 + bx + c)^n$"}</td>
                    <td>Nerozložitelný kvadratický člen na n-tou</td>
                </tr>
            </tbody>
        </table>

        <h2>Vzorce užitečné pro rozklad</h2>
        <p>
        {`$$\\begin{align*}
            a^2 - b^2 & = (a - b)(a + b) \\\\
            a^2 + 2ab + b^2 & = (a + b)^2 \\\\
            a^2 - 2ab + b^2 & = (a - b)^2 \\\\
            & \\\\
            a^3 - b^3 & = (a - b)(a^2 + ab + b^2) \\\\
            a^3 + b^3 & = (a + b)(a^2 - ab + b^2) \\\\
            a^3 + 3a^2b + 3ab^2 + b^3 & = (a+b)^3  \\\\
            a^3 - 3a^2b + 3ab^2 - b^3 & = (a-b)^3 \\\\
            & \\\\
            a^n - b^n & = (a - b)(a^{n-1} + a^{n-2}b + a^{n-3}b^2 + \\cdots + b^{n-1}) \\\\
            a^n + b^n & = \\mid \\text{pouze pro liché n} \\mid = (a + b)(a^{n-1} - a^{n-2}b + a^{n-3}b^2 - \\cdots + b^{n-1}) \\\\
            \\sum \\limits_{k=0}^{n} \\binom{n}{k}a^{n-k}b^k & = (a+b)^n  \\\\
            \\sum \\limits_{k=0}^{n} (-1)^k \\binom{n}{k}a^{n-k}b^k & = (a-b)^n  \\\\
        \\end{align*}$$`}
        </p>

        <Footer/>
        </ChapterWrapper>
    );
}
