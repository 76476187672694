import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import Footer from '~/Footer';
import { Path } from '~/paths';

const function_zoo = require('../assets/images/function_zoo.png');


export const FunctionZooMeta = {
    title: "Zoo funkcí",
    shortTitle: "Zoo funkcí",
    path: Path.function_zoo,
    element: (sectionNumber:string) => <FunctionZoo sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [

];


export function FunctionZoo({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={FunctionZooMeta.title} TOCSpec={TOCSpec}>

        <div style={{textAlign: "center", paddingBottom: 10, paddingTop: 10}}>
            <img src={function_zoo} style={{width: "95%"}}/>
        </div>

        <h2 id="fparity-summary">Motivace</h2>
        <p>
        <i>Osobní motivace: Někdy je pocitově těžké se vyznat ve spoustě věcí. Když se dívám na jednu konkrétní,
        ztratím přitom ze zřetele všechny ostatní. Když se posunu ke druhé, třetí, čtvrté, už jsem zapomněl
        co byla ta první. Tohle ve mě budí dojem, že je toho moc; a/nebo že je to takový "guláš".
        Uspořádání všech věcí do diagramu / mindmapy umožňuje uvidět všechny věci naráz - a ukáže se, že
        jich vlastně není tolik. Je to takový "big picture", nebo "vykolíkování nepřehledné bažiny".
        </i>
        </p>

        <h2 id="fparity-summary">Další funkce</h2>
        <p>Z těchto funkci se dají "poskládat" další, např. pomocí:

        </p>

        <table className='simple-table simple-table-basic'>
            <tbody>
                <tr>
                    <td>sčítání:</td>
                    <td>{"$f(x) = x^2 + \\sin x$"}</td>
                    <td>(tato funkce není ani polynom, ani goniometrická)</td>
                </tr>
                <tr>
                    <td>násobení:</td>
                    <td>{"$f(x) = x^2 \\cdot \\sin x$"}</td>
                    <td>(tato funkce není ani polynom, ani goniometrická)</td>
                </tr>
                <tr>
                    <td>složení:</td>
                    <td>{"$f(x) = \\sin(x^2)$"}</td>
                    <td>(tato funkce není ani polynom, ani goniometrická)</td>
                </tr>
            </tbody>
        </table>


        <h2>Grafy funkcí</h2>
        <p>
        U všech těchto funkcí bychom měli mít představu, jak vypadá jejich graf.
        </p>
        <ul>
            <li><p>
            Gonio+cyklo, exp+log, mocninné, konstatní, lineární, abs. hodnota:
            Grafy bychom měli být schopni přesně nakreslit z hlavy, bez přemýšlení.
            </p></li>
            <li><p>
            Kvadratická, lineární lomená: Měli bychom mít představu o kvalitatitvním tvaru grafu a být schopní ho přesně nakreslit po rychlé úpravě výrazu.
            </p></li>
            <li><p>
            Obecný polynom: Stačí znalost limit do {"$\\pm \\infty$"} a představa o možném počtu kořenů a lokálních extrémů.
            </p></li>
            <li><p>
            Obecná racionalní lomená funkce: Stačí znalost limit do {"$\\pm \\infty$"} a představa o možném počtu bodů nespojitosti (svislých asymptot).
            </p></li>
        </ul>

        <Footer/>
        </ChapterWrapper>
    );
}

