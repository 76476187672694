import { MathJax } from 'better-react-mathjax';
import React from 'react';

import { ChapterWrapper } from '~/ChapterWrapper';
import { ExternalLink } from '~/ExternalLink';
import Footer from '~/Footer';
import { MathJaxWrapper } from '~/MathJaxWrapper';
import { Path } from '~/paths';

export const BasicsFractionsMeta = {
    title: "Zlomky",
    shortTitle: "Zlomky",
    path: Path.basics_fractions,
    element: (sectionNumber:string) => <BasicsFractions sectionNumber={sectionNumber}/>,
    sectionNumber: "",
}

const TOCSpec: string[] = [
    "fra-orientation",
    "fra-reciprocal",
    "fra-composed-fraction",
    "fra-cancelling",
    "fra-expanding",
    "fra-summation",
    "fra-minus-1",
    "fra-minus-2",
    "fra-cancelling-advanced",
    "fra-cancelling-compose",
];


export function BasicsFractions({sectionNumber}: {sectionNumber: string}) {

    return (
        <ChapterWrapper sectionNumber={sectionNumber} title={BasicsFractionsMeta.title} TOCSpec={TOCSpec}>
        {/* <MathJaxWrapper>
        <MathJax dynamic={true}> */}
        <p>
        Ref.: Odmaturuj z matematiky 1, vydání 2007, kapitola 3. <br/>
        Ref.: Zlomky na <ExternalLink href="https://www.matweb.cz/zlomky/">matweb</ExternalLink>.
        </p>

        <h2 id="fra-orientation">Základní orientace</h2>

        <p>Zlomek (podíl) lze ekvivalentně v hlavě (i na papíře) chápat jako <strong>součin</strong>:
        {`$$\\begin{align*}
            \\frac{a}{b} &= a \\cdot \\frac{1}{b}  \\qquad \\frac{3}{2} = 3 \\cdot \\frac{1}{2}
        \\end{align*}$$`}
        Slovně: Tři poloviny je to samé jako tři krát jedna polovina.
        {`$$\\begin{align*}
            \\frac{a}{b} &= \\frac{1}{b} \\cdot a \\qquad \\frac{3}{2} = \\frac{1}{2} \\cdot 3
        \\end{align*}$$`}
        Slovně: Tři poloviny je to samé jako jedna polovina krát tři.
        </p>

        <p><strong>Násobení zlomku</strong> číslem:
        {`$$\\begin{align*}
            \\textcolor{green}{p} \\cdot \\frac{a}{b} & = \\frac{\\textcolor{green}{p}\\cdot a}{b} \\qquad 2\\cdot \\frac{1}{3}  = \\frac{2\\cdot 1}{3}
        \\end{align*}$$`}
        Když násobím zlomek číslem, násobím čitatel. Příklad výše: 2 krát 1/3 pizzy je 2/3 pizzy.
        </p>

        <p><strong>Dělení zlomku</strong> číslem:
        {`$$\\begin{align*}
            \\frac{\\frac{a}{b}}{\\textcolor{red}{q}} & = \\textcolor{red}{\\frac{1}{q}} \\cdot \\frac{a}{b} = \\frac{a\\cdot\\textcolor{red}{\\frac{1}{q}}}{b} = \\frac{a}{b\\cdot\\textcolor{red}{q}} \\\\
        \\end{align*}$$`}
        </p>
        Příklad:
        <ul>
            <li>
            {`$$\\begin{align*}
                \\frac{\\frac{3}{4}}{2} & = \\frac{3}{4\\cdot 2} = \\frac{3}{8}
            \\end{align*}$$`}
            Slovně: Tři čtvrtiny dělím dvěma. To znamená že z každé čtvrtiny vezmu polovinu -
            z každé čtvrtiny se stane osmina - čili ve výsledku budu mít tři osminy.
            </li>
            <li>
            {`$$\\begin{align*}
                \\frac{\\frac{3}{4}}{2} & = \\frac{3\\cdot \\frac{1}{2}}{4} = \\frac{1.5}{4} = \\frac{3}{8}
            \\end{align*}$$`}
            Slovně: Mám tři 'dílky' (čtvrtiny), beru z nich polovinu, takže budu mít 1.5 'dílků' (čtvrtin).
            </li>

        </ul>

        <p><strong>Násobení zlomku zlomkem</strong>:
        {`$$\\begin{align*}
            \\frac{\\textcolor{green}{p}}{\\textcolor{red}{q}} \\cdot \\frac{a}{b} & = \\frac{\\textcolor{green}{p}\\cdot a}{\\textcolor{red}{q}\\cdot b}
        \\end{align*}$$`}
        Čitatel (a) násobím čitatelem (p), jmenovatel (b) násobím jmenovatelem (q).

        </p>

        <h2 id="fra-reciprocal">Převrácená hodnota</h2>
        <p>Převrácená hodnota čísla {"$a$"} je číslo {"$\\frac{1}{a}$"}:
        {`$$\\begin{align*}
            2 \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad \\frac{1}{2} \\\\
            10 \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad \\frac{1}{10} \\\\
            5 \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad \\frac{1}{5}
        \\end{align*}$$`}
        Je-li číslo ve tvaru {"$\\frac{1}{a}$"}, jeho převrácená hodnota je {"$a$"}:
        {`$$\\begin{align*}
            \\frac{1}{a} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\frac{1}{\\frac{1}{a}} = a
        \\end{align*}$$`}
        {`$$\\begin{align*}
            \\frac{1}{2} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad 2 \\\\
            \\frac{1}{10} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad 10 \\\\
            \\frac{1}{5} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad 5
        \\end{align*}$$`}
        Převracení hodnoty je cesta 'tam a zpět', 'tam a zpět'...:
        {`$$\\begin{align*}
            2 \\quad \\rightarrow \\text{přev.} \\rightarrow \\quad \\frac{1}{2} \\quad & \\rightarrow \\text{přev.} \\rightarrow \\quad 2 \\quad \\rightarrow \\text{přev.} \\rightarrow \\quad \\frac{1}{2} \\cdots
        \\end{align*}$$`}
        </p>
        Převrácená hodnota zlomku {"$\\frac{a}{b}$"} je zlomek {"$\\frac{b}{a}$"}:
        {`$$\\begin{align*}
            \\frac{a}{b} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad \\frac{1}{\\frac{a}{b}} = \\frac{b}{a}
        \\end{align*}$$`}
        {`$$\\begin{align*}
            \\frac{3}{10} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad \\frac{10}{3} \\\\
            \\frac{6}{2} \\quad & \\rightarrow \\text{převrácená hodnota} \\rightarrow \\quad \\frac{2}{6}
        \\end{align*}$$`}


        <h2 id="fra-composed-fraction">Složený zlomek</h2>
        <p>Obecně si lze pamatovat, že
        {`$$\\begin{align*}
            \\frac{\\frac{a}{b}}{\\frac{\\textcolor{red}{c}}{\\textcolor{green}{d}}} & = \\frac{a\\cdot \\textcolor{green}{d}}{b \\cdot \\textcolor{red}{c}}
        \\end{align*}$$`}
        Jak s tím operovat:
        {`$$\\begin{align*}
            \\frac{\\frac{a}{b}}{\\frac{c}{d}} & = \\textcolor{Purple}{\\frac{1}{\\frac{c}{d}}} \\cdot \\frac{a}{b} = \\textcolor{purple}{\\frac{d}{c}} \\cdot \\frac{a}{b} = \\frac{a\\cdot d}{b\\cdot c}
        \\end{align*}$$`}
        Použili jsme <span style={{color: 'purple'}}>převrácenou hodnotu</span>. Příklad:
        {`$$\\begin{align*}
            \\frac{\\frac{4}{10}}{\\frac{2}{10}} & = \\frac{4}{10}\\cdot \\frac{10}{2} = \\frac{4}{2} = 2
        \\end{align*}$$`}
        </p>
        <p>Speciální případ 1: Jednoduchý jmenovatel = dělení zlomku:
        {`$$\\begin{align*}
            \\frac{\\frac{a}{b}}{q} & = \\frac{\\frac{a}{b}}{\\frac{q}{1}} = \\frac{a}{b} \\cdot \\frac{1}{q} = \\frac{a}{b\\cdot q}
        \\end{align*}$$`}
        </p>
        <p>Speciální případ 2: Jednoduchý čitatel = násobek převrácené hodnoty jmenovatele:
        {`$$\\begin{align*}
            \\frac{p}{\\frac{c}{d}} & = p \\cdot \\frac{d}{c} = \\frac{p\\cdot d}{c}
        \\end{align*}$$`}
        </p>

        <h2 id="fra-cancelling">Krácení</h2>
        <p>
        {`$$\\begin{align*}
            \\frac{a\\cdot \\textcolor{RoyalBlue}{c} }{b \\cdot \\textcolor{RoyalBlue}{c}} &= \\frac{a}{b} & \\quad \\frac{15}{20} = \\frac{3\\cdot \\textcolor{RoyalBlue}{5}}{4 \\cdot \\textcolor{RoyalBlue}{5}} & = \\frac{3}{4} \\\\
            & \\\\
            \\frac{a\\cdot \\textcolor{magenta}{\\frac{1}{c}} }{b \\cdot \\textcolor{magenta}{\\frac{1}{c}}} &= \\frac{a}{b} & \\quad  \\frac{\\frac{1}{3}}{\\frac{2}{3}} = \\frac{1\\cdot\\textcolor{magenta}{\\frac{1}{3}}}{2\\cdot\\textcolor{magenta}{\\frac{1}{3}}} & = \\frac{1}{2}
        \\end{align*}$$`}
        </p>
        <h2 id="fra-expanding">Rozšiřování</h2>
        <p>
        {`$$\\begin{align*}
            \\frac{a}{b} &= \\frac{a\\cdot \\textcolor{RoyalBlue}{c}}{b\\cdot \\textcolor{RoyalBlue}{c}} & \\frac{3}{4} & = \\frac{3\\cdot \\textcolor{RoyalBlue}{2}}{4\\cdot \\textcolor{RoyalBlue}{2}} = \\frac{6}{8}\\\\
            & \\\\
            \\frac{a}{b} &= \\frac{a\\cdot \\textcolor{magenta}{\\frac{1}{c}}}{b\\cdot \\textcolor{magenta}{\\frac{1}{c}}} = \\frac{\\frac{a}{\\textcolor{magenta}{c}}}{\\frac{b}{\\textcolor{magenta}{c}}} & \\frac{3}{4} & = \\frac{3\\cdot \\textcolor{magenta}{\\frac{1}{2}}}{4\\cdot \\textcolor{magenta}{\\frac{1}{2}}} = \\frac{\\frac{3}{\\textcolor{magenta}{2}}}{\\frac{4}{\\textcolor{magenta}{2}}} = \\frac{1.5}{2}
        \\end{align*}$$`}
        </p>
        <h2 id="fra-summation">Sčítání zlomků</h2>
        <p>Pokud zlomky mají <strong>stejného jmenovatele</strong>, není co řešit - sčítají se čitatelé:
        {`$$\\begin{align*}
            \\frac{a}{p} + \\frac{b}{p} & = \\frac{a+b}{p} & \\qquad  \\frac{1}{8} + \\frac{3}{8} & = \\frac{1+3}{8} = \\frac{4}{8} = \\frac{1}{2}
        \\end{align*}$$`}
        Slovně: Mám osminu pizzy a k tomu tři osminy pizzy - dohromady mám čtyři osminy pizzy.
        </p>
        <p>
        Toto funguje, protože můžeme vytknout:
        {`$$\\begin{align*}
            \\frac{a}{p} + \\frac{b}{p} & = \\frac{1}{p}\\cdot a + \\frac{1}{p}\\cdot b = \\frac{1}{p} (a+b) = \\frac{a+b}{p}
        \\end{align*}$$`}
        (Viz úplný úvod této kapitoly, kde ukazujeme, že zlomek {"$\\frac{a}{p}$"} je ekvivalentní součinu {"$a\\cdot \\frac{1}{p}$"}).
        V našem číselném příkladě:
        {`$$\\begin{align*}
            \\frac{1}{8} + \\frac{3}{8} & = \\frac{1}{8} \\cdot 1 + \\frac{1}{8} \\cdot 3 = \\frac{1}{8} (1+3) = \\frac{1}{8}\\cdot 4 = \\frac{1}{2}
        \\end{align*}$$`}
        </p>

        <p>Pokud zlomky mají <strong>různého jmenovatele</strong>:
        {`$$\\begin{align*}
            \\frac{a}{\\textcolor{red}{p}} + \\frac{b}{\\textcolor{green}{q}} &= \\frac{a\\cdot \\textcolor{green}{q} + b \\cdot \\textcolor{red}{p}}{\\textcolor{red}{p} \\cdot \\textcolor{green}{q}} & \\quad \\frac{2}{\\textcolor{red}{3}} + \\frac{4}{\\textcolor{green}{5}} & = \\frac{2\\cdot \\textcolor{green}{5} + 4\\cdot \\textcolor{red}{3}}{\\textcolor{red}{3}\\cdot \\textcolor{green}{5}} = \\frac{10 + 12}{15} = \\frac{22}{15}
        \\end{align*}$$`}
        </p>

        <p>Někdy je tento mechanický postup nepraktický, protože ve jmenovateli vede ke zbytečně <span style={{color: 'magenta'}}>velkému číslu</span>:
        {`$$\\begin{align*}
            \\frac{3}{8} + \\frac{1}{12} & = \\frac{3\\cdot 12 + 1\\cdot 8}{\\textcolor{magenta}{8\\cdot 12}} = \\frac{36 + 8}{\\textcolor{magenta}{96}} = \\frac{44}{96} = \\frac{11}{24}
        \\end{align*}$$`}
        Je evektivnější najít <strong>nejmenší společný jmenovatel</strong>:
        {`$$\\begin{align*}
            \\frac{3}{8} + \\frac{1}{12} & = \\frac{3\\cdot \\textcolor{Emerald}{3}}{8\\cdot \\textcolor{Emerald}{3}} + \\frac{1\\cdot \\textcolor{BrickRed}{2}}{12\\cdot \\textcolor{BrickRed}{2}} = \\frac{9}{24} + \\frac{2}{24} = \\frac{11}{24}
        \\end{align*}$$`}
        Nejmenší společný jmenovatel je <strong>nejmenší společný násobek</strong> jmenovatelů.
        Pro čísla 8 a 12 je to 24, protože {"$8 \\cdot \\textcolor{Emerald}{3} = 24$"} a {"$12 \\cdot \\textcolor{BrickRed}{2} = 24$"}.
        Pokud si potřebujete zopakovat rozklad na prvočísla a nejmenší společný násobek,
        podívejte se např. na <ExternalLink href="https://isibalo.com/matematika/6-trida-delitelnost/nejmensi-spolecny-nasobek">isibalo</ExternalLink>.
        </p>
        <p>Odčítání funguje stejně, místo plus máme mínus:
        {`$$\\begin{align*}
            \\frac{a}{p} - \\frac{b}{p} & = \\frac{a-b}{p}, & \\qquad  \\frac{a}{\\textcolor{red}{p}} - \\frac{b}{\\textcolor{green}{q}} &= \\frac{a\\cdot \\textcolor{green}{q} - b \\cdot \\textcolor{red}{p}}{\\textcolor{red}{p} \\cdot \\textcolor{green}{q}}
        \\end{align*}$$`}
        </p>

        <h2 id="fra-minus-1">Zlomek s mínusem (1)</h2>
        <p>
        {`$$\\begin{align*}
            -\\frac{a}{b} &= \\frac{-a}{b} = \\frac{a}{-b}
        \\end{align*}$$`}
        S mínusem tedy můžeme "vlézt" do čitatele nebo jmenovatele.
        Ale pozor, pokud je v čitateli nebo jmenovateli výraz, musíme "závorkovat":
        {`$$\\begin{align*}
            -\\frac{a + b}{c} &= \\frac{-(a+b)}{c} = \\frac{-a-b}{c}
        \\end{align*}$$`}
        {`$$\\begin{align*}
            -\\frac{a}{b + c} &= \\frac{a}{-(b+c)} = \\frac{a}{-b-c}
        \\end{align*}$$`}
        </p>
        <p>Vytýkání mínus před zlomek:</p>
        {`$$\\begin{align*}
            \\frac{-x^2 + x - 1}{c} & = \\frac{-(x^2 - x + 1)}{c} = - \\frac{x^2 - x + 1}{c} \\\\
            & \\\\
            \\frac{a}{-\\cos x + 1} & = \\frac{a}{-(\\cos x - 1)} = -\\frac{a}{\\cos x - 1}
        \\end{align*}$$`}

        <h2 id="fra-minus-2">Zlomek s mínusem (2)</h2>
        <p>
        {`$$\\begin{align*}
            \\frac{a}{b} &= \\frac{-a}{-b}
        \\end{align*}$$`}
        Ve zlomku můžeme současně otočit znaménka v čitateli i jmenovateli. Příklad:
        {`$$\\begin{align*}
            \\frac{-x - 1}{-x + 3} &= \\frac{x + 1}{x - 3} \\quad \\text{praktičtější tvar}
        \\end{align*}$$`}
        Toto se hodí, abychom minimalizovali počet mínusů ve výrazech - s plusy se prakticky počítá snadněji.
        </p>
        <p>Proč to funguje?
        {`$$\\begin{align*}
            \\frac{a}{b} &= \\frac{(-1)\\cdot a}{(-1)\\cdot b} = \\frac{-a}{-b}
        \\end{align*}$$`}
        </p>

        <h2 id="fra-cancelling-advanced">Pokročilé krácení</h2>
        <p>
        Pozor, nikdy nesmíme krátit 'částečně' (metoda 'náhodné úpravy od oka'):
        {`$$\\begin{align*}
            \\frac{x^2}{x + 3} &= \\textcolor{red}{\\cancel{\\frac{x}{1 + 3}}}
        \\end{align*}$$`}
        </p>
        <p>Krácení vlastně znamená dělení nebo násobení <strong>celého</strong> čitatele i jmenovatele stejným členem:
        {`$$\\begin{align*}
            \\frac{x^2}{x + 3} &= \\frac{\\frac{x^2}{x}}{\\frac{x + 3}{x}} = \\frac{x}{1 + \\frac{3}{x}}
        \\end{align*}$$`}
        </p>

        <h2 id="fra-cancelling-compose">Krácení ve složeném zlomku</h2>
        <p>Krátíme čitatele proti čitateli:
        {`$$\\begin{align*}
            \\frac{\\frac{x + 1}{b}}{\\frac{x^2 + x}{d}} &= \\frac{\\frac{\\cancel{(x + 1)}}{b}}{\\frac{x\\cancel{(x+1)}}{d}} = \\frac{\\frac{1}{b}}{\\frac{x}{d}}
        \\end{align*}$$`}
        </p>
        <p>Krátíme jmenovatele proti jmenovateli:
        {`$$\\begin{align*}
            \\frac{\\frac{a}{\\sin^2x}}{\\frac{c}{\\sin x \\cos x}} &= \\frac{\\frac{a}{\\cancel{\\sin x} \\sin x}}{\\frac{c}{\\cancel{\\sin x} \\cos x}} = \\frac{\\frac{a}{\\sin x}}{\\frac{c}{\\cos x}}
        \\end{align*}$$`}
        </p>
        <p>Obzvlášť, pokud člověk převede horní i dolní zlomek na stejného společného jemnovatele, může zlomek rovnou "splácnout":
        {`$$\\begin{align*}
            \\frac{x + \\frac{1}{x}}{x - \\frac{1}{x}} &= \\frac{\\frac{x^2 + 1}{x}}{\\frac{x^2 - 1}{x}} = \\frac{x^2 + 1}{x^2 - 1}
        \\end{align*}$$`}
        </p>
        {/* </MathJax>
        </MathJaxWrapper> */}
        <Footer/>
        </ChapterWrapper>
    );
}

